import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Loader, Pagination } from '../../components';
import { Button, DatePicker, Input } from '../../components/inputs';
import Table, { ColumnType, ItemActionProps } from '../../components/Table';
import { SearchFields, View } from '../../components/View';
import { adminStore } from '../../mobx/adminStore';
import { adminRoutes } from '../../routes/routes';

const PAGE_SIZE = 24;

export const PayPeriods: FC = observer(() => {
  const { t } = useTranslation();
  const [groupId, setGroupId] = useState<number | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const { status, payPeriods, total, pages, page } = adminStore;
  const payPeriodsTable: ColumnType<PayPeriod & { hasChanges?: boolean }>[] = [
    { key: 'id', customRender: ({ id, hasChanges }) => `${id} ${hasChanges ? '*' : ''}` },
    { key: 'group', customRender: ({ group }) => <>{group?.name}</> },
    {
      key: 'groupId',
      customRender: ({ groupId, ...payPeriod }) => <Input type="number" id={`groupId_g`} value={groupId || undefined} onChange={e => adminStore.changePayPeriod({ groupId: Number(e.target.value), ...payPeriod })} />,
    },
    {
      key: 'startDate',
      customRender: ({ startDate, ...payPeriod }) => <DatePicker id={`startDate_g`} selectsStart startDate={startDate} onChange={e => adminStore.changePayPeriod({ startDate: e as Date, ...payPeriod })} />,
    },
    {
      key: 'endDate',
      customRender: ({ endDate, ...payPeriod }) => <DatePicker id={`endDate_g`} selectsStart startDate={endDate} onChange={e => adminStore.changePayPeriod({ endDate: e as Date, ...payPeriod })} />,
    },

    {
      key: 'payDate',
      customRender: ({ payDate, ...payPeriod }) => <DatePicker id={`payDate_g`} selectsStart startDate={payDate} onChange={e => adminStore.changePayPeriod({ payDate: e as Date, ...payPeriod })} />,
    },
  ];

  const payPeriodsTableRowActions: ItemActionProps<PayPeriod & { hasChanges?: boolean }>[] = [
    {
      label: t('delete'),
      icon: <Icon type="Close" color="primary" />,
      onClick: ({ id }) => {
        if (confirm(`Are you sure you want to delete pay period ${id}?`)) adminStore.deletePayPeriod(id);
      },
    },
  ];

  const handlePageChange = (page: number) => {
    adminStore.getPayPeriods({ skip: (page - 1) * PAGE_SIZE, take: PAGE_SIZE, groupId, startDate, endDate });
  };

  useEffect(() => {
    if (status !== 'BUSY' && status !== 'ERROR' && !payPeriods) {
      adminStore.getPayPeriods({ skip: 0, take: PAGE_SIZE, groupId, startDate, endDate });
    }
  }, [status, payPeriods]);

  useEffect(() => {
    adminStore.getPayPeriods({ skip: 0, take: PAGE_SIZE, groupId, startDate, endDate });
  }, [groupId, startDate, endDate]);

  return (
    <View routes={adminRoutes}>
      <SearchFields>
        <Input id="searchByGroupId" label={t('groupId')}type="number" value={groupId} onChange={e => setGroupId(e.target.value)} />
        <DatePicker id="startDate" label={t('startDate')} selectsStart startDate={startDate} onChange={e => setStartDate(e as Date)} />
        <DatePicker id="endDate" label={t('endDate')} selectsEnd endDate={endDate} onChange={e => setEndDate(e as Date)} />
      </SearchFields>

      {status === 'BUSY' && <Loader isFullScreen />}
      {payPeriods && (
        <>
          <Pagination total={total} pageCount={pages} page={page} onPageChange={handlePageChange} />
          <Table<PayPeriod> data={payPeriods} columns={payPeriodsTable} itemActions={payPeriodsTableRowActions} />
          <Pagination total={total} pageCount={pages} page={page} onPageChange={handlePageChange} />
        </>
      )}
      {payPeriods && status === 'CHANGED' && <Button id="save-changes" onClick={() => adminStore.saveChangedPayPeriods()} text={t('save-changes')} />}
    </View>
  );
});

import { observer } from 'mobx-react';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Col, ConfirmModal, Icon, Layout, Loader, Row, Sect } from '../../components';
import { Button, ButtonGroupRow, Checkbox, DatePicker, Input, LinkButton, Select } from '../../components/inputs';
import { SelectOrganization } from '../../components/SelectOrganization';
import { SelectRecipients } from '../../components/SelectRecipients';
import { ShowErrors } from '../../components/ShowErrors';
import Table, { ColumnType } from '../../components/Table';
import { employeeSuggestionStore } from '../../mobx/employeeSuggestionStore';
import { orgStore } from '../../mobx/orgStore';
import { path } from '../../routes/routes';
import { photos } from '../../theme/photos';
import { dateToString } from '../../utils/date';

const PhotoContainer = styled.div`
  position: relative;
  display: flex;
  height: 200px;
  align-items: center;

  img {
    margin: ${p => p.theme.spacings.md} !important;
    cursor: pointer;
  }

  @media ${p => p.theme.breakpoints.mobile} {
    height: auto;

    img {
      width: 100% !important;
      margin: 0 !important;
      padding: ${p => p.theme.spacings.sm};
    }
  }
`;

const Photos = styled.div`
  img {
    width: 150px;
  }
`;

const AddPhotoButton = styled.div`
  img {
    width: 200px !important;
    @media ${p => p.theme.breakpoints.mobile} {
      width: 100% !important;
      padding-top: ${p => p.theme.spacings.xl};
    }
  }
`;

export const CreateOrUpdateEmployeeSuggestion: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { employeeSuggestionId, copy } = useParams<{ employeeSuggestionId: string; copy: string }>();
  const { organizationTree } = orgStore;
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showSelectPhoto, setShowSelectPhoto] = useState(false);

  const durationUnitOption: SelectOption<'h' | 'd' | 'w' | 'm'>[] = [
    { value: 'h', label: t('hours') },
    { value: 'd', label: t('days') },
    { value: 'w', label: t('weeks') },
    { value: 'm', label: t('months') },
  ];
  const { status, employeeSuggestion, error, errorDetails } = employeeSuggestionStore;

  // copy is from params

  const isCopy = !!copy;

  const tableColumns: ColumnType<EmployeeSuggestionResponse>[] = [
    {
      key: 'user',
      customRender: ({ user }) => (
        <a href={`${process.env.REACT_APP_LIKEIT_ADMIN_EMPLOYEE_URL}?id=${user.likeitEmployeeId}`} target="_blank">
          🔗{user.name}
        </a>
      ),
    },
    { key: 'user', label: 'email', customRender: ({ user }) => user.email + '' },
    { key: 'user', label: 'phone', customRender: ({ user }) => '📱' + user.phone },
    { key: 'type', label: 'reply', customRender: ({ type }) => (type === 'interested_in' ? '🟢' : '') + t(type) },
    { key: 'createdAt', label: 'respondedAt', customRender: ({ createdAt }) => dateToString(createdAt, 'dd.MM.yyyy HH:mm') },
  ];

  useEffect(() => {
    if (isCopy) {
      employeeSuggestionStore.change({ startDate: new Date() });
      employeeSuggestionStore.change({ endDate: new Date() });
    }
    if (status === 'SAVED' && !employeeSuggestion?.id) {
      history.push(`/${path('employee-suggestions')}`);
    }
    if (status === 'DELETED') {
      const s = { sort: 'updatedAt', order: 'desc', filter: 'open' };
      employeeSuggestionStore.list(s);
      history.push(`/${path('employee-suggestions')}`);
      history.replace({ search: new URLSearchParams(s).toString() });
    } else if (status !== 'BUSY' && status !== 'ERROR' && !employeeSuggestion) {
      if (employeeSuggestionId) {
        employeeSuggestionStore.fetchById(Number(employeeSuggestionId));
      } else {
        // new
        employeeSuggestionStore.createNew();
      }
    }
  }, [status]);

  const saveChanges = () => {
    employeeSuggestionStore.save(isCopy);
  };

  const {
    customer,
    customerFullName,
    customerAddress,
    customerCity,
    customerPerson,
    contactPersonPhone,
    otherDuration,
    profession,
    description,
    startDate,
    endDate,
    estimatedDuration = '',
    estimatedDurationUnit,
    employeeCount,
    employeeInfo,
    startDateIgnoreTime = true,
    endDateIgnoreTime = true,
  } = employeeSuggestion || {};

  const onSelectOrganization = ({ department: { value, label }, organizationFullName }: any) => {
    if (value !== employeeSuggestion?.customer?.likeitId) {
      employeeSuggestionStore.change({ customer: value ? { likeitId: Number(value), name: label } : undefined, customerFullName: organizationFullName });
    }
  };

  const handleInputChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = event?.target.value;
    if (name === 'startDateIgnoreTime' || name === 'endDateIgnoreTime') {
      value = !event?.target.checked;
    }
    employeeSuggestionStore.change({ [name]: value });
  };

  // react to organization selections
  useEffect(() => {
    if (employeeSuggestion && organizationTree) {
      const organization: any = organizationTree?.Data?.[0];
      employeeSuggestionStore.change({
        customerAddress: organization?.Address?.Address || '',
        customerCity: organization?.Address?.City || '',
        customerPerson: '',
        contactPersonPhone: '',
      });
    }
  }, [organizationTree]);

  const selectsToSuggestionProps = (propName: string) => (selected: SelectedOption<number>[]) => {
    employeeSuggestionStore.change({ [propName]: selected.map((o: SelectedOption<number>) => o && { id: Number(o.value), name: o.label }) });
  };

  const propsToSelects = (propName: 'groups' | 'employees' | 'blacklisted') =>
    employeeSuggestion?.[propName]?.map((group: any) => ({ value: group.id, label: group.name })) || [];

  const employeeCandidatesProps = {
    setSelectedGroups: selectsToSuggestionProps('groups'),
    selectedGroups: propsToSelects('groups'),
    setSelectedEmployees: selectsToSuggestionProps('employees'),
    selectedEmployees: propsToSelects('employees'),
    setSelectedBlacklisted: selectsToSuggestionProps('blacklisted'),
    selectedBlacklisted: propsToSelects('blacklisted'),
  };

  const deleteEmployeeSuggestion = () => {
    employeeSuggestionStore.deleteById(Number(employeeSuggestionId));
  };

  const handleSelectPhoto = (photoName: string) => {
    setShowSelectPhoto(false);
    employeeSuggestionStore.change({ photoName: photoName });
  };

  const photoArray = Object.keys(photos).map(key => [key, photos[key]]);

  return (
    <Layout>
      {status === 'BUSY' && <Loader isFullScreen />}
      {employeeSuggestion?.id && !isCopy && (
        <Sect className="status">
          <div>
            <LinkButton color="primary" href={`/${path('employee-suggestion-edit')}/${employeeSuggestionId}/copy`}>
              {t('copy')}
            </LinkButton>
          </div>
          <Button
            id="close-employee-suggestion"
            text={t(employeeSuggestion.status == 'closed' ? 'open-employee-suggestion' : 'close-employee-suggestion')}
            onClick={() =>
              employeeSuggestion.id
                ? employeeSuggestionStore.updateStatus(employeeSuggestion.id, employeeSuggestion.status == 'closed' ? 'open' : 'closed')
                : undefined
            }
          />
          <table>
            <tbody>
              {employeeSuggestion.createdByUser && (
                <tr>
                  <th>{t('createdBy')}:</th>
                  <td>{employeeSuggestion.createdByUser.name}</td>
                </tr>
              )}
              {employeeSuggestion.createdAt && (
                <tr>
                  {}
                  <th>{t('createdAt')}:</th>
                  <td>{dateToString(employeeSuggestion.createdAt, 'yyyy-MM-dd HH:mm:ss')}</td>
                </tr>
              )}
              {employeeSuggestion.updatedByUser && (
                <tr>
                  <th>{t('updatedBy')}:</th>
                  <td>{employeeSuggestion.updatedByUser.name}</td>
                </tr>
              )}
              {employeeSuggestion.updatedAt && (
                <tr>
                  <th>{t('updatedAt')}:</th>
                  <td>{dateToString(employeeSuggestion.updatedAt, 'yyyy-MM-dd HH:mm:ss')}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Sect>
      )}
      <h1>{t(employeeSuggestionId && !isCopy ? 'edit-employee-suggestion' : 'create-employee-suggestion')}</h1>
      {employeeSuggestion?.deleted && (
        <>
          {t('employee-suggestion-deleted')} {employeeSuggestion.deletedAt}
        </>
      )}
      {employeeSuggestion && !employeeSuggestion?.deleted && (
        <>
          {Boolean(employeeSuggestion?.employeeResponses?.length) && !isCopy && (
            <Sect>
              <Table<EmployeeSuggestionResponse> columns={tableColumns} data={employeeSuggestion.employeeResponses || []} />
            </Sect>
          )}
          <Sect>
            <Col>
              <ShowErrors property="customer" errorDetails={employeeSuggestionStore.errorDetails}>
                <SelectOrganization
                  preSelectedOrganization={(customer && { value: customer.likeitId, label: customer.name || '' }) || undefined}
                  onSelectOrganization={onSelectOrganization}
                />
              </ShowErrors>
              <Input
                id="shift-set-org-name"
                value={customerFullName?.split(';').join('\n')}
                label={t('displayedOrganizationName')}
                onChange={({ target: { value } }) => employeeSuggestionStore.change({ customerFullName: value?.split('\n').join(';') })}
                placeholder={t('inputPlaceholder.organizationName')}
                asTextarea
              />
              <ShowErrors property="customerAddress" errorDetails={employeeSuggestionStore.errorDetails}>
                <Input id="address" label={t('address')} value={customerAddress} onChange={handleInputChange('customerAddress')} />
              </ShowErrors>
              <ShowErrors property="customerCity" errorDetails={employeeSuggestionStore.errorDetails}>
                <Input id="city" label={t('city')} value={customerCity} onChange={handleInputChange('customerCity')} />
              </ShowErrors>
            </Col>
            <Row>
              <ShowErrors property="customerPerson" errorDetails={employeeSuggestionStore.errorDetails}>
                <Input id="customerPerson" label={t('customerPerson')} value={customerPerson} onChange={handleInputChange('customerPerson')} />
              </ShowErrors>
              <ShowErrors property="contactPersonPhone" errorDetails={employeeSuggestionStore.errorDetails}>
                <Input id="contactPersonPhone" label={t('phone')} value={contactPersonPhone} onChange={handleInputChange('contactPersonPhone')} />
              </ShowErrors>
            </Row>
          </Sect>
          <Sect>
            <Col>
              <ShowErrors property="profession" errorDetails={employeeSuggestionStore.errorDetails}>
                <Input id="profession" value={profession} label={t('profession')} onChange={handleInputChange('profession')} />
              </ShowErrors>
              <ShowErrors property="description" errorDetails={employeeSuggestionStore.errorDetails}>
                <Input
                  id="description"
                  value={description}
                  label={t('description')}
                  onChange={handleInputChange('description')}
                  placeholder={t('inputPlaceholder.suggestionDescription')}
                  asTextarea
                />
              </ShowErrors>
            </Col>

            <Row>
              <Input
                id="other duration"
                value={otherDuration}
                label={t('other duration')}
                onChange={handleInputChange('otherDuration')}
                placeholder={t('inputPlaceholder.suggestionOtherDuration')}
                asTextarea
              />

              <>
                <ShowErrors property="startDate" errorDetails={employeeSuggestionStore.errorDetails}>
                  <div>
                    <DatePicker
                      id="startDate"
                      label={t('startDay')}
                      date={startDate}
                      onChange={(date: any) => employeeSuggestionStore.change({ startDate: date })}
                      minDate={new Date()}
                      isClearable
                    />
                    <Checkbox id="startDateIgnoreTime" label={t('time')} checked={!startDateIgnoreTime} onChange={handleInputChange('startDateIgnoreTime')} />
                  </div>
                </ShowErrors>
                {!startDateIgnoreTime && (
                  <DatePicker
                    id="startTime"
                    label={t('startTime')}
                    date={startDate}
                    onChange={(date: any) => employeeSuggestionStore.change({ startDate: date })}
                    minDate={new Date()}
                    showTimeSelectOnly
                  />
                )}
                <ShowErrors property="endDate" errorDetails={employeeSuggestionStore.errorDetails}>
                  <div>
                    <DatePicker
                      id="endDate"
                      label={t('endDay')}
                      date={endDate}
                      onChange={(date: any) => employeeSuggestionStore.change({ endDate: date })}
                      minDate={new Date()}
                      isClearable
                    />
                    <Checkbox id="endDateIgnoreTime" label={t('time')} checked={!endDateIgnoreTime} onChange={handleInputChange('endDateIgnoreTime')} />
                  </div>
                </ShowErrors>
                {!endDateIgnoreTime && (
                  <DatePicker
                    id="endTime"
                    label={t('ends')}
                    date={endDate}
                    onChange={(date: any) => employeeSuggestionStore.change({ endDate: date })}
                    minDate={new Date()}
                    showTimeSelectOnly
                  />
                )}
                <ShowErrors property="estimatedDuration" errorDetails={employeeSuggestionStore.errorDetails}>
                  <Input
                    id="estimatedDuration"
                    type="number"
                    label={t('estimatedDuration')}
                    value={estimatedDuration}
                    onChange={handleInputChange('estimatedDuration')}
                  />
                </ShowErrors>
                <ShowErrors property="estimatedDurationUnit" errorDetails={employeeSuggestionStore.errorDetails}>
                  <Select<'h' | 'd' | 'w' | 'm'>
                    id="estimatedDurationUnit"
                    label={t('estimatedDurationUnit')}
                    options={durationUnitOption}
                    selected={durationUnitOption.find(o => o.value === estimatedDurationUnit)}
                    onChange={o => employeeSuggestionStore.change({ estimatedDurationUnit: o?.value })}
                  />
                </ShowErrors>
              </>
            </Row>
            <Col>
              <ShowErrors property="employeeCount" errorDetails={employeeSuggestionStore.errorDetails}>
                <Input id="employeeCount" label={t('employeeCount')} type="number" value={employeeCount} onChange={handleInputChange('employeeCount')} />
              </ShowErrors>
              <ShowErrors property="employeeInfo" errorDetails={employeeSuggestionStore.errorDetails}>
                <Input
                  id="employeeInfo"
                  value={employeeInfo}
                  label={t('employeeInfo')}
                  onChange={handleInputChange('employeeInfo')}
                  placeholder={t('inputPlaceholder.suggestionEmployeeInfo')}
                  asTextarea
                />
              </ShowErrors>
            </Col>
          </Sect>
          <PhotoContainer>
            {showSelectPhoto ? (
              <Photos>
                {photoArray.map((photo, i) => {
                  return <img key={i} src={photo[1]} onClick={() => handleSelectPhoto(photo[0])} />;
                })}
              </Photos>
            ) : (
              <AddPhotoButton>
                <Button
                  id="add-photo-button"
                  color="primary"
                  text={t('add-photo')}
                  onClick={() => setShowSelectPhoto(true)}
                  icon={<Icon type="Add" color="white" />}
                />
                {employeeSuggestion.photoName && (
                  <img src={photos[employeeSuggestion.photoName]} onClick={() => employeeSuggestionStore.change({ photoName: undefined })} />
                )}
              </AddPhotoButton>
            )}
          </PhotoContainer>
          <Sect>
            <ShowErrors property="employees" errorDetails={employeeSuggestionStore.errorDetails}>
              <SelectRecipients {...employeeCandidatesProps} />
            </ShowErrors>
          </Sect>
          <Sect>
            <ButtonGroupRow>
              <Button
                id="delete-button"
                type="submit"
                text={t('delete')}
                onClick={() => setShowConfirmDelete(true)}
                color="primary"
                disabled={!employeeSuggestion.id || isCopy}
              />
              <Button id="save-button" type="submit" text={t('saveChanges')} onClick={saveChanges} color="primary" disabled={status !== 'CHANGED'} />
              <Button id="cancel-button" text={t('cancel')} onClick={() => history.push(`/${path('employee-suggestions')}`)} variant="outlined" />
            </ButtonGroupRow>
          </Sect>
        </>
      )}
      {showConfirmDelete && (
        <ConfirmModal
          bodyText={t('delete-employee-suggestion-confirm-body')}
          confirmText={t('delete')}
          cancelText={t('cancel')}
          onConfirm={deleteEmployeeSuggestion}
          onHide={() => setShowConfirmDelete(false)}
        />
      )}
    </Layout>
  );
});

// @ts-nocheck
import React, { ChangeEvent, Dispatch, forwardRef, SetStateAction, useState, useEffect } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { extractDateValue } from './utils';
import Label from './Label';

const Container = styled.div`
  margin-top: ${p => p.theme.spacings.md};
  margin-bottom: ${p => p.theme.spacings.md};

  .input-group {
    .input-group-prepend,
    .input-group-append {
      .input-group-text {
        background-color: ${p => p.theme.colors.white};
        color: ${p => p.theme.colors.primary};
      }
    }
  }
`;

interface Props {
  id: string;
  value?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  prepend?: string | JSX.Element;
  append?: string | JSX.Element;
  onChange?: (event: ChangeEvent<any>) => void;
  onBlur?: (event: ChangeEvent<any>) => void;
  onKeyDown?: (event: ChangeEvent<any>) => void;
  onClick?: (event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
  setValue?: Dispatch<SetStateAction<string>>;
  asTextarea?: boolean;
  disabled?: boolean;
  format?: string;
  showTimeSelectOnly?: boolean;
}

// Note: forwardRef is used because of the DatePicker component,
// which is using this as a custom input and requires some handling for the ref
// Still, the ref is not used in this component in any way.
export const Input = forwardRef<any, Props>((props, _ref: any) => {
  const { id, label, value, placeholder, type, prepend, append, onChange, onBlur, onKeyDown, onClick, setValue, asTextarea = false, disabled, format, showTimeSelectOnly, ...rest } = props;

  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  const handleChange = (event: any) => {
    setState(event.target.value);
  };

  const handleBlur = (event: any) => {
    const _state = extractDateValue(event.target.value, format, showTimeSelectOnly);
    const newDate = DateTime.fromFormat(_state, format);
    if (newDate.isValid) {
      event.target.value = _state;
      onChange(event);
      onBlur(event);
    }
  };

  const handleSelect = (event: any) => {
    const newDate = DateTime.fromFormat(event.target.value, format);
    if (newDate.isValid) {
      onChange(event);
    }
  };

  const handleKeyDown = (event: any) => {
    const eventKey = event.key;
    if (eventKey === 'Enter' || eventKey === 'Tab') {
      const _state = extractDateValue(event.target.value, format, showTimeSelectOnly);
      const newDate = DateTime.fromFormat(_state, format);
      if (newDate.isValid) {
        event.target.value = _state;
        onChange(event);
      }
    }
    onKeyDown(event);
  };

  return (
    <Container>
      {label && <Label htmlFor={id}>{label}</Label>}
      <InputGroup>
        {prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <FormControl
          id={id}
          data-testid={id}
          placeholder={placeholder}
          value={state}
          onChange={handleChange}
          onBlur={handleBlur}
          onSelect={handleSelect}
          onKeyDown={handleKeyDown}
          type={type}
          as={asTextarea ? 'textarea' : undefined}
          rows={asTextarea ? 5 : undefined}
          disabled={disabled}
          onClick={onClick}
          {...rest}
        />
        {append && (
          <InputGroup.Append>
            <InputGroup.Text>{append}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </Container>
  );
});

export default Input;

import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { orgStore } from '../mobx/orgStore';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, ButtonGroup, Checkbox, DatePicker, ASelect } from './inputs';
import { SingleValue } from 'react-select';
import { ShowTeam } from './ShowTeam';
import { DateTime } from 'luxon';

const Container = styled.div`
  width: 340px;
  @media ${p => p.theme.breakpoints.smalldesktop} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      flex: 1;
      flex-wrap: wrap;
      :not(:last-child) {
        margin-right: ${p => p.theme.spacings.sm};
      }
    }
    .date-picker-container {
      flex: 1;
    }
  }
  @media ${p => p.theme.breakpoints.mobile} {
    display: block;
    > div {
      display: block;
    }
  }
  .date-picker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      :not(:last-child) {
        margin-right: ${p => p.theme.spacings.sm};
      }
    }
  }
`;
export interface SearchOrgFormParams {
  department?: SelectedOption<number | undefined>;
  group?: SelectedOption<number | undefined>;
  groupIds?: SelectedOption<number | undefined>[] | undefined;
  employee?: SelectedOption<number | undefined>;
  employeeGroups?: boolean;
  task?: SelectedOption<number | undefined>;
  unit?: SelectedOption<number | undefined>;
  startTime?: Date;
  endTime?: Date;
  exportToFile?: boolean;
}

interface Props {
  selectedOptions?: { [k: string]: SelectedOption<number | undefined> };
  onSelect?: (name: string, option: SelectedOption<number | undefined>) => void;
  onSubmit?: (searchOptions: SearchOrgFormParams) => void;
  selUnit?: boolean;
  selDepartment?: boolean;
  selTask?: boolean;
  selTime?: boolean;
  selGroup?: false;
  selEmployee?: false;
  exportCheckbox?: boolean;
  employeeGroupsCheckbox?: boolean;
}

const SearchOrgForm: FC<Props> = observer(
  ({ onSubmit, onSelect = orgStore.select.bind(orgStore), selectedOptions, selUnit = true, selDepartment = true, selTask = true, selTime = true, selGroup = true, selEmployee = true, exportCheckbox = true, employeeGroupsCheckbox = true }) => {
    const { t } = useTranslation();
    const [params, setParams] = useState<GetOrgOptionsParams>({ take: 100 });
    const { options } = orgStore;
    const [employeeGroups, setEmployeeGroups] = useState<boolean>(true);
    const { employee, group, department, unit, task } = selectedOptions || orgStore.selectedOptions;
    const [startTime, setStartTime] = useState<Date | undefined>();
    const [endTime, setEndTime] = useState<Date | undefined>();
    const [exportToFile, setExportToFile] = useState<boolean>(false);
    const idPrefix = 'org-search';
    const onChange = (name: string) => (option: SingleValue<SelectOption<number | undefined>>) => {
      const value = option?.value;
      switch (name) {
        case 'group':
          delete params.groupName;
          params.groupId = value;
          onSelect('group', option);
          break;
        case 'employee':
          delete params.employeeName;
          params.employeeId = value;
          onSelect('employee', option);
          break;
        case 'unit':
          delete params.unitName;
          params.unitId = value;
          onSelect('unit', option);
          break;
        case 'department':
          delete params.departmentName;
          params.departmentId = value;
          onSelect('department', option);
          break;
        case 'task':
          params.taskName = option?.label;
          onSelect('task', option);
          break;
      }
      setParams(params);
    };
    const handleSubmit = () => {
      if (!onSubmit) return;
      onSubmit({
        employee,
        group,
        unit,
        department,
        task,
        startTime,
        endTime,
        exportToFile,
        employeeGroups,
      });
    };

    const loadOptions = (name: string) => async (value: string) => {
      const newParams: GetOrgOptionsParams = { ...params };
      switch (name) {
        case 'group':
          newParams.groupName = value;
          newParams.groupId = undefined;
          break;
        case 'employee':
          newParams.employeeName = value;
          newParams.employeeId = undefined;
          break;
        case 'unit':
          newParams.unitName = value;
          newParams.unitId = undefined;
          break;
        case 'department':
          newParams.departmentName = value;
          newParams.departmentId = undefined;
          break;
        case 'task':
          newParams.taskName = value;
          newParams.taskId = undefined;
          break;
      }
      setParams(newParams);
      return await updateOptions(newParams, name);
    };
    const updateOptions = async (newParams: any, name?: string) => {
      const options = await orgStore.organizationSearch(newParams, name);
      if (name) return options[name + 's'];
    };

    return (
      <Container>
        <ShowTeam />
        <div className="org-selects">
          {selGroup && <ASelect defaults={options as any} name="group" value={group} onChange={onChange} loadOptions={loadOptions} />}
          {selEmployee && <ASelect defaults={options as any} name="employee" value={employee} onChange={onChange} loadOptions={loadOptions} />}
          {employeeGroupsCheckbox && employee && <Checkbox id={`employees-groups-checkbox`} label={t('search-by-employees-groups')} checked={employeeGroups} setValue={setEmployeeGroups} />}
        </div>
        <div className="org-selects">
          {selUnit && <ASelect defaults={options as any} name="unit" value={unit} onChange={onChange} loadOptions={loadOptions} />}
          {selDepartment && <ASelect defaults={options as any} name="department" value={department} onChange={onChange} loadOptions={loadOptions} />}
        </div>
        {selTask && (
          <div className="org-selects">
            <ASelect defaults={options as any} name="task" value={task} onChange={onChange} loadOptions={loadOptions} />
          </div>
        )}
        {selTime && (
          <div className="date-picker-container">
            <DatePicker
              id={`${idPrefix}-startdate-input`}
              label={t('startDay')}
              placeholder={t('inputPlaceholder.date')}
              startDate={startTime}
              endDate={endTime}
              selectsStart
              setDate={d =>
                setStartTime(
                  d
                    ? DateTime.fromJSDate(d as Date)
                        .startOf('day')
                        .toJSDate()
                    : d
                )
              }
              isClearable
            />
            <DatePicker
              id={`${idPrefix}-enddate-input`}
              label={t('endDay')}
              placeholder={t('inputPlaceholder.date')}
              startDate={startTime}
              endDate={endTime}
              selectsEnd
              setDate={d =>
                setEndTime(
                  d
                    ? DateTime.fromJSDate(d as Date)
                        .endOf('day')
                        .toJSDate()
                    : d
                )
              }
              isClearable
            />
          </div>
        )}
        <ButtonGroup>
          {exportCheckbox && <Checkbox id={`${idPrefix}-export-to-file-button`} label={t('exportResultsToExcel')} checked={exportToFile} setValue={setExportToFile} />}
          {onSubmit && <Button id={`${idPrefix}-submit-button`} text={t('search')} onClick={handleSubmit} color="primary" />}
        </ButtonGroup>
      </Container>
    );
  }
);

export default SearchOrgForm;

import React, { useEffect } from 'react';
import { NavLink, Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { employeeStore } from '../../mobx/employeeStore';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import DataNotFound from '../../components/DataNotFound';
import { employeeDataRoutes, path } from '../../routes/routes';
import styled from 'styled-components';
import { Button } from '../../components/inputs';
import { useTranslation } from 'react-i18next';

export * from './BasicData';
export * from './Groups';
export * from './PersonalData';
export * from './Education';
export * from './WorkExperience';
export * from './Notifications';

const Container = styled.div`
  #employee-data-back-button {
    margin-top: ${p => p.theme.spacings.xl};
  }
`;

const NavBar = styled.div`
  display: flex;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${p => p.theme.fonts.size.md};

  .navbar-item {
    display: block;
    padding: ${p => p.theme.spacings.md} ${p => p.theme.spacings.lg};
    color: ${p => p.theme.colors.text};
    border-radius: ${p => p.theme.borderRadius[0]} ${p => p.theme.borderRadius[0]} 0 0;
  }

  .navbar-item-active {
    color: ${p => p.theme.colors.primary};
    text-decoration: underline;
    background-color: ${p => p.theme.colors.white};
    border-top: 1px solid ${p => p.theme.colors.grayLighter};
    border-left: 1px solid ${p => p.theme.colors.grayLighter};
    border-right: 1px solid ${p => p.theme.colors.grayLighter};
  }
`;

const ContentArea = styled.div`
  padding: ${p => p.theme.spacings.xl};
  border-radius: 0 0 ${p => p.theme.borderRadius[0]} ${p => p.theme.borderRadius[0]};
  background-color: ${p => p.theme.colors.white};
  ${p => p.theme.shadows[0]};
  border-left: 1px solid ${p => p.theme.colors.grayLighter};
  border-right: 1px solid ${p => p.theme.colors.grayLighter};
`;

export interface EmployeeDataBaseProps {
  employee: Person;
}

export const EmployeeData = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ [key: string]: string }>();
  const { status, employee, error } = employeeStore;

  useEffect(() => {
    const employeeId = Number(id);
    if (employeeId) {
      employeeStore.getEmployee(employeeId);
    }
  }, []);

  const handleGoBack = () => {
    history.push(`/${path('stats')}`);
  };

  const title = employee ? `${employee.firstName} ${employee.lastName}` : '';

  return (
    <Layout>
      {status === 'BUSY' && <Loader />}
      <pre>
        {status}
        {JSON.stringify(employee, null, 2)}
      </pre>

      {!employee && ['FETCHED', 'ERROR'].includes(status) && <DataNotFound error={error} />}

      {employee && (
        <Container>
          <h1>{title}</h1>
          <NavBar>
            {employeeDataRoutes.map(({ path, title }) => (
              <NavLink key={path} className="navbar-item" activeClassName="navbar-item-active" to={path.replace(':id', id)}>
                {title}
              </NavLink>
            ))}
          </NavBar>
          <Button id="employee-data-back-button" text={t('backToStats')} icon={<>{'< '}</>} onClick={handleGoBack} variant="outlined" />
        </Container>
      )}
    </Layout>
  );
});

export default EmployeeData;

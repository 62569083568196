import React, { FC, ReactNode } from 'react';

import Header from './Header';
import NavBar from './NavBar';
import Wrapper from './Wrapper';

export const Layout: FC<{ children: ReactNode; noPadding?: boolean }> = ({ children, noPadding }) => {
  return (
    <>
      <Header />
      <NavBar>
        <Wrapper noPadding={noPadding}>{children}</Wrapper>
      </NavBar>
    </>
  );
};

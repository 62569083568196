import { EventSourcePolyfill } from 'event-source-polyfill';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { api } from '../services/api';
import apiConfig from '../services/api/config';
import notify from '../services/notify';
import { ChatRoom } from './ChatRoom';
import { userStore } from './userStore';

const EventSource = EventSourcePolyfill;
const heartbeatTimeout = 1000 * 60 * 15; // = 15minutes, SSE heartbeat timeout, make this big to avoid unnessery connection drops
const REOPEN_DELAY = 1000 * 5; // = 5 seconds, delay before reconnecting to SSE
export const CHATLIST_DEFAULT_PAGE_SIZE = 50; // how many items is default take size

export enum ChatEventType {
  NEW_MESSAGE,
  USER_JOINED,
  USER_LEFT,
}

export type ChatMessageEventPayload = [
  chatId: number,
  type: ChatEventType,
  timestamp?: number,
  authorId?: number,
  messageId?: number,
  _?: number,
  messageData?: { user: IMinimalUser; message: ChatMessage; files: FileAttachment[] }
];

class ChatEventStore {
  private sse?: EventSource;
  private reopenTimeout?: NodeJS.Timeout;
  status: 'EMPTY' | 'BUSY' | 'CHANGED' | 'INITIAL FETCH' | 'FETCHED' | 'FETCHED OLDER MESSAGES' | 'CHANGED TITLE' | 'ERROR' = 'EMPTY';
  eventStatus: 'CLOSED' | 'OPEN' | 'OPENING' | 'NEW_EVENT' | 'EVENT_HANDLED' | 'ERROR' | 'REOPENING' = 'CLOSED'; // status of event source
  chatNotificationStatus: 'EMPTY' | 'PENDING' | 'SENT' = 'EMPTY';
  events: ChatMessageEventPayload[] = []; // unhandled events
  currentChat?: ChatRoom; // chat that user is currently in if any
  chats: Map<number, ChatRoom> = new Map<number, ChatRoom>(); // list of all current user has access to
  chatList: ChatRoom[] = []; // list of chat rooms
  totalChats: number = 0; // number of chats
  chatListParams: ISearchChatsParams = {}; // params chatlist was searched with
  userListParams: IChatUserSearchParams = {}; // params availableUsers was searched with
  availableUsers: ChatUser[] = []; // list of user available to add to chat
  availableGroups: ChatGroup[] = [];
  totalAvailableUsers: number = 0;
  totalAvailableGroups: number = 0;
  selectedChatId?: number;
  gotoMessageId?: number;

  foundMessage: { messageId: number | undefined; roomId: number | undefined } = { messageId: undefined, roomId: undefined };

  constructor() {
    makeAutoObservable(this);
    // this.getAvailableChats({ skip: 0, take: 10, search: undefined, status:'active' }); //inital fetch
  }

  reset() {
    this.currentChat = undefined;
    this.status = 'EMPTY';
    this.chats = new Map();
    this.chatList = [];
    this.totalChats = 0;
    this.chatListParams = {};
    this.userListParams = {};
    this.availableGroups = [];
    this.totalAvailableGroups = 0;
    this.availableUsers = [];
    this.totalAvailableUsers = 0;
  }

  @action
  private async handleEvent(chatEvent: ChatMessageEventPayload) {
    this.eventStatus = 'NEW_EVENT';
    const [chatId, type] = chatEvent;
    if (!this.chats.has(chatId)) {
      // some chat that is not in the list, create it and populate it
      this.chats.set(chatId, new ChatRoom(chatId, { title: `chat ${chatId}` }));
      await this.getChat({ chatId });
    }
    const chat = this.chats.get(chatId) as ChatRoom;
    if (this.currentChat?.id === chatId) {
      // remove attention key since we are in this chat
      // appStore.setAttention('chat_event', false); <- commented out because this sets the general attention value for all chatrooms in appstore when typed in a single chatroom.
      // we are in this  chat, should update messages
      switch (type) {
        case ChatEventType.NEW_MESSAGE:
          const lastMessageId = chat.messages[chat.messages.length - 1]?.id || undefined;
          return this.getChat({ chatId, lastMessageId, params: { skip: 0, take: CHATLIST_DEFAULT_PAGE_SIZE }, select: false, background: true });
        case ChatEventType.USER_JOINED:
        case ChatEventType.USER_LEFT:
          return this.getChatUsers(chatId);
      }
    } else if (!this.currentChat?.id) {
      //this.getAvailableChats({ skip: 0, take: CHATLIST_DEFAULT_PAGE_SIZE });
      // // set attention key
      // appStore.setAttention('chat_event', true);
      // // we are not in this chat, should update chat list only
      chat.registerChatEvent(chatEvent);
      const sortedChatList = this.sortChatList(this.chatList);
      this.chatList = sortedChatList;
      this.eventStatus = 'EVENT_HANDLED';
    }
  }

  sortChatList(chatList: ChatRoom[]) {
    const sortedChatList = chatList.sort((a: ChatRoom, b: ChatRoom) => {
      if (a.newEvents && b.newEvents) {
        return b.timestamp - a.timestamp;
      }
      // Prioritize objects with newEvents
      else if (a.newEvents) {
        return -1;
      } else if (b.newEvents) {
        return 1;
      }
      // If both objects have newMessages or neither does, sort by their timestamp
      else if ((a.newMessages && b.newMessages) || (!a.newMessages && !b.newMessages)) {
        return b.timestamp - a.timestamp;
      }
      // Prioritize objects with newMessages
      else if (a.newMessages) {
        return -1;
      } else if (b.newMessages) {
        return 1;
      } else {
        return b.timestamp - a.timestamp;
      }
    });

    return sortedChatList;
  }

  // create new chat
  @action
  async newChat(params: INewChatParams): Promise<number | void> {
    runInAction(() => {
      this.status = 'BUSY';
    });
    const result = await api.newChat(params);
    if (result.ok && result.data) {
      const { id, ...props } = result.data;
      // could use result data as chat data, but we will fetch it again
      if (!this.chats.has(id)) {
        this.chats.set(id, new ChatRoom(id, props));
      }
      this.availableGroups = [];
      this.availableUsers = [];
      this.getChat({ chatId: id, select: true }); // select chat
      return id;
    } else {
      runInAction(() => {
        this.status = 'ERROR';
        notify.error(result?.data?.message);
      });
    }
  }

  // get available chats to the user
  @action
  async getAvailableChats(params: ISearchChatsParams = {}, clearChats?: boolean): Promise<ChatRoom[] | undefined> {
    const {
      skip = 0,
      take = CHATLIST_DEFAULT_PAGE_SIZE,
      searchTitle,
      searchMessage,
      groupId,
      searchGroup,
      userId,
      searchUser,
      status = 'active',
    } = params || this.chatListParams;
    runInAction(() => {
      this.status = 'BUSY';
    });
    const result = await api.listChats({ skip, take, searchTitle, searchMessage, groupId, searchGroup, userId, searchUser, status });
    if (result.ok && result.data) {
      const foundRooms: ChatRoom[] = [];
      if (clearChats) {
        this.chats = new Map();
      }
      const { results, total, params: resultParams } = result.data;
      results.forEach(chat => {
        const chatRoom = new ChatRoom(chat.id, chat);
        if (this.chats.has(chat.id)) {
          // update item
          const existing = this.chats.get(chat.id) as ChatRoom;
          existing.title = chat.title;
          existing.label = chat.label;
          existing.status = chat.status || 'opening';
          existing.team = chat.team;
          existing.timestamp = chat.timestamp;
          existing.newMessages = chat.newMessages;
          existing.createdBy = chat.createdBy;
        } else {
          // create new item
          this.chats.set(chat.id, chatRoom);
        }
        foundRooms.push(chatRoom);
      });
      runInAction(() => {
        this.status = 'FETCHED';
        this.totalChats = total;
        this.chatListParams = resultParams;
        this.chatList = this.chatListArray();
      });

      const sortedFoundRooms = this.sortChatList(foundRooms);
      // tell notificationhandler if there are any unread messages, notification handler will decide if notification is shown based on current path
      if (sortedFoundRooms.some(chat => chat.newMessages && chat.newMessages > 0) && this.chatNotificationStatus === "EMPTY") {
        this.setNotificationStatus('PENDING');
      }

      return sortedFoundRooms;
    } else {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }

  // paging
  @action
  getMoreChats() {
    if (this.chatList.length < this.totalChats) {
      const { skip = 0, searchTitle, searchMessage, groupId, searchGroup, userId, searchUser } = this.chatListParams;
      return this.getAvailableChats({
        skip: skip + CHATLIST_DEFAULT_PAGE_SIZE,
        take: CHATLIST_DEFAULT_PAGE_SIZE,
        searchTitle,
        searchMessage,
        groupId,
        searchGroup,
        userId,
        searchUser,
      });
    }
  }

  // get chat by id
  // with lastMessageId only messages after given id will be fetched (update)
  @action
  async getChat({
    chatId,
    lastMessageId,
    gotoMessageId,
    params,
    select,
    background,
    fetchStatus,
    scrollToBottom,
  }: {
    chatId: number;
    lastMessageId?: number;
    gotoMessageId?: number;
    params?: { skip: number; take: number };
    select?: boolean;
    background?: boolean;
    fetchStatus?: 'FETCHED OLDER MESSAGES' | 'INITIAL FETCH';
    scrollToBottom?: boolean;
  }) {
    const { skip, take } = params || (lastMessageId ? {} : { skip: 0, take: CHATLIST_DEFAULT_PAGE_SIZE });
    runInAction(() => {
      this.status = 'BUSY';
    });
    const result = await api.getChat({ chatId, lastMessageId, gotoMessageId, skip, take });
    if (result.ok && result.data) {
      const { timestamp, messages, messagesTotal, users, groups, params: chatRoomParams, title, createdAt, updatedAt, status } = result.data;
      const chat = this.chats.get(chatId) as ChatRoom;
      runInAction(() => {
        this.status = fetchStatus || 'FETCHED';
        if (messages?.length) {
          const newMessageIds = messages?.map((message: any) => message.id);
          // if skip!===0 concat fetched messages to existing list of messages
          const newMessages =
            params?.skip === 0 && scrollToBottom
              ? messages.sort((a, b) => a.id - b.id)
              : chat.messages
                  .filter(message => !newMessageIds.includes(message.id))
                  .concat(messages)
                  .sort((a, b) => a.id - b.id);
          chat.messages = newMessages;
          chat.messagesTotal = messagesTotal;
          // chat.photo = chat.users?.[0]?.photo;
        }
        chat.params = chatRoomParams;
        chat.title = title;
        chat.status = status;
        if (createdAt) chat.createdAt = new Date(createdAt);
        if (updatedAt) chat.updatedAt = new Date(updatedAt);
        if (!background) {
          chat.resetEventCount(); // reset event count (user has seen this chat)
        }
        if (users?.length) {
          chat.users = users;
        }
        if (groups?.length) {
          chat.groups = groups;
        }
        chat.timestamp = timestamp;
        if (select) {
          this.currentChat = chat;
          this.selectedChatId = chat.id;
          this.gotoMessageId = gotoMessageId;
        }
        this.chatList = this.chatListArray();
      });
    } else {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }

  @action
  async getOlderMessages(chatId: number): Promise<boolean> {
    if (this.status === 'BUSY') {
      return false;
    }
    const chat = this.chats.get(chatId) as ChatRoom;
    const { skip } = chat.params || { skip: 0 };
    await this.getChat({
      chatId,
      params: { skip: Number(skip) + CHATLIST_DEFAULT_PAGE_SIZE, take: CHATLIST_DEFAULT_PAGE_SIZE },
      select: true,
      fetchStatus: 'FETCHED OLDER MESSAGES',
    });
    return true;
  }

  @action
  async getChatUsers(chatId: number) {
    runInAction(() => {
      this.status = 'BUSY';
    });
    const result = await api.getChatUsers(chatId);
    if (result.ok && result.data) {
      const { users } = result.data;
      if (users?.length) {
        const chat = this.chats.get(chatId) as ChatRoom;
        chat.users = users;
      }
      runInAction(() => {
        this.status = 'FETCHED';
      });
    } else {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }

  @action
  clearSearchResults() {
    runInAction(() => {
      this.availableUsers = [];
      this.availableGroups = [];
    });
  }

  // search and list chat users req user may add to chat
  @action
  async chatAddUsersList(params: IChatUserSearchParams = {}) {
    runInAction(() => {
      this.status = 'BUSY';
      this.clearSearchResults();
    });
    const result = await api.chatAddUsersList(params);
    if (result.ok && result.data) {
      const { total, results, params } = result.data;
      runInAction(() => {
        this.status = 'FETCHED';
        this.totalAvailableUsers = total;
        this.userListParams = params;
        this.availableUsers = results;
      });
    } else {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }

  @action
  async chatAddGroupsList(params: IChatGroupSearchParams = {}) {
    runInAction(() => {
      this.status = 'BUSY';
      this.clearSearchResults();
    });
    const result = await api.chatAddGroupsList(params);
    if (result.ok && result.data) {
      const { total, results, params } = result.data;
      runInAction(() => {
        this.status = 'FETCHED';
        this.totalAvailableGroups = total;
        this.userListParams = params;
        this.availableGroups = results;
      });
    } else {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }

  // send message to given chat
  @action
  async sendMessage(chatId: number, messageFormData: FormData) {
    runInAction(() => {
      this.status = 'BUSY';
    });
    const result = await api.sendChatMessage({ chatId, messageFormData });
    if (result.ok && result.data) {
      // NOTE: server event should automatically update chat
      runInAction(() => {
        this.status = 'CHANGED';
      });
      /* if you want to update chat manually do it with
      this.updateChat(chatId)
      */
    } else {
      runInAction(() => {
        this.status = 'ERROR';
        notify.error(result?.data?.message || 'error', undefined, false);
      });
    }
  }

  @action
  async archiveChat(chatId: number) {
    runInAction(() => (this.status = 'BUSY'));
    await api.archiveChat(chatId);
    this.getAvailableChats();
  }

  @action
  async restoreChat(chatId: number) {
    runInAction(() => (this.status = 'BUSY'));
    await api.restoreChat(chatId);
    this.getAvailableChats();
  }

  @action
  async selectChat(chatId: number, gotoMessageId?: number) {
    let chat = this.chats.has(chatId) ? this.chats.get(chatId) : undefined;
    if (!chat) {
      // chat not found create emply chat
      chat = new ChatRoom(chatId);
      this.chats.set(chatId, chat);
    }
    // pull status of the chat, if lastMessageId is set will pull changes only since lastMessageId (including it)
    this.currentChat = chat;
    this.selectedChatId = chatId;
    this.gotoMessageId = gotoMessageId;
    // NOTE if lastMessageId is given get chat fetches messages from that message forwards. use case is when you want to just get changes since the lastMessage showing in the UI
    // or if you want to retrive only found message
    //const lastMessageId = (chat.messages.length && chat.messages[chat.messages.length - 1]?.id) || undefined; //this should disable if we want to fetch paged results from latest -> backwards in time
    await this.getChat({
      chatId,
      gotoMessageId,
      params: { skip: 0, take: CHATLIST_DEFAULT_PAGE_SIZE },
      select: true,
      fetchStatus: 'INITIAL FETCH',
    });
  }

  chatListArray(): ChatRoom[] {
    const chatList = Array.from(this.chats.values());
    const sortedChatList = this.sortChatList(chatList);
    return sortedChatList;
  }

  // listen to chats
  @action
  open() {
    const { token } = userStore; // note sse source needs token
    runInAction(() => {
      this.eventStatus = 'OPENING';
    });
    this.sse?.close(); // just in case
    this.sse = new EventSource(`${apiConfig.baseURL}/chat/events`, { heartbeatTimeout, headers: { Authorization: `Bearer ${token}` } });
    this.sse.onmessage = e => {
      runInAction(() => {
        this.eventStatus = 'NEW_EVENT';
        const chatEvent = JSON.parse(e.data);
        this.events.push(chatEvent);
        this.handleEvent(chatEvent);
      });
    };
    this.sse.onerror = () => {
      this.sse?.close();
      this.eventStatus = 'ERROR';
      this.reopen()
    };
    runInAction(() => {
      this.eventStatus = 'OPEN';
      this.getAvailableChats();
    });
  }

  @action
  close() {
    this.sse?.close();
    this.eventStatus = 'CLOSED';
  }

  @action
  reopen() {
    if (this.reopenTimeout) clearTimeout(this.reopenTimeout);
    runInAction(() => {
      this.eventStatus = 'REOPENING';
      this.reopenTimeout = setTimeout(() => {
        chatEventStore.open();
      }, REOPEN_DELAY);
    });
  }

  @action
  leaveChat() {
    runInAction(() => {
      this.reset();
      this.currentChat = undefined;
      this.status = 'CHANGED';
      this.getAvailableChats({ skip: 0, take: CHATLIST_DEFAULT_PAGE_SIZE, status: 'active' });
    });
  }

  @action
  async updateChat(chatId: number, title: string) {
    runInAction(() => {
      this.status = 'BUSY';
    });
    const result = await api.updateChat(chatId, { title });
    if (result.ok && result.data) {
      const { title } = result.data;
      runInAction(() => {
        const chat = this.chats.get(chatId) as ChatRoom;
        chat.title = title;
        this.currentChat = chat;
        this.status = 'CHANGED TITLE';
      });
    } else {
      runInAction(() => {
        this.status = 'ERROR';
        notify.error(result?.data?.error.message);
      });
    }
  }

  @action
  setFoundMessage(roomId: number | undefined, messageId: number | undefined) {
    runInAction(() => {
      this.foundMessage = { roomId: roomId, messageId: messageId };
    });
  }

  @action
  setNotificationStatus(status: 'EMPTY' | 'PENDING' | 'SENT') {
    this.chatNotificationStatus = status;
  }
}

export const chatEventStore = new ChatEventStore();

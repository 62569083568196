import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from './Label';
import { SelectContainer } from './Select';
import Async from 'react-select/async';

export const ASelect: FC<{
  name?: string;
  loadOptions: Function;
  onChange: Function;
  defaults?: { [k: string]: SelectOption<number | undefined>[] };
  defaultOptions?: SelectOption<number | undefined>[];
  value: SelectedOption<number | undefined>;
  isDisabled?: boolean;
}> = ({ name, value, defaultOptions, defaults, loadOptions, onChange, isDisabled = false }) => {
  const { t } = useTranslation();
  return (
    <SelectContainer>
      {name && <Label>{t(name)}</Label>}
      <Async
        id={`${name}-async`}
        placeholder={t('select')}
        loadOptions={loadOptions(name)}
        value={value}
        defaultOptions={defaultOptions || defaults?.[name + 's']}
        isDisabled={isDisabled}
        onChange={onChange(name)}
        isClearable={true}
        isSearchable={true}
      />
    </SelectContainer>
  );
};

import React, { FC, FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../components/inputs';
import Checkbox from '../../components/inputs/Checkbox';
import { SelectRecipients } from '../../components/SelectRecipients';

interface Props {
  onSubmit: (msg: Message) => void;
}

export const MessageForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [isEmail, setIsEmail] = useState(true);
  const [isSms, setIsSms] = useState(false);
  const [isPush, setIsPush] = useState(false);
  const [heading, setHeading] = useState('');
  const [message, setMessage] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState<SelectedOption<number>[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<SelectedOption<number>[]>([]);

  const clearInputs = () => {
    setIsEmail(true);
    setIsSms(false);
    setIsPush(false);
    setHeading('');
    setMessage('');
    setSelectedEmployees([]);
    setSelectedGroups([]);
  };

  // prettier-ignore
  const handleSend = (event: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    onSubmit({
      heading,
      message,
      isEmail, isSms, isPush,
      recipientIds: (selectedEmployees.length && selectedEmployees.map((o: any) => Number(o.value))) || undefined,
      personGroupIds: (selectedGroups.length && selectedGroups.map((o: any) => Number(o.value))) || undefined
    })
    //  clearInputs();
  };

  const isSendingDisabled = (!isSms && !isEmail && !isPush) || !heading.length || !message.length || (!selectedEmployees.length && !selectedGroups.length);

  return (
    <Form onSubmit={handleSend}>
      <Checkbox id="messages-checkbox-email" checked={isEmail} label={t('email')} setValue={setIsEmail} />
      <Checkbox id="messages-checkbox-sms" checked={isSms} label={t('sms')} setValue={setIsSms} />
      <Checkbox id="messages-checkbox-push" checked={isPush} label={t('pushNotification')} setValue={setIsPush} />
      <Input id="messages-input-heading" value={heading} label={t('heading')} setValue={setHeading} />
      <Input id="messages-input-content" value={message} label={t('message')} setValue={setMessage} asTextarea />
      <SelectRecipients {...{ setSelectedGroups, selectedGroups, setSelectedEmployees, selectedEmployees }} />
      <Button id="messages-send-button" type="submit" text={t('send')} onClick={handleSend} color="primary" disabled={isSendingDisabled} />
    </Form>
  );
};

export default MessageForm;

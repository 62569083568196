import React, { FC, useEffect } from 'react';

import { View } from '../../components/View';
import ListReservedShifts from './ListReservedShifts';

export const Home: FC = () => {
  return (
    <View defaultTitle="newest shift reservations">
      <ListReservedShifts />
    </View>
  );
};

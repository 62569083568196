import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { userStore } from '../../mobx/userStore';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NavLinkRoute, userRoutes } from '../../routes/routes';
import { Icon } from '../Icon';
import { Button } from '../inputs';

const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  height: 100%;
  margin-left: ${p => p.theme.spacings.md};
  user-select: none;

  .user-menu-button {
    display: flex;
    align-items: center;
    height: 100%;
    padding: ${p => p.theme.spacings.md};
    cursor: default;
    background-color: ${p => (p.isOpen ? p.theme.colors.primaryDarker : 'transparent')};
    transition: background-color 200ms ease-in-out;

    svg {
      margin-left: ${p => p.theme.spacings.md};
    }
  }
`;

const MenuList = styled.ul<{ isOpen: boolean }>`
  list-style-type: none;
  background-color: ${p => p.theme.colors.white};
  position: absolute;
  top: ${p => p.theme.spacings.headerHeight};
  @media ${p => p.theme.breakpoints.mobile} {
    top: 40px;
  }
  padding: 0 ${p => p.theme.spacings.lg};
  min-width: 250px;
  max-width: 300px;
  ${p => p.theme.shadows[0]};
  border-radius: ${p => p.theme.borderRadius[0]};
  color: ${p => p.theme.colors.text};

  right: ${p => (p.isOpen ? 0 : '-330px')};
  transition: right 200ms ease-in-out;

  li > .menu-item {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    padding: ${p => p.theme.spacings.lg};
    text-decoration: none;
    color: inherit;

    opacity: 0.7;
    &:hover {
      opacity: 1;
    }

    svg {
      margin-right: ${p => p.theme.spacings.lg} !important;
      fill: ${p => p.theme.colors.grayDarkest};
    }
  }

  li {
    :not(:last-child) {
      border-bottom: 1px solid ${p => p.theme.colors.grayLighter};
    }
  }
`;

const Menu: FC<{ isOpen: boolean; items: NavLinkRoute[] }> = observer(({ isOpen, items }) => {
  const { t } = useTranslation();

  const logout = async () => {
    await userStore.logout();
    window.location.reload();
  };

  return (
    <MenuList isOpen={isOpen}>
      {items.map(({ path, icon, title }) => (
        <li key={path}>
          <NavLink to={path} className="menu-item">
            {icon && <Icon type={icon} />}
            <span>{t(title)}</span>
          </NavLink>
        </li>
      ))}
      <li onClick={logout}>
        <div className="menu-item">
          <Icon type="Lock" />
          <span>{t('logout')}</span>
        </div>
      </li>
    </MenuList>
  );
});

const UserMenu = observer(() => {
  const { t } = useTranslation();
  const { status, user } = userStore;
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = (value?: boolean) => () => setIsOpen(value ?? !isOpen);

  const logout = async () => {
    await userStore.logout();
    window.location.reload();
  };

  const userName = user ? `${user.firstName} ${user.lastName}` : '';

  useEffect(() => {
    if (!user && status !== 'BUSY') {
      userStore.getMe();
    }
  }, [user, status]);

  if (!user) {
    return <Button id="logout-button" onClick={logout} text={t('logout')} color="white" variant="link" />;
  }

  return (
    <Container onMouseLeave={toggleMenu(false)} isOpen={isOpen}>
      <div className="user-menu-button" onMouseEnter={toggleMenu(true)} onClick={toggleMenu(true)}>
        <span>{userName}</span>
        <Icon type="Person" color="white" />
      </div>
      <Menu isOpen={isOpen} items={userRoutes} />
    </Container>
  );
});

export default UserMenu;

import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { ActionMeta, components } from 'react-select';
import styled from 'styled-components';

import Label from './Label';

export const SelectContainer = styled.div`
  max-width: 600px;
  min-width: 150px;
  margin-top: ${p => p.theme.spacings.md};
  margin-bottom: ${p => p.theme.spacings.md};
`;
/* eslint-disable */
export type SelectValueType<T> = any;
/* eslint-enable */

export type SelectActionMeta<T> = ActionMeta<SelectOption<T>>;

interface Props<T> {
  id: string;
  options: SelectOption<T>[] | GroupedSelectOption<T>[];
  selected: SelectedOption<T> | any;
  inputValue?: string;
  onKeyDown?: (e: any) => void;
  onInputChange?: (value: any) => void;
  onChange?: (value: any, actionMeta: SelectActionMeta<T>) => void;
  setSelected?: Dispatch<SetStateAction<SelectedOption<T>>>;
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
}

const Input = (props: any) => {
  const { children, ...rest } = props;
  return <components.Input {...rest}>{children}</components.Input>;
};

const Option = (props: any) => {
  const { children, ...rest } = props;
  return (
    <components.Option
      {...rest}
      innerProps={{
        ...rest.innerProps,
      }}
    >
      {children}
    </components.Option>
  );
};

export const Select = <T,>({
  id,
  options,
  selected,
  inputValue,
  onKeyDown,
  onInputChange,
  onChange,
  setSelected,
  label,
  placeholder,
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
}: PropsWithChildren<Props<T>>) => {
  const { t } = useTranslation();

  const handleChange = (value: SelectValueType<T>) => {
    if (setSelected) setSelected(value);
  };

  return (
    <SelectContainer>
      {label && <Label>{label}</Label>}
      <ReactSelect
        id={id}
        defaultValue={selected}
        isMulti={isMulti}
        value={selected}
        inputValue={inputValue}
        onKeyDown={onKeyDown}
        onInputChange={onInputChange}
        onChange={onChange || handleChange}
        options={options}
        placeholder={placeholder ?? t('select')}
        noOptionsMessage={() => t('noOptions')}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        captureMenuScroll={true}
        components={{ Option, Input }}
        openMenuOnFocus={true}
      />
    </SelectContainer>
  );
};

export default Select;

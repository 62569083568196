import { action, makeAutoObservable } from 'mobx';
import { userStore } from './userStore';
import { api } from '../services/api';
import notify from '../services/notify';

class MessageStore {
  status: 'IDLE' | 'BUSY' | 'FETCHED' | 'ERROR' | 'SELECTED' | 'SENT' = 'IDLE';
  results?: MessageLog[];
  error?: any;
  total: number = 0;
  pages: number = 1;
  page: number = 1;
  twilioStatus?: any; // status from twilio
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @action
  async messageLog(params: SearchTerms) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const response = await api.messageLog({ ...params, token });
    if (response.ok && response.data) {
      this.results = response.data.results;
      this.total = response.data.total;
      this.pages = Math.ceil(this.total / params.take);
      this.page = Math.ceil(params.skip / params.take) + 1;
      this.status = 'FETCHED';
      return response.data;
    } else {
      this.error = response.originalError;
      this.status = 'ERROR';
    }
  }
  @action
  async sendMessage(message: Message): Promise<void | { recipientCount: number }> {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const response = await api.sendMessage({ message, token });
    if (response.ok) {
      this.status = 'SENT';
      notify.success('message-sent');
      return response.data;
    } else {
      this.error = response.originalError;
      notify.error('error-sending-message');
      this.status = 'ERROR';
    }
  }
  @action
  async getTwilioMessageStatus(sid: string): Promise<any> {
    const { token } = userStore;
    if (!token) {
      return;
    }
    this.status = 'BUSY';
    const response = await api.getTwilioMessageStatus({ sid, token });
    if (response.ok) {
      this.status = 'FETCHED';
      this.twilioStatus = response.data;
      return response.data;
    }
  }
  @action
  async clearTwilioMessageStatus() {
    this.status = 'BUSY';
    this.twilioStatus = undefined;
    this.status = 'FETCHED';
  }

  @action
  async markMessageAsUnread(msgId: number, chatRoomId: number) {
    const { token } = userStore;
    if (!token) {
      return;
    }
    this.status = 'BUSY';
    const response = await api.markMessageAsUnread({ chatId: chatRoomId, messageId: msgId });
    if (response.ok) {
      this.status = 'FETCHED';
      return response.ok;
    } else {
      this.error = response.originalError;
      this.status = 'ERROR';
      return response.ok;
    }
  }
}

export const messageStore = new MessageStore();

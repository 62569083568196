import { DateTime } from 'luxon';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../components/inputs';
import { shiftStore } from '../../../../mobx/shiftStore';
import { DefaultShiftTimes } from './DefaultShiftTimes';
import ShiftSetEditing from './ShiftSetEditing';
import { getShiftKeyByStartTime } from './utils';

export const DEFAULT_DATE = DateTime.local().set({ hour: 7, minute: 0 }).plus({ day: 0 });

export function getDefaultDateTime(datekey: string = 'def', format: string = 'd.M.yyyy H:mm'): DateTime {
  return DEFAULT_DATE;
}

export function saveAsDefaultDateTime(datekey: string = 'def', dd: Date, format: string = 'd.M.yyyy H:mm') {
  // save to localstorage with datekey
  localStorage.setItem(datekey, DateTime.fromJSDate(dd).toFormat(format));
}

export function getBlankShift(startDate?: Date, endDate?: Date, shiftKey?: ShiftKey): IShiftData {
  const defaultStart = startDate || getDefaultDateTime('shiftStart').toJSDate();
  const defaultEnd = endDate || DateTime.fromJSDate(defaultStart).plus({ hours: 7 }).toJSDate();
  return {
    startTime: defaultStart,
    endTime: defaultEnd,
    shiftKey: shiftKey || getShiftKeyByStartTime(defaultStart)?.value || 'morning',
  };
}

export function getBlankShiftSet(index: number, startTime?: Date, endTime?: Date, shiftKey?: ShiftKey): Partial<IShiftSetData> {
  return {
    index,
    shifts: [getBlankShift(startTime, endTime, shiftKey)],
  };
}

const TimePeriods: FC = () => {
  const { t } = useTranslation();
  const { unsavedShiftSetData = {} as ICreateOrUpdateShiftSetsData, shiftTimeDefaults } = shiftStore;
  const { common, shiftSets = [] } = unsavedShiftSetData;
  const [showEditDefaultShiftTimes, setShowEditDefaultShiftTimes] = useState<boolean>(false);

  const handleAddNewShiftSet = () => {
    // Initialize new shift set with values computed from latest shift set in the list
    const latestSet = shiftSets.length ? shiftSets[shiftSets.length - 1] : undefined;
    const latestShift = (latestSet && latestSet.shifts?.length && latestSet.shifts[latestSet.shifts.length - 1]) || undefined;
    const startTime =
      latestShift &&
      DateTime.fromJSDate(latestShift?.startTime || new Date())
        .plus({ days: 1 })
        .toJSDate();
    const endTime =
      latestShift &&
      DateTime.fromJSDate(latestShift?.endTime || new Date())
        .plus({ days: 1 })
        .toJSDate();
    shiftStore.change({ shiftSets: shiftSets.concat(getBlankShiftSet(shiftSets.length, startTime, endTime, latestShift?.shiftKey)) });
  };

  const handleDeleteShiftSet = (index: number) => () => {
    shiftStore.change({ shiftSets: shiftSets.filter((ss, i) => i !== index) });
  };

  const handleShiftSetUpdate = (index: number, updatedSet: Partial<IShiftSetData>) => {
    // Put updated shift set into same position it was in the shift sets array
    shiftStore.change({ shiftSets: shiftSets.map((set, i) => (index === i ? updatedSet : set)) });
  };

  return (
    <div>
      <Button id={`set-default-times`} text={t('set-default-times')} variant="mini" color="black" isSmall={true} disabled={!common?.departmentLikeitId || !common?.unitLikeitId} onClick={() => setShowEditDefaultShiftTimes(true)} />
      {showEditDefaultShiftTimes && <DefaultShiftTimes onHide={() => setShowEditDefaultShiftTimes(false)} />}

      {shiftSets?.map((shiftSet: Partial<IShiftSetData>, shiftSetIndex: number) => (
        <ShiftSetEditing
          key={shiftSetIndex + 'shift_set'}
          shiftSetIndex={shiftSetIndex}
          shiftSet={shiftSet}
          onDelete={handleDeleteShiftSet(shiftSetIndex)}
          onChange={(updatedSet: Partial<IShiftData>) => handleShiftSetUpdate(shiftSetIndex, updatedSet)}
        />
      ))}
      <Button id="new-shift-set-button" text={`+ ${t('addShiftSet')}`} onClick={handleAddNewShiftSet} variant="outlined" color="primary" isSmall />
    </div>
  );
};

export default TimePeriods;

import { action, makeAutoObservable, runInAction } from 'mobx';
import { userStore } from './userStore';
import { api } from '../services/api';
import notify from '../services/notify';

const PAGE_SIZE = 50;

class ReservedShiftsStore {
  status: 'IDLE' | 'BUSY' | 'FETCHED' | 'ERROR' = 'IDLE';
  reservedShifts?: IReservedShift[];
  lastPollAt?: Date;
  total: number = 0;
  page: number = 1;
  pages: number = 1;
  error?: any;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async listShiftSetsByReservation({ page = 1, ...rest }: ListShiftSetsByReservationRequest): Promise<any> {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    const reservedAtAfter = (page === 1 && this.page === 1 && this.lastPollAt) || undefined;
    runInAction(() => {
      this.status = 'BUSY';
      this.lastPollAt = new Date();
      this.page = page;
    });
    const take = PAGE_SIZE;
    const skip = page && (page - 1) * take;
    const result = await api.listShiftSetsByReservation({ token, skip, take, ...rest, reservedAtAfter });
    if (result.ok && result.data) {
      const { results, total } = result.data;
      runInAction(() => {
        this.error = undefined;
        if (!reservedAtAfter || !this.reservedShifts) {
          this.total = total;
          this.pages = Math.ceil(total / PAGE_SIZE);
          this.reservedShifts = results;
        } else {
          // polled result
          this.reservedShifts = results.concat(this.reservedShifts.filter(s => !results.find(r => r.id === s.id))).slice(0, PAGE_SIZE);
        }
        this.status = 'FETCHED';
      });
    } else {
      runInAction(() => {
        this.error = result.originalError;
        this.status = 'ERROR';
      });
      notify.error(this.error.message || 'error-fetching-shifts');
    }
  }
}

export const reservedShiftsStore = new ReservedShiftsStore();

import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EmployeeDataBaseProps } from '.';
import { Icon } from '../../components/Icon';
import { Button } from '../../components/inputs';
import { Todo } from '../../components/Todo';
import { dateToString } from '../../utils/date';
import List from '../../components/List';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

interface Props extends EmployeeDataBaseProps {}

export const BasicData: FC<Props> = ({ employee }) => {
  const { t } = useTranslation();

  const listKeys: (keyof Person)[] = ['lastLoginAt', 'active', 'description'];

  const handleEditEmployeeData = () => {
    console.log('Todo: Edit employee basic data!');
  };

  const renderEmployeeValueInKey = (key: keyof Person) => {
    switch (key) {
      case 'lastLoginAt':
        const date = employee[key];
        const dateString = date ? dateToString(date, 'dd.MM.yyyy') : '';
        const daysAgo = date ? DateTime.fromJSDate(date).toRelative({ unit: 'days' }) : '';
        return `${dateString} (${daysAgo})`;

      case 'active':
        return employee[key] ? t('yes') : t('no');

      default:
        return `${employee[key]}`;
    }
  };

  return (
    <Container>
      <List<Person> objectKeys={listKeys} renderValueInKey={renderEmployeeValueInKey} />

      <div>
        <Button id="employee-basic-data-edit-button" text={t('edit')} icon={<Icon type="Edit" color="primary" />} onClick={handleEditEmployeeData} variant="outlined" color="primary" isSmall />
        <Todo noMargin />
      </div>
    </Container>
  );
};

export default BasicData;

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { path } from '../../routes/routes';
import { Icon } from '../Icon';
import { LinkButton } from '../inputs';
import Logo from '../Logo';
import UserMenu from './UserMenu';
import { userStore } from '../../mobx/userStore';
import { chatEventStore } from '../../mobx/chatEventStore';
import { handleNotifications } from '../../utils/notifications';

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${p => p.theme.spacings.headerHeight};
  background: transparent linear-gradient(180deg, ${p => p.theme.colors.primary} 0%, ${p => p.theme.colors.primaryDarker} 100%) 0% 0% no-repeat padding-box;
  color: ${p => p.theme.colors.white};

  > div {
    display: flex;
    align-items: center;
    margin: 0 ${p => p.theme.spacings.lg};
    height: 100%;
  }

  @media ${p => p.theme.breakpoints.mobile} {
    .header-logo {
      svg {
        width: 130px;
      }
    }
    > div {
      display: block;
      height: auto;
      > * {
        padding: 0;
        margin: 0;
      }
    }
    a {
      min-width: 0;
      padding: 0 ${p => p.theme.spacings.md};

      svg {
        margin: 0 !important;
      }
    }
  }
`;

const Version = styled.div`
  text-align: right;
  color: #fff;
  small {
    display: block;
  }
  @media ${p => p.theme.breakpoints.mobile} {
    position: fixed;
    top: 0;
    left: 16px;
    small {
      display: inline-block;
      margin-right: 5px;
    }
  }
`;

const Header = observer(() => {
  let location = useLocation()
  const { t } = useTranslation();

  useEffect(() => {
    userStore.healthCheck();
  }, []);

  useEffect(() => {
    handleNotifications(location.pathname)
  },[chatEventStore.chatNotificationStatus])

  return (
    <Container>
      <div className="header-logo">
        <Logo />
      </div>
      <div>
        <LinkButton href={`/${path('create-new-shifts')}`} color="white">
          <Icon type="Add" color="primary" />
          {t('create-new-shifts')}
        </LinkButton>
        <UserMenu />
        <Version>
          <small>web:{process.env.REACT_APP_VERSION}</small>
          <small>api:{userStore.apiVersion}</small>
        </Version>
      </div>
    </Container>
  );
});

export default Header;

import styled from 'styled-components';

export const Fill = styled.div`
  flex: 1 1 0;
`;
export const Rows = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
  @media ${p => p.theme.breakpoints.mobile} {
    display: block;
  }
  > * {
    flex: 1;
    :not(:first-child) {
      margin-left: ${p => p.theme.spacings.md};
      @media ${p => p.theme.breakpoints.mobile} {
        margin-left: 0;
      }
    }
    :not(:last-child) {
      margin-right: ${p => p.theme.spacings.md};
      @media ${p => p.theme.breakpoints.mobile} {
        margin-right: 0;
      }
    }
  }
  > .shrink {
    flex: 0 1 0;
  }
`;

export const ShiftSetContainer = styled.div`
  max-width: 1000px;
  background-color: ${p => p.theme.colors.grayLightest};
  padding: ${p => p.theme.spacings.md} ${p => p.theme.spacings.lg};
  margin: ${p => p.theme.spacings.lg} 0;
  border: 1px solid ${p => p.theme.colors.grayLighter};
  border-radius: ${p => p.theme.borderRadius[0]};

  .remove-shift-set-button,
  .remove-shift-button {
    cursor: pointer;
    user-select: none;
    opacity: 0.6;
    :hover {
      opacity: 1;
    }
  }

  header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .shift-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @media ${p => p.theme.breakpoints.tablet} {
      flex-direction: column;
    }

    .shift-details-column {
      :first-child {
        width: 80%;
        @media ${p => p.theme.breakpoints.tablet} {
          width: 100%;
        }
      }
      .shift-details-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .remove-shift-button {
          position: absolute;
          right: -24px;
        }

        > div {
          margin-right: 10px;
        }

        .shift-input {
          width: 120px;
          @media ${p => p.theme.breakpoints.tablet} {
            width: 100%;
          }
        }

        .shift-show-move-button {
          width: 120px;
          justify-content: center;
          align-items: center;
        }

        .shift-input,
        .shift-input-employee {
          flex: 1;
        }

        @media ${p => p.theme.breakpoints.tablet} {
          flex-direction: column;
          align-items: inherit;

          &__time-details {
            padding: 10px;
            margin-top: 20px;
            margin-bottom: 20px;
            border: 1px solid ${p => p.theme.colors.grayLighter};
            border-radius: ${p => p.theme.borderRadius[0]};
          }

          .remove-shift-button {
            right: -10px;
            top: -5px;
          }

          .shift-input-time,
          .shift-input-date {
            width: inherit;
          }
        }
      }
    }
  }
`;

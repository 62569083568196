import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  border: 1px solid ${p => p.theme.colors.grayDarker};
  border-radius: ${p => p.theme.borderRadius[0]};
  margin-top: ${p => p.theme.spacings.lg};
  margin-bottom: ${p => p.theme.spacings.lg};
  > div {
    padding: ${p => p.theme.spacings.sm} ${p => p.theme.spacings.lg};
    font-family: ${p => p.theme.fonts.secondary};
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: ${p => p.theme.fonts.size.md};
    user-select: none;
    cursor: pointer;
    flex: 1 1 0;

    :not(:last-child) {
      border-right: 1px solid ${p => p.theme.colors.grayDarker};
    }

    :hover:not(.is-active) {
      text-decoration: underline;
    }

    &.is-active {
      background-color: ${p => p.theme.colors.primary};
      color: ${p => p.theme.colors.white};
      cursor: default;
    }
  }

  @media ${p => p.theme.breakpoints.mobile} {
    > div {
      padding: ${p => p.theme.spacings.sm};
    }
  }
`;

interface Props<T> {
  selected: T[];
  setSelected: React.Dispatch<React.SetStateAction<T[]>>;
  options: T[];
}

/**
 * Type parameter T has to be some union of STRINGS (or simply 'string')
 */
const FilterSelectBar = <T,>({ selected, setSelected, options }: Props<T>) => {
  const { t } = useTranslation();

  const handleChange = (option: T) => () => {
    const opt: T[] = (option as unknown as string).split('!') as unknown as T[];
    if (selected.includes(opt[0])) {
      setSelected(selected.filter(o => o !== opt[0]));
    } else {
      setSelected([...selected.filter(s => s !== opt[1]), opt[0]]);
    }
  };

  return (
    <Container>
      {options.map(option => {
        const opt = (option as unknown as string).split('!')[0];
        return (
          <div data-testid={`filter-selection-${option}`} key={`filters-${option}`} className={selected.includes(opt as unknown as T) ? 'is-active' : ''} onClick={handleChange(option)}>
            {t(opt)}
          </div>
        );
      })}
    </Container>
  );
};

export default FilterSelectBar;

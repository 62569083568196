import { action, makeAutoObservable, runInAction } from 'mobx';

import { api } from '../services/api';
import STORAGE from '../services/storage';

class UserStore {
  apiVersion?: string | null;
  token: string | null;
  error?: any;
  user?: Person;
  status: 'BUSY' | 'NOT_LOGGED_IN' | 'LOGGED_IN' | 'PASSCODE_SENT' | 'PASS_CODE_FAILED' | 'ERROR' | 'FETCHED';
  fetchedUsersById?: IMinimalUser[] = [];
  constructor() {
    const saved = STORAGE.read({ key: 'USER_TOKEN' });
    this.token = saved?.token;
    this.user = saved?.user;
    this.status = this.token ? 'LOGGED_IN' : 'NOT_LOGGED_IN';
    makeAutoObservable(this);
  }
  @action
  async sendPasscode(email: string, phoneNumber: string) {
    this.status = 'BUSY';
    const response = await api.sendPasscode({ email, phoneNumber });
    if (response.ok) {
      this.error = undefined;
      this.status = 'PASSCODE_SENT';
    } else {
      this.error = response.originalError;
      this.status = 'ERROR';
    }
  }
  @action
  async login(passcode: string) {
    this.status = 'BUSY';
    const response = await api.login({ passcode });
    if (response.ok && response.data) {
      const { token } = response.data;
      this.token = token;
      STORAGE.write({ key: 'USER_TOKEN', value: { token } });
      this.status = 'LOGGED_IN';
    } else {
      this.status = 'PASS_CODE_FAILED';
    }
  }

  @action
  async logout() {
    STORAGE.write({ key: 'USER_TOKEN', value: { token: null } });
    if (!this.token) {
      this.status = 'NOT_LOGGED_IN';
      return;
    }
    this.status = 'BUSY';
    const response = await api.logout({ token: this.token });
    if (response.ok) {
      this.token = null;
      STORAGE.write({ key: 'USER_TOKEN', value: {} });
      this.status = 'NOT_LOGGED_IN';
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  async getMe() {
    if (!this.token) {
      this.status = 'NOT_LOGGED_IN';
      return;
    }
    this.status = 'BUSY';
    const response = await api.getMe({ token: this.token });
    if (response.ok && response.data) {
      const user = response.data;
      this.user = user;
      STORAGE.write({ key: 'USER_TOKEN', value: { token: this.token, user } });
      this.status = 'LOGGED_IN';
    } else {
      this.status = 'ERROR';
    }
  }

  // todo: store fetched users so that there is no need to fetch same ones multiple times
  @action
  async getUsersByIds(userIds: number[]) {
    runInAction(() => {
      this.status = 'BUSY';
    });
    const userIdsAsStrings = userIds?.map(id => id.toString()).join(',');
    const result = await api.getUsersByIds(userIdsAsStrings);
    if (result.ok && result.data) {
      runInAction(() => {
        this.status = 'FETCHED';
        this.fetchedUsersById = result.data;
      });
    } else {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }

  @action
  async healthCheck() {
    const response = await api.healthCheck();
    if (response.ok && response.data) {
      this.apiVersion = response.data.version;
    }
  }
  @action
  cancelLogin() {
    if (this.status !== 'NOT_LOGGED_IN') this.status = 'NOT_LOGGED_IN';
  }
}

export const userStore = new UserStore();

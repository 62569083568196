import styled from 'styled-components';

export const Label = styled.label`
  font-weight: bold;
  font-size: ${p => p.theme.fonts.size.md};
  user-select: none;
  margin: ${p => p.theme.spacings.sm} 0;
`;

export default Label;

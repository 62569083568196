import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ noMargin?: boolean }>`
  padding: 10px 40px;
  margin: ${p => (p.noMargin ? 0 : 20)}px;
  border-radius: 5px;
  background-color: #ffff99;
  color: #bbbb00;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  opacity: 0.7;

  > div {
    font-size: 14px;
  }
`;

interface Props {
  todo?: string;
  notes?: string;
  noMargin?: boolean;
}

export const Todo: FC<Props> = ({ todo, notes, noMargin }) => {
  return (
    <Container noMargin={noMargin}>
      TODO{todo && `: ${todo}`}
      {notes && <div>{notes}</div>}
    </Container>
  );
};

import React, { FC } from 'react';
import { View } from '../../components/View';
import { GuideEditor } from './GuideEditor';
import { ListGuides } from './ListGuides';
const Main: FC = () => {
  return (
    <View defaultTitle="guide">
      <ListGuides />
    </View>
  );
};

export const Guide = {
  Main,
  GuideEditor,
};

import { action, makeAutoObservable } from 'mobx';
import { userStore } from './userStore';
import { api } from '../services/api';
import notify from '../services/notify';

class GuideStore {
  status: 'IDLE' | 'BUSY' | 'NEW' | 'FETCHED' | 'ERROR' | 'CHANGED' | 'SAVED' | 'DELETED' = 'IDLE';
  error?: any;
  results?: Omit<Guide, 'content'>[];
  total?: number;
  selected?: Partial<Guide>;

  constructor() {
    makeAutoObservable(this);
  }
  @action
  create() {
    this.status = 'NEW';
    this.selected = {
      title: '',
      content: '',
    };
  }
  @action
  changed() {
    this.status = 'CHANGED';
  }
  @action
  async list() {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const response = await api.listGuides({ token });
    if (response.ok && response.data) {
      const { results, total } = response.data;
      this.error = undefined;
      this.results = results;
      this.total = total;
      this.status = 'FETCHED';
    } else {
      this.error = response.originalError;
      this.status = 'ERROR';
    }
  }

  @action
  async get(id: number) {
    const { token } = userStore;
    if (!token || !id) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const response = await api.getGuide({ id, token });
    if (response.ok && response.data) {
      this.error = undefined;
      this.selected = response.data;
      this.status = 'FETCHED';
      notify.success('guide-fetched');
    } else {
      this.error = response.originalError;
      this.status = 'ERROR';
      notify.error('error-fetching-guide');
    }
  }

  @action
  async save({ id, title, content }: { id: number | undefined; title: string; content: string }) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const response = await api.saveGuide({ id, content, title, type: 'MARKDOWN', token });
    if (response.ok && response.data) {
      this.error = undefined;
      this.selected = response.data;
      this.status = 'SAVED';
      notify.success('guide-saved');
    } else {
      this.error = response.originalError;
      this.status = 'ERROR';
      notify.error('error-saving-guide');
    }
  }

  @action
  async delete(id: number) {
    const { token } = userStore;
    if (!token || !id) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const response = await api.deleteGuide({ id, token });
    if (response.ok) {
      this.error = undefined;
      this.status = 'DELETED';
      notify.success('guide-deleted');
    } else {
      this.error = response.originalError;
      this.status = 'ERROR';
      notify.error('error-deleting-guide');
    }
  }
}

export const guideStore = new GuideStore();

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Settings as LuxonSettings } from 'luxon';
import fi_datepicker from 'date-fns/locale/fi';
import fi from './locales/fi.json';
import en from './locales/en.json';

export const languages = [
  { name: 'Finnish', value: 'fi' },
  { name: 'English', value: 'en' },
];

LuxonSettings.defaultLocale = 'fi';

const detectionOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator', 'querystring', 'cookie', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: languages[0].value,

    ns: ['translation'],
    defaultNS: 'translation',

    debug: false,

    resources: {
      fi: { translation: fi },
      en: { translation: en },
    },

    detection: detectionOptions,

    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: 'added',
      useSuspense: true,
    },
  });

// set language for Date Picker

if (i18n.language === 'fi') {
  registerLocale('fi', fi_datepicker);
  setDefaultLocale('fi');
} else setDefaultLocale('en-US');

export default i18n;

import { chatEventStore } from '../mobx/chatEventStore';
import notify from '../services/notify';

export const handleNotifications = (pathname: string): void => {
  if (!pathname.includes('chat') && chatEventStore.chatNotificationStatus === 'PENDING') {
    chatEventStore.setNotificationStatus('SENT');
    notify.info('newMessage', {
      position: 'bottom-right',
      autoClose: false,
      className: 'Toastify__toast--success-green',
    });
  } else if (pathname.includes('chat') && chatEventStore.chatNotificationStatus === 'SENT') {
    chatEventStore.setNotificationStatus('EMPTY');
    notify.close();
  } else if (pathname.includes('chat') && chatEventStore.chatNotificationStatus === 'PENDING') {
    chatEventStore.setNotificationStatus('EMPTY');
  }
};

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useLocation, useHistory } from 'react-router-dom';
import { employeeSuggestionStore } from '../../mobx/employeeSuggestionStore';
import { path } from '../../routes/routes';
import { Loader, Layout, Toolbar, Icon, Pagination, SearchListContainer } from '../../components';
import { LinkButton } from '../../components/inputs';
import FilterSelectBar from '../../components/FilterSelectBar';
import Table, { ColumnType, ItemActionProps } from '../../components/Table';
import SearchOrgForm, { SearchOrgFormParams } from '../../components/SearchOrgForm';
import { dateToString } from '../../utils/date';

const PAGE_SIZE = 50;

enum FILTER_OPTIONS {
  open = 'open',
  closed = 'closed',
  all = 'all',
}

enum SORT_OPTIONS {
  createdAt = 'createdAt',
  status = 'status',
}

enum ORDER_OPTIONS {
  asc = 'ASC',
  desc = 'DESC',
}

export const ListEmployeeSuggestions: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { status, items, total, page, pages } = employeeSuggestionStore;
  const order = new URLSearchParams(search).get('order') || ORDER_OPTIONS.desc;
  const sort = new URLSearchParams(search).get('sort') || SORT_OPTIONS.createdAt;
  const skip = Number(new URLSearchParams(search).get('skip')) || 0;
  const take = Number(new URLSearchParams(search).get('take')) || PAGE_SIZE;
  const filter = new URLSearchParams(search).get('filter') || FILTER_OPTIONS.open;
  const groupId = Number(new URLSearchParams(search).get('groupId')) || undefined;
  const employeeId = Number(new URLSearchParams(search).get('employeeId')) || undefined;
  const [criteria, setCriteria] = useState<Partial<GetEmployeeSuggestionsRequest>>({ skip, take, sort, order, filter, groupId, employeeId });

  const tableColumns: ColumnType<EmployeeSuggestion>[] = [
    { key: 'customer', customRender: ({ customer }) => customer?.name || '-', isSortable: true },
    { key: 'profession', isSortable: true },
    { key: 'customerAddress', label: 'address', isSortable: true },
    { key: 'customerCity', label: 'city', isSortable: true },
    { key: 'description', isSortable: true },
    {
      key: 'startDate',
      label: 'dateRange',
      customRender: ({ startDate, endDate }) => (startDate ? `${dateToString(startDate, 'd.MM.')}-${dateToString(endDate || startDate, 'd.MM.yyyy')}` : endDate ? `-${dateToString(endDate, 'd.MM.yyyy')}` : `-`),
      isSortable: true,
    },
    { key: 'status', customRender: ({ status }) => t(status), isSortable: true },
    { key: 'updatedAt', customRender: ({ updatedAt }: any) => dateToString(updatedAt), isSortable: true },
    { key: 'employeeResponses', label: '🟢 ', customRender: ({ employeeResponses }) => employeeResponses?.length.toString() || '-' },
  ];

  const linkEditSuggestion = (employeeSuggestion: EmployeeSuggestion) => `${path('employee-suggestion-edit')}/${employeeSuggestion.id}`;
  const linkCopySuggestion = (employeeSuggestion: EmployeeSuggestion) => `${path('employee-suggestion-edit')}/${employeeSuggestion.id}/copy`;

  const tableRowActions: ItemActionProps<EmployeeSuggestion>[] = [
    {
      label: t('edit'),
      icon: <Icon type="Edit" color="primary" />,
      makeLink: linkEditSuggestion,
    },
    {
      label: t('copy'),
      icon: <Icon type="Edit" color="primary" />,
      makeLink: linkCopySuggestion,
    },
  ];

  // fetch again if criteria changes
  useEffect(() => {
    if (status !== 'BUSY') employeeSuggestionStore.list({ ...criteria });
  }, [criteria]);

  const setQuery = (params: any) => {
    const filtered: any = {};
    Object.keys(params).forEach(key => {
      if (params[key]) filtered[key] = params[key];
    });
    history.replace({ search: new URLSearchParams(filtered).toString() });
  };

  const handlePageChange = (page: number) => {
    const newCriteria = { ...criteria, skip: (page - 1) * PAGE_SIZE };
    setCriteria(newCriteria);
    setQuery(newCriteria);
  };

  const handleSearch = (params: SearchOrgFormParams) => {
    const newCriteria = {
      order,
      sort,
      filter,
      skip: 0,
      take: PAGE_SIZE,
      customerId: (params.unit && params.unit.value) || undefined,
      employeeId: (params.employee && params.employee.value) || undefined,
      groupId: (params.group && params.group.value) || undefined,
      startTime: params.startTime,
      endTime: params.endTime,
    };
    setCriteria(newCriteria);
    setQuery(newCriteria);
  };

  const changeSortOrder = (params: { sort?: string; order?: string }) => {
    const newOrder = params.order === ORDER_OPTIONS.asc ? ORDER_OPTIONS.desc : ORDER_OPTIONS.asc;
    const newSort = params.sort || sort || SORT_OPTIONS.createdAt;
    const newCriteria = { ...criteria, order: newOrder, sort: newSort };
    setCriteria(newCriteria);
    setQuery(newCriteria);
  };

  const setFilter = (filter: any) => {
    const newCriteria = { ...criteria, filter: filter[1] };
    setCriteria(newCriteria);
    setQuery(newCriteria);
  };

  const renderToolbar = () => (
    <>
      <Toolbar>
        <LinkButton href={path('employee-suggestion-edit')} color="primary">
          <Icon type="Add" color="white" />
          {t('create-employee-suggestion')}
        </LinkButton>
        <Pagination total={total} pageCount={pages} page={page} onPageChange={handlePageChange} />
        <FilterSelectBar selected={[filter]} setSelected={setFilter} options={['open', 'closed', 'all']} />
      </Toolbar>
    </>
  );

  return (
    <Layout>
      {status === 'BUSY' && <Loader isFullScreen />}
      <h1>{t('employee-suggestions')}</h1>
      <SearchListContainer>
        <div className="secondary-column">
          <SearchOrgForm onSubmit={handleSearch} exportCheckbox={false} selDepartment={false} selTask={false} />
        </div>
        <div className="main-column">
          {renderToolbar()}
          {items && (
            <>
              <Table<EmployeeSuggestion> onChangeSortOrder={changeSortOrder} order={order} sort={sort} columns={tableColumns} itemActions={tableRowActions} data={items} linkRow={linkEditSuggestion} />
              {renderToolbar()}
            </>
          )}
        </div>
      </SearchListContainer>
    </Layout>
  );
});

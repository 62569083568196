import { DateTime } from 'luxon';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const DATE_FORMAT_TIME = 'yyyy-MM-dd HH:mm';

export const sortDates = (direction: 'asc' | 'desc') => (a: Date, b: Date) => {
  const order = direction === 'desc' ? -1 : 1;
  return a < b ? -1 * order : a > b ? 1 * order : 0;
};

/* format LikeIt Api DAte to given format */
export const likeitDateToString = (date: string, format = DATE_FORMAT_TIME) => {
  const p: string[] = date.split('+');
  const d = new Date(p[0]);
  d.setHours(d.getHours() + Number(p[1].split(':')[0]));
  return DateTime.fromJSDate(d).toFormat(format);
};

/* format Date or date string to string format */
export const dateToString = (date: Date | string, format = DATE_FORMAT) => {
  if (typeof date === 'string') {
    date = new Date(date.split('.')[0] + 'Z');
  }
  return DateTime.fromJSDate(date).toFormat(format);
};

export const startTimeToString = ({ startTime }: { startTime: Date }) => {
  return dateToString(startTime);
};

export const stringToDate =
  (format = DATE_FORMAT) =>
  (dateString: string) => {
    return DateTime.fromFormat(dateString, format).toJSDate();
  };

/**
 * Get all unique dates in all shift sets
 *
 * (only year, month & day are taken into account in comparision)
 */
export const getShiftSetsUniqueDates = (shiftSets: ShiftSetLegacy[]) => {
  const allUniqueDates = new Set(shiftSets.map(({ shifts }) => shifts.map(startTimeToString)).flat());
  return Array.from(allUniqueDates).map(stringToDate()).sort(sortDates('asc'));
};

import React, { FC } from 'react';
import styled from 'styled-components';

import { Colors } from '../../theme/styled';

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${p => p.theme.spacings.lg} 0;
`;

export const ButtonGroupRow = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  margin: ${p => (p.noMargin ? 0 : p.theme.spacings.xl)} 0;

  button {
    :not(:last-child) {
      margin-right: ${p => p.theme.spacings.lg};
    }
  }
  @media ${p => p.theme.breakpoints.mobile} {
    display: block;
  }
`;

const StyledButton = styled.button<{ color: ColorType; isSmall: boolean }>`
  min-width: ${p => (p.isSmall ? undefined : '180px')};
  border-radius: ${p => (p.isSmall ? '5px' : '40px')};
  margin: ${p => p.theme.spacings.sm} 0;
  padding: ${p => p.theme.spacings.md} ${p => p.theme.spacings.lg};

  font-family: ${p => p.theme.fonts.secondary};
  font-size: ${p => (p.isSmall ? p.theme.fonts.size.sm : p.theme.fonts.size.md)};
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;

  transition: all 0.2s ease-in-out;

  svg {
    margin-right: 6px;
  }

  :active {
    box-shadow: none;
    opacity: 0.7;
  }

  &.disabled {
    background-color: transparent !important;
    color: ${p => p.theme.colors.grayDarker} !important;
    border: 1px solid ${p => p.theme.colors.grayLighter} !important;
  }

  &.button-filled {
    border: 1px solid ${p => p.theme.colors[p.color]};
    background-color: ${p => p.theme.colors[p.color]};
    color: ${p => p.theme.colors[p.color === 'white' ? 'black' : 'white']};
    opacity: 1;
    :hover {
      border: 1px solid ${p => p.theme.colors[p.color === 'white' ? 'black' : 'white']};
      /* opacity: 0.8; */
    }
  }

  &.button-outlined {
    background-color: transparent;
    border: 1px solid ${p => p.theme.colors[p.color]};
    color: ${p => p.theme.colors[p.color]};
    opacity: 0.7;
    :hover {
      opacity: 1;
    }
  }

  &.button-link {
    background-color: transparent;
    border: 1px solid transparent;
    color: ${p => p.theme.colors[p.color]};
    opacity: 1;
    :hover {
      text-decoration: underline;
    }
  }

  &.button-mini {
    border: 1px solid ${p => p.theme.colors[p.color]};
    padding: 1px 8px;
    height: 20px;
    font-size: 10px;
    white-space: nowrap;
    margin-right: ${p => p.theme.spacings.lg};
  }

  &.button-square {
    border-radius: 0;
    cursor: pointer;
  }
`;

type ColorType = keyof Colors;
type VariantType = 'filled' | 'outlined' | 'link' | 'mini' | 'square';

interface Props {
  id: string;
  text?: string | JSX.Element;
  icon?: JSX.Element;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  type?: 'button' | 'submit' | 'reset';
  color?: ColorType;
  variant?: VariantType;
  disabled?: boolean;
  isSmall?: boolean;
}

export const Button: FC<Props> = ({ id, text, icon, onClick, type = 'button', color = 'black', variant = 'filled', disabled, isSmall = false }) => {
  return (
    <StyledButton id={id} data-testid={id} type={type} onClick={onClick} className={`button-${variant}${disabled ? ' disabled' : ''}`} disabled={disabled} color={color} isSmall={isSmall}>
      {icon}
      {text}
    </StyledButton>
  );
};

export default Button;

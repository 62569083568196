import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-size: ${p => p.theme.fonts.size.sm};
`;

const LoginFooter = () => {
  return (
    <Container>
      <div>{`2020 \u00A9 WorkPower`}</div>
    </Container>
  );
};

export default LoginFooter;

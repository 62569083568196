import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Loader, Pagination, SearchListContainer, Toolbar } from '../../components';
import DataNotFound from '../../components/DataNotFound';
import Table, { ColumnType } from '../../components/Table';
import { reservedShiftsStore } from '../../mobx/reservedShiftsStore';
import { path } from '../../routes/routes';
import { dateToString } from '../../utils/date';

// timeout for polling reserved shifts
let pollReservedShiftsTimeout: NodeJS.Timeout | null = null;

const ORDER_OPTIONS = {
  asc: 'ASC',
  desc: 'DESC',
};

const Container = styled.div`
  * {
    padding-left: 0 !important;
  }

  * td {
    height: 70px;
    min-width: 120px;
    padding-right: 10px;
  }

  @media ${p => p.theme.breakpoints.mobile} {
    flex-direction: column;
    align-items: center;

    * td {
      height: 100%;
      min-width: 0px;
      padding-right: 0px;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => p.theme.spacings.xl} !important;
  width: fit-content;
  font-size: ${p => p.theme.fonts.size.md};
`;

const POLL_INTERVAL: number = 30000;

const useSearchParams = () => {
  const { search } = useLocation();
  const order = new URLSearchParams(search).get('order') || ORDER_OPTIONS.desc;
  const sort = 'reservedAt';
  const page = Number(new URLSearchParams(search).get('page')) || 1;
  return { order, sort, page };
};

export const ListReservedShifts: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { status, reservedShifts, total, pages, error, lastPollAt } = reservedShiftsStore;
  const searchParams = useSearchParams();
  const [searchOptions, setSearchOptions] = useState<any>(searchParams);
  const { page, sort, order } = searchOptions;

  const canFetch = status !== 'BUSY' && status !== 'ERROR';

  const setQuery = (params: any) => {
    const filtered: any = {};
    Object.keys(params).forEach(key => {
      if (params[key]) filtered[key] = params[key];
    });
    history.replace({ search: new URLSearchParams(filtered).toString() });
  };

  const pollReservedShiftSets = () => {
    if (pollReservedShiftsTimeout) clearTimeout(pollReservedShiftsTimeout);

    reservedShiftsStore.listShiftSetsByReservation(searchOptions);
    setQuery(searchOptions);

    pollReservedShiftsTimeout = setTimeout(pollReservedShiftSets, POLL_INTERVAL);
    return () => {
      if (pollReservedShiftsTimeout) clearTimeout(pollReservedShiftsTimeout);
    };
  };

  useEffect(() => {
    if (canFetch) return pollReservedShiftSets();
  }, []);

  useEffect(() => {
    if (canFetch && page === 1) {
      return pollReservedShiftSets();
    } else if (page > 1 && pollReservedShiftsTimeout) {
      clearTimeout(pollReservedShiftsTimeout);
      reservedShiftsStore.listShiftSetsByReservation(searchOptions);
      setQuery(searchOptions);
    }
  }, [page]);

  const handlePageChange = (page: number) => {
    setSearchOptions({ ...searchOptions, page });
  };

  const renderReservedAt = ({ reservedAt }: IReservedShift) => <>{dateToString(reservedAt, 'd.M.yyyy HH:mm')}</>;

  const renderShifts = ({ shifts }: IReservedShift) => (
    <>
      {shifts.map(({ startTime, endTime }, index) => (
        <div key={index}>
          {dateToString(startTime, 'd.M.yyyy')}
          <br />
          {dateToString(startTime, 'HH:mm')}-{dateToString(endTime, 'HH:mm')}
          <br />
        </div>
      ))}
    </>
  );

  const renderReservedTo = ({ reservedTo }: IReservedShift) => {
    return <>{reservedTo ? `${reservedTo.name}` : '-'}</>;
  };

  const renderTask = ({ task }: IReservedShift) => {
    return <>{task?.name || '-'}</>;
  };

  const renderOrganizations = ({ organizationFullName }: IReservedShift) => {
    return <>{organizationFullName?.replaceAll(';', ' / ')}</>;
  };

  const renderCreatedBy = ({ createdBy }: IReservedShift) => {
    return <>{createdBy?.name}</>;
  };

  const renderReservedBy = ({ reservedBy }: IReservedShift) => {
    return <>{reservedBy ? `${reservedBy.name}` : '-'}</>;
  };

  const tableColumns: ColumnType<IReservedShift>[] = [
    { key: 'organizationFullName', customRender: renderOrganizations },
    { key: 'task', customRender: renderTask },
    { key: 'shifts', customRender: renderShifts },
    { key: 'reservedAt', customRender: renderReservedAt },
    { key: 'reservedTo', customRender: renderReservedTo },
    { key: 'createdBy', customRender: renderCreatedBy },
    { key: 'reservedBy', customRender: renderReservedBy },
  ];

  const isDataNotFound = ['FETCHED', 'ERROR'].includes(status) && (!reservedShifts || reservedShifts.length === 0);

  const changeSortOrder = (params: { sort?: string; order?: string }) => {
    const newParams: any = {};
    if (params.sort) {
      newParams.sort = params.sort;
    }
    if (params.order) {
      newParams.order = params.order === ORDER_OPTIONS.desc ? ORDER_OPTIONS.asc : ORDER_OPTIONS.desc;
    }
    if (newParams.sort || newParams.order) {
      setSearchOptions({ ...searchOptions, ...newParams });
    }
  };

  const handleEditShift = (shift: IReservedShift) => (shift.id ? `${path('editShiftSet')}/${shift.id}/${shift.id}` : 'undefined');

  return (
    <Container>
      <InfoContainer>
        {<div>{lastPollAt && `${t('page updated at')}: ${dateToString(lastPollAt, 'HH:mm:ss')}`}</div>}
        {status === 'BUSY' && <Loader size="xs" />}
      </InfoContainer>
      <SearchListContainer>
        <div className="secondary-column"></div>
        <div className="main-column">
          {status === 'BUSY' && reservedShifts === undefined && <Loader isFullScreen />}
          {!!reservedShifts && reservedShifts.length > 0 && (
            <>
              <Table<IReservedShift>
                onChangeSortOrder={changeSortOrder}
                order={order}
                sort={sort}
                data={reservedShifts}
                columns={tableColumns}
                linkRow={handleEditShift}
              />
              <Toolbar>
                <Pagination total={total} pageCount={pages} page={page} onPageChange={handlePageChange} />
              </Toolbar>
            </>
          )}
          {isDataNotFound && <DataNotFound error={error} tryAgain={() => reservedShiftsStore.listShiftSetsByReservation(searchOptions)} />}
        </div>
      </SearchListContainer>
    </Container>
  );
});

export default ListReservedShifts;

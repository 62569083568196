import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EmployeeDataBaseProps } from '.';
import { Icon } from '../../components/Icon';
import { Button } from '../../components/inputs';
import { Todo } from '../../components/Todo';
import List from '../../components/List';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

interface Props extends EmployeeDataBaseProps {}

export const PersonalData: FC<Props> = ({ employee }) => {
  const { t } = useTranslation();

  const listKeys: (keyof Person)[] = ['firstName', 'lastName', 'birthday', 'address', 'zipCode', 'city', 'phone', 'email'];

  const handleEditEmployeeData = () => {
    console.log('Todo: Edit employee personal data!');
  };

  return (
    <Container>
      <List<Person> object={employee} objectKeys={listKeys} />

      <div>
        <Button id="employee-personal-data-edit-button" text={t('edit')} icon={<Icon type="Edit" color="primary" />} onClick={handleEditEmployeeData} variant="outlined" color="primary" isSmall />
        <Todo noMargin />
      </div>
    </Container>
  );
};

export default PersonalData;

import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { Route } from '../routes/routes';
import { Layout } from './Layout';

const NavContainer = styled.div`
  margin-bottom: ${p => p.theme.spacings.md};
  .tab-item {
    margin-right: ${p => p.theme.spacings.md};
  }
  .active {
    color: black;
    font-weight: bold;
  }
`;

export const SearchFields = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    display: flex;
    flex-direction: column;
    padding-right: 1em;
  }
`;

const TabNavigation: FC<{ routes: Route[] }> = ({ routes }) => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <NavContainer>
      {routes.map((r, i) => (
        <NavLink key={`${r.path}`} to={r.path} isActive={() => location.pathname === r.path} className="tab-item">
          <span>{r.title && t(r.title)}</span>
        </NavLink>
      ))}
    </NavContainer>
  );
};

export const View: FC<{ children: ReactNode; routes?: Route[]; defaultTitle?: string; noPadding?: boolean }> = ({ children, routes, defaultTitle, noPadding }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { title = defaultTitle } = routes?.find(r => location.pathname === r.path) || {};
  return (
    <Layout noPadding={noPadding}>
      {routes && <TabNavigation routes={routes} />}
      {title && <h1>{t(title)}</h1>}
      <>{children}</>
    </Layout>
  );
};

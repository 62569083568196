import React, { ChangeEvent, FC, useState } from 'react';
import { observer } from 'mobx-react';
import { userStore } from '../../mobx/userStore';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../components/Logo';
import PasscodeForm from './SendPasscodeForm';
import LoginFooter from './LoginFooter';
import LoginForm from './LoginForm';
import LoginInfo from './LoginInfo';
import bgImage from '../../assets/login_bg.jpg';
import { path } from '../../routes/routes';
import { Loader } from '../../components/Loader';
import Format, { PHONE_PREFIX } from '../../utils/format';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee url(${bgImage}) no-repeat;
  background-position: center;
  background-size: cover;
`;

const LoginArea = styled.div`
  background-color: ${p => p.theme.colors.white};
  padding: 1rem;
  border-radius: 0 0 ${p => p.theme.borderRadius[0]} ${p => p.theme.borderRadius[0]};
  ${p => p.theme.shadows[0]};
  border: solid 1px #eee;
`;

const FormArea = styled.div`
  margin: ${p => p.theme.spacings.md} 0;
`;

export const Login: FC = observer(() => {
  const { status } = userStore;
  const [phoneNumber, setPhoneNumber] = useState<string>(PHONE_PREFIX);
  const [email, setEmail] = useState<string>('');
  const [passcode, setPasscode] = useState<string>('');
  const handleInputChange = (type: 'phoneNumber' | 'email' | 'passcode') => (event: ChangeEvent<any>) => {
    const { value } = event.currentTarget;
    switch (type) {
      case 'phoneNumber':
        return setPhoneNumber(Format.phoneNumber(value));
      case 'email':
        return setEmail(value);
      case 'passcode':
        return setPasscode(value);
      default:
        return;
    }
  };

  const handleSendPasscode = () => {
    userStore.sendPasscode(email, phoneNumber);
  };

  const handleLogin = () => {
    userStore.login(passcode);
  };

  const handleCancel = () => {
    setPhoneNumber(PHONE_PREFIX);
    setEmail('');
    setPasscode('');
    userStore.cancelLogin();
  };

  if (status === 'LOGGED_IN') return <Redirect to={path('home')} />;

  return (
    <Container>
      <LoginArea>
        <Logo fillA="#939598" fillB="#ee3224" />
        {status === 'BUSY' && <Loader isFullScreen />}
        {status !== 'PASSCODE_SENT' && <LoginInfo />}
        <FormArea>
          {(status === 'NOT_LOGGED_IN' || status === 'ERROR') && <PasscodeForm phoneNumber={phoneNumber} onPhoneNumberChange={handleInputChange('phoneNumber')} email={email} onEmailChange={handleInputChange('email')} onSubmit={handleSendPasscode} />}
          {(status === 'PASSCODE_SENT' || status === 'PASS_CODE_FAILED') && <LoginForm passcode={passcode} onPasscodeChange={handleInputChange('passcode')} onSubmit={handleLogin} onCancel={handleCancel} />}
        </FormArea>
        <LoginFooter />
      </LoginArea>
    </Container>
  );
});

export default Login;

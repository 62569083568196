import { DateTime, Interval } from 'luxon';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../components/Icon';
import { Button, DatePicker } from '../../components/inputs';
import { Loader } from '../../components/Loader';
import Table, { ColumnType, ItemActionProps } from '../../components/Table';
import { View } from '../../components/View';
import { adminStore } from '../../mobx/adminStore';
import { adminRoutes } from '../../routes/routes';

export const CronStatus: FC = observer(() => {
  const { t } = useTranslation();
  const { status, cronStatus } = adminStore;
  const [newDate, setNewDate] = useState<Date>();
  const [importStartDate, setImportStartDate] = useState<CronStatus>();
  const cronStatusTable: ColumnType<CronStatus>[] = [
    { key: 'dataname' },
    { key: 'status' },
    {
      key: 'lastRunStartedAt',
      customRender: ({ lastRunStartedAt }) => DateTime.fromISO(lastRunStartedAt).toFormat('yyyy-MM-dd HH:mm:ss'),
    },
    {
      key: 'lastRunFinishedAt',
      customRender: ({ lastRunFinishedAt }) => DateTime.fromISO(lastRunFinishedAt).toFormat('yyyy-MM-dd HH:mm:ss'),
    },
    {
      key: 'id',
      label: 'took',
      customRender: ({ lastRunStartedAt, lastRunFinishedAt }) => Interval.fromDateTimes(DateTime.fromISO(lastRunStartedAt), DateTime.fromISO(lastRunFinishedAt)).length('seconds') + 's',
    },
    {
      key: 'itemsProcessed',
    },
  ];
  const cronTableRowActions: ItemActionProps<CronStatus>[] = [
    {
      label: t('set-import-start-date'),
      icon: <Icon type="Edit" color="primary" />,
      onClick: r => setImportStartDate(r),
    },
  ];

  useEffect(() => {
    adminStore.getCronStatus();
  }, []);

  return (
    <View routes={adminRoutes}>
      {status === 'BUSY' && <Loader isFullScreen />}
      {cronStatus && <Table<CronStatus> data={cronStatus} columns={cronStatusTable} itemActions={cronTableRowActions} />}
      <Button id="start-nightly-now-button" onClick={() => adminStore.nightlyNow()} disabled={status === 'BUSY'} text={t('start-nightly-now')} />
      {importStartDate && (
        <Modal show={true} onHide={() => setImportStartDate(undefined)}>
          <Modal.Body>
            <div>{t('set-import-start-date-help')}</div>
            <DatePicker id={'set-lastRunStartedAt'} selectsStart isClearable startDate={newDate || new Date(importStartDate.lastRunStartedAt)} onChange={e => (e ? setNewDate(e as Date) : null)} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="view-message--close-modal"
              onClick={() => {
                if (newDate) {
                  adminStore.updateCronStatus(importStartDate, newDate);
                }
                setNewDate(undefined);
                setImportStartDate(undefined);
              }}
              text={t('close')}
              color="secondary"
              disabled={false}
            />
          </Modal.Footer>
        </Modal>
      )}
    </View>
  );
});

import { observer } from 'mobx-react';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ErrorLine = styled.div`
  outline: solid 1px red;
  position: relative;
`;

const ErrorDescription = styled.div`
  color: red;
  position: absolute;
  background-color: white;
  top: -1rem;
  left: 1rem;
`;

export const ShowErrors: FC<PropsWithChildren<{ property: string; errorDetails: any }>> = ({ property, errorDetails, children }) => {
  const { t } = useTranslation();

  const parameterError = Array.isArray(errorDetails?.message) ? errorDetails?.message?.find((error: any) => error.indexOf(property) === 0) : undefined;

  if (parameterError) {
    return (
      <ErrorLine>
        <ErrorDescription>{t('invalid value')}</ErrorDescription>
        {children}
      </ErrorLine>
    );
  }
  return <>{children}</>;
};

import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { messageStore } from '../../mobx/messageStore';
import styled from 'styled-components';
import { Layout, Loader } from '../../components';
import { useTranslation } from 'react-i18next';
import MessageForm from './MessageForm';
import { Modal } from 'react-bootstrap';
import { MessageLogsForm } from './MessageLogsForm';
import { NavLink } from 'react-router-dom';
import { messageRoutes } from '../../routes/routes';
import { useLocation } from 'react-router-dom';
import { MessageLogResult } from './MessageLogResult';
import { Pagination } from '../../components/Pagination';
import { Button } from '../../components/inputs';
import { dateToString } from '../../utils/date';

const NavContainer = styled.div`
  margin-bottom: ${p => p.theme.spacings.md};
  .tab-item {
    margin-right: ${p => p.theme.spacings.md};
  }
  .active {
    color: black;
    font-weight: bold;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MessagingPagesNavigation: FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <NavContainer>
      {messageRoutes.map((r, i) => (
        <NavLink key={`${r.path}`} to={r.path} isActive={() => location.pathname === r.path} className="tab-item">
          <span>{r.title && t(r.title)}</span>
        </NavLink>
      ))}
    </NavContainer>
  );
};

export const Messages: FC = observer(() => {
  const { t } = useTranslation();
  const { status } = messageStore;
  const handleSendMessage = (message: Message) => {
    messageStore.sendMessage(message);
  };
  return (
    <Layout>
      {status === 'BUSY' && <Loader isFullScreen />}
      <h1 data-testid="messages-page-title">{t(`route.sendMessages`)}</h1>
      <MessagingPagesNavigation />
      <MessageForm onSubmit={handleSendMessage} />
    </Layout>
  );
});

export default Messages;

export const MessageLogs: FC = observer(() => {
  const { t } = useTranslation();
  const [terms, setTerms] = useState<SearchTerms>({
    skip: 0,
    take: 20,
    search: '',
    source: 'MESSAGETOGROUP',
  });
  const [update, setUpdate] = useState<boolean>(true);
  const [selectedMessage, setSelectedMessage] = useState<MessageLog>();
  const { status, results, total, pages, page, twilioStatus } = messageStore;

  const handleSearch = (newTerms: Partial<SearchTerms>) => {
    setTerms({ ...terms, ...newTerms });
    setUpdate(true);
  };

  const handlePageChange = (page: number) => {
    setTerms({ ...terms, skip: terms.take * (page - 1) });
    setUpdate(true);
  };

  const selectMessage = (message: MessageLog) => {
    if (message.twilioSid) {
      messageStore.getTwilioMessageStatus(message.twilioSid);
    }
    messageStore.clearTwilioMessageStatus();
    setSelectedMessage(message);
  };

  useEffect(() => {
    if (status !== 'BUSY' && update) {
      messageStore.messageLog({ ...terms });
      setUpdate(false);
    }
  }, [terms, update]);

  return (
    <Layout>
      {status === 'BUSY' && <Loader isFullScreen />}
      <h1 data-testid="messages-page-title">{t(`route.messageLogs`)}</h1>
      <MessagingPagesNavigation />
      <Container>
        <MessageLogsForm onSubmit={handleSearch} />
        <MessageLogResult total={total} results={results} onClick={item => selectMessage(item)} />
      </Container>
      <Pagination pageCount={pages} page={page} onPageChange={handlePageChange} />
      {selectedMessage && <ViewMessage message={selectedMessage} twilioStatus={twilioStatus} onHide={() => setSelectedMessage(undefined)} />}
    </Layout>
  );
});

const ViewMessage: FC<{ message: MessageLog; twilioStatus?: any; onHide: Function }> = ({ message, twilioStatus, onHide }) => {
  const { t } = useTranslation();
  return (
    <Modal show={true} onHide={() => onHide()}>
      <Modal.Body>
        <div>
          {message.type}:
          {message.user && (
            <>
              {message.user.firstName} {message.user.lastName} ({message.user.email})
            </>
          )}
          {message.personGroup && <>{message.personGroup.name}</>}
        </div>
        <hr />
        <div>{t('content')}:</div>
        <h3>{message.title}</h3>
        <div>{message.content}</div>
        <hr />
        <div>
          {t('createdAt')}:{dateToString(message.createdAt)}
        </div>
        <div>
          {t('sent')}:{message.sent ? dateToString(message.sent) : message.error}
        </div>
        {twilioStatus && (
          <div>
            <hr />
            <b>{twilioStatus.status}</b>
            <br />
            {twilioStatus.body}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button id="view-message--close-modal" onClick={() => onHide()} text={t('close')} color="secondary" disabled={false} />
      </Modal.Footer>
    </Modal>
  );
};

import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DatePicker, Select } from '../../../../components/inputs';
import { shiftStore } from '../../../../mobx/shiftStore';
import { getShiftKeyByStartTime, SHIFT_TYPE_OPTIONS, updateDates } from './utils';


const ShiftContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  > div {
    margin-right: 50px !important;
  }
  @media ${p => p.theme.breakpoints.mobile} {
    display: block;
  }
`;


interface ShiftProps {
  noLabels: boolean;
  onChange: (shift: any) => void;
  shift: IShiftData;
  shiftSetIndex: number;
}

const ShiftEditing: FC<ShiftProps> = ({ noLabels, onChange, shift, shiftSetIndex }) => {
  const { t } = useTranslation();
  const { startTime, endTime, shiftKey } = shift;
  const selectedShiftKey = SHIFT_TYPE_OPTIONS.find(o => o.value === shiftKey);

  const [typeOptions, setTypeOptions] = useState<SelectOptionWithProps<string>[]>(SHIFT_TYPE_OPTIONS);

  const [moveToShiftSetOptions, setMoveToshiftSetOptions] = useState<SelectOption<number>[]>([{ label: '', value: 0 }]);

  const idPrefix = `shift-${shiftSetIndex}-${shift.index}`;

  const shiftSetsData = shiftStore.unsavedShiftSetData?.shiftSets;

  const { shiftTimeDefaults } = shiftStore;

  const hasOnlyOneShift = shiftSetsData && shiftSetsData[shiftSetIndex].shifts?.length === 1;

  useEffect(() => {
    if (shiftTimeDefaults) {
      const optionsWithSavedDefaults = SHIFT_TYPE_OPTIONS.map(option => {
        if (shiftTimeDefaults && shiftTimeDefaults.value && shiftTimeDefaults.value.options) {
          const savedOption = shiftTimeDefaults.value.options.find(o => o.value === option.value);
          if (!savedOption) {
            return option;
          }
          return {
            ...savedOption,
            label: `${option.label} ${savedOption.startClock} - ${savedOption.endClock} (${shiftTimeDefaults.value.target})`,
          };
        }
        return option;
      });
      setTypeOptions(optionsWithSavedDefaults);
    } else {
      setTypeOptions(SHIFT_TYPE_OPTIONS);
    }
  }, [shiftTimeDefaults]);

  useEffect(() => {}, [typeOptions]);

  useEffect(() => {
    if (!shiftSetsData) return;

    const options = shiftSetsData?.length > 0 ? shiftSetsData?.map((_, index) => ({ label: `${t('shiftSet')} ${index + 1}`, value: index as number })).filter(e => e.value !== shiftSetIndex) : [{ label: 'no options', value: 0 }];

    setMoveToshiftSetOptions(options);
  }, [shiftSetsData]);

  const handleDateChange = (type: 'startDate' | 'startTime' | 'endTime') => (newDate: Date | [Date, Date] | null) => {
    const targetDate = newDate && Array.isArray(newDate) ? newDate[0] : newDate;
    const [newStartTime, newEndTime] = updateDates(type, targetDate, startTime, endTime);
    //Changed only little, then it's likely and user is adjusting time
    const adjustment: boolean = (newStartTime && Math.abs(startTime.getTime() - newStartTime.getTime()) / (1000 * 60 * 60) < 4) || false;
    const shiftKey = adjustment ? shift.shiftKey : getShiftKeyByStartTime(newStartTime)?.value;
    onChange({ ...shift, shiftKey, startTime: newStartTime, endTime: newEndTime });
  };

  const handleShiftKeyChange = (shiftKeyOption: SelectOptionWithProps<string>) => {
    if (shiftKeyOption && shiftKeyOption.value) {
      const update = { ...shift, shiftKey: shiftKeyOption.value };
      if (shiftKeyOption.startClock) {
        update.startTime = DateTime.fromJSDate(startTime)
          .set({
            hour: shiftKeyOption.startClock.split(':')[0],
            minute: shiftKeyOption.startClock.split(':')[1],
          })
          .toJSDate();
      }

      if (shiftKeyOption.endClock) {
        const [hour, minute] = shiftKeyOption.endClock.split(':');
        // end hour < start hour, then enddate next day
        if (DateTime.fromJSDate(startTime).set({ hour, minute }).toJSDate() < update.startTime) {
          update.endTime = DateTime.fromJSDate(update.startTime).set({ hour, minute }).plus({ day: 1 }).toJSDate();
        } else {
          update.endTime = DateTime.fromJSDate(update.startTime).set({ hour, minute }).toJSDate();
        }
      }
      onChange(update);
    }
  };

  const handleMoveToNewShiftSet = (option: SelectOption<number>) => {
    if (!shiftSetsData) return;
    // remove target shfit from origin shiftset
    const removedShiftFromOriginShiftSet = shiftSetsData[shiftSetIndex].shifts?.filter(e => {
      return e !== shift;
    });
    // add target shift to destination shiftset
    const addShiftToDestinationShiftSet = shiftSetsData[option.value].shifts?.concat(shift);
    // replace origin shiftset shifts array
    const updatedOriginShiftSet = { ...shiftSetsData[shiftSetIndex], shifts: removedShiftFromOriginShiftSet };
    // replace destination shiftset shifts array
    const updatedDestinationShiftSet = { ...shiftSetsData[option.value], shifts: addShiftToDestinationShiftSet };
    // remove origin and destination shiftsets from shiftset collection
    const shiftSetsWithoutOriginAndDestination = shiftSetsData.filter((e, index) => {
      return index !== shiftSetIndex && index !== option.value;
    });
    // add updated origin shiftset and updated destination shiftset to shiftsets
    const updatedShiftSets = [...shiftSetsWithoutOriginAndDestination, updatedOriginShiftSet, updatedDestinationShiftSet].sort((a, b) => (a.index || 0) - (b.index || 0));
    shiftStore.change({ shiftSets: updatedShiftSets });
  };

  return (
      <ShiftContainer key={idPrefix + '_shift'}>
        <div className="shift-input">
          <DatePicker id={`${idPrefix}-date-input`} label={noLabels ? undefined : t('date')} date={startTime} onChange={handleDateChange('startDate')} minDate={new Date()} />
        </div>
        <div className="shift-input">
          <DatePicker id={`${idPrefix}-start-time-input`} label={noLabels ? undefined : t('starts')} date={startTime} onChange={handleDateChange('startTime')} showTimeSelectOnly minDate={new Date()} />
        </div>
        <div className="shift-input">
          <DatePicker id={`${idPrefix}-end-time-input`} label={noLabels ? undefined : t('ends')} date={endTime} onChange={handleDateChange('endTime')} showTimeSelectOnly minDate={new Date()} />
        </div>
        <div className="shift-input">
          <Select<string> id={`${idPrefix}-type-select`} label={noLabels ? undefined : t('shift')} options={typeOptions} selected={selectedShiftKey} onChange={handleShiftKeyChange} />
        </div>
        <div className="shift-input">
          <Select<number>
            id={`${idPrefix}-move-shift-to-another-shiftSet`}
            options={moveToShiftSetOptions}
            label={noLabels ? undefined : t('move shift')}
            selected={moveToShiftSetOptions[0]}
            onChange={value => handleMoveToNewShiftSet(value)}
            isClearable={false}
            isDisabled={shiftSetsData?.length === 1 || hasOnlyOneShift}
          />
        </div>
      </ShiftContainer>
  );
};

export default ShiftEditing;

import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  fillA?: string;
  fillB?: string;
}

const Logo: React.FC<Props> = ({ fillA = '#c6c8c9', fillB = '#fff', ...props }) => {
  return (
    <svg width="213.651" height="28.331" viewBox="0 0 213.651 28.331">
      <path
        fill={fillA}
        d="M0,27.8,5.17,0H7.5c1.8,0,2.157.768,1.891,2.3-.266,1.566-2.659,14.18-2.659,14.18s3.959-4.313,5.436-5.908c1.507-1.625,2.718-2.9,4.431-2.9h3.9l-9.01,9.779L17.666,27.8H12.378L6.5,17.785,4.668,27.8Z"
        transform="translate(64.491)"
      />
      <path
        fill={fillA}
        d="M0,20.562,3.722.473H6.854c.473,0,.886.207.739,1.064C7.5,2.127,7.267,3.22,7.267,3.22A7.536,7.536,0,0,1,10.074.768,9.892,9.892,0,0,1,14.18,0h.148l-.857,4.7A8.2,8.2,0,0,0,8.6,6c-.975.827-1.654,2.334-2.068,4.52l-1.891,10.1H0Z"
        transform="translate(51.374 7.238)"
      />
      <path
        fill={fillA}
        d="M0,11.433A12.174,12.174,0,0,1,.975,6.558,11.67,11.67,0,0,1,3.781,2.6,9.618,9.618,0,0,1,6.913.65,10.323,10.323,0,0,1,10.665,0a8.775,8.775,0,0,1,6.677,2.718,9.546,9.546,0,0,1,2.6,6.942,12.766,12.766,0,0,1-.8,4.52,11.118,11.118,0,0,1-2.334,3.781A9.589,9.589,0,0,1,13.442,20.3a10.951,10.951,0,0,1-4.2.827A8.862,8.862,0,0,1,2.6,18.4,9.664,9.664,0,0,1,0,11.433Zm10.369-7.09A5.429,5.429,0,0,0,6.115,6.352,7.577,7.577,0,0,0,4.4,11.4,5.473,5.473,0,0,0,5.79,15.244a4.7,4.7,0,0,0,3.6,1.507,5.454,5.454,0,0,0,4.313-2.009A7.174,7.174,0,0,0,15.48,9.808a5.461,5.461,0,0,0-1.418-3.959A4.767,4.767,0,0,0,10.369,4.343Z"
        transform="translate(30.813 7.208)"
      />
      <path
        fill={fillA}
        d="M0,0H2.127C4.254,0,4.993.3,4.993,2.925c0,1.448-.089,18.966-.089,18.966h.3S14.033,2.1,14.417,1.241c.266-.62.827-.561,1.507-.591H18.08c.886,0,1.3,0,1.3.8,0,.827.473,20.621.473,20.621l.236.03s7.238-18.2,7.8-19.38C28.981.414,29.483.03,31.433.03h2.747l-12.26,27.8H15.864L15.6,7.238h-.148L6.795,27.829H.65Z"
        transform="translate(0)"
      />
      <path
        fill={fillB}
        d="M0,.473H5.643c1.064,0,1.6.561,1.6,1.713V3.752A8.372,8.372,0,0,1,9.395.8,4.58,4.58,0,0,1,12.142,0a7.972,7.972,0,0,1,3.722,1.064L13.471,6.558A5.925,5.925,0,0,0,11.315,6,2.624,2.624,0,0,0,8.981,7.238C8.183,8.42,7.8,10.606,7.8,13.826v6.736H.059V.473"
        transform="translate(197.787 7.179)"
      />
      <path
        fill={fillB}
        d="M15.037,8.745c.236,0,.414-.148.355-.473a4.94,4.94,0,0,0-1.152-2.747,3.475,3.475,0,0,0-2.57-.975A3.572,3.572,0,0,0,8.715,6a5.263,5.263,0,0,0-.886,2.718C7.829,8.745,14.771,8.745,15.037,8.745Zm5.7,3.693H7.829a5.123,5.123,0,0,0,1,2.777,3.6,3.6,0,0,0,2.925,1.3,4.275,4.275,0,0,0,2.157-.561c.325-.177.591-.443,1-.709a3.792,3.792,0,0,1,2.393-.561H23.22a2.527,2.527,0,0,0-.384.679,10.253,10.253,0,0,1-4.2,4.343A15.192,15.192,0,0,1,11.581,21a14.72,14.72,0,0,1-6.293-1.123,8.952,8.952,0,0,1-3.781-3.575A10.735,10.735,0,0,1,0,10.547,10.1,10.1,0,0,1,3.013,2.925C5.022.975,7.8,0,11.374,0A15.078,15.078,0,0,1,18.2,1.3a8.729,8.729,0,0,1,3.811,3.781,13.809,13.809,0,0,1,1.329,6.411C23.309,12.319,22.334,12.437,20.739,12.437Z"
        transform="translate(170.903 7.208)"
      />
      <path
        fill={fillB}
        d="M0,0H4.461C6.027,0,7.386-.146,7.947,2.041c.325,1.3,2.659,10.281,2.659,10.281h.207s3.4-10.606,3.575-11.167A1.538,1.538,0,0,1,15.776,0h4.756a1.3,1.3,0,0,1,1.477,1.034c.177.561,3.811,11.256,3.811,11.256h.236s2.334-8.715,2.659-9.985c.62-2.422,1.92-2.3,3.279-2.3H36.6L29.188,20.121H22.334l-3.9-12.733H18.05L14.3,20.091H7.386Z"
        transform="translate(133.384 7.649)"
      />
      <path
        fill={fillB}
        d="M6.145,10.547A4.838,4.838,0,0,0,7.5,14.358,4.742,4.742,0,0,0,10.872,15.6a4.8,4.8,0,0,0,3.368-1.211,5.134,5.134,0,0,0,1.329-3.929,4.879,4.879,0,0,0-1.329-3.752,4.625,4.625,0,0,0-3.309-1.211A4.962,4.962,0,0,0,7.474,6.736,5.066,5.066,0,0,0,6.145,10.547ZM0,10.576A10.381,10.381,0,0,1,2.925,2.984,10.579,10.579,0,0,1,10.813,0c3.781,0,6.647,1.182,8.567,3.516a10.522,10.522,0,0,1,2.334,6.972,10.431,10.431,0,0,1-2.9,7.592c-1.92,1.979-4.609,2.954-8.006,2.954a10.922,10.922,0,0,1-7.386-2.452A10.28,10.28,0,0,1,0,10.576Z"
        transform="translate(111.228 7.208)"
      />
      <path
        fill={fillB}
        d="M8.6,6.174v5.672h2.1a5.518,5.518,0,0,0,3.486-.857,2.721,2.721,0,0,0,1-2.216A3.006,3.006,0,0,0,14.3,6.558c-.561-.62-1.654-.916-3.279-.916H9.188A.633.633,0,0,0,8.6,6.174ZM0,0H14.269c3.1,0,5.436.739,6.972,2.216a8.336,8.336,0,0,1,2.334,6.293A8.528,8.528,0,0,1,21.034,15.1c-1.684,1.566-4.254,2.363-7.711,2.363H9.867a1.229,1.229,0,0,0-1.241,1.034V27.77H0Z"
        transform="translate(86.5)"
      />
    </svg>
  );
};

export default Logo;

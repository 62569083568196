import React, { useState } from 'react';
import { adminRoutes } from '../../routes/routes';
import { View } from '../../components/View';
import { Button, Input } from '../../components/inputs';
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../components';

const Debug = () => {
  const [url, setUrl] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const { t } = useTranslation();

  async function handleSearch() {
    setLoading(true);
    const res = await api.debugRequest(+userId, { url });

    setResult(JSON.stringify(res.data, null, 2));
    setLoading(false);
  }

  async function getJwt() {
    setLoading(true);
    const res = await api.getDebugJwt(+userId);

    setResult(JSON.stringify(res.data, null, 2));
    setLoading(false);
  }

  return (
    <View routes={adminRoutes}>
      <div style={{ maxWidth: 700 }}>
        <p>{t('admin-debug-intro')}</p>
        <p>{t('admin-debug-example')}</p>
        <p>{t('admin-debug-userId-tip')}</p>

        <Input id="search-users-by-email" label={'Url ' + t('address')} value={url} onChange={e => setUrl(e.target.value)} />
        <Input type="number" id="user-id" label="Käyttäjän id (userId)*" value={userId} onChange={e => setUserId(e.target.value)} />

        <Button id="search-users-button" text={t('search')} disabled={!url || !userId || loading} onClick={handleSearch} color="primary" />
        <span style={{ marginLeft: 8 }}>
          <Button id="get-jwt-from-user-button" text={`${t('search')} JWT`} disabled={!userId || loading} onClick={getJwt} color="secondary" />
        </span>

        {loading && <Loader />}
      </div>
      {result && (
        <div style={{ height: '100%', marginBottom: 120 }}>
          <Input id="vastaus" value={result} label="Api vastaus" placeholder="" asTextarea />
        </div>
      )}
    </View>
  );
};

export { Debug };

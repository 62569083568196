import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { customerSuggestionStore } from '../../mobx/customerSuggestionStore';
import { Loader, Layout } from '../../components';
import { Input, Button, ButtonGroupRow } from '../../components/inputs';

export const ViewOrder: FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Layout>
      <h1 data-testid="view-offer-page-title">{t('view-offer')}</h1>
    </Layout>
  );
});

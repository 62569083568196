import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { api } from '../services/api';
import notify, { notificationOptions } from '../services/notify';
import { fromApi } from '../transforms/shifts';
import { DEFAULT_DATE } from '../views/Shifts/components/TimePeriods';
import { prefsStore } from './prefsStore';
import { userStore } from './userStore';

export type ShiftSetPreferenceType = 'GENERAL' | 'NOTIFICATION_SETTINGS' | 'SHIFT_TIME_SETTIGS';

const PAGE_SIZE = 50;

interface IPrefsItem<T> {
  id?: number;
  key: string;
  value: T;
}

export interface ITimeOption {
  value: string;
  label: string;
  startClock: string;
  endClock: string;
}

interface IDefaultTimes {
  target: string;
  options: ITimeOption[];
}

class ShiftStore {
  status: 'NEW' | 'EMPTY' | 'IDLE' | 'BUSY' | 'FETCHED' | 'CHANGED' | 'SAVED' | 'SAVED_NEW' | 'DELETED' | 'RESENT' | 'CHANGED ORGANIZATION' | 'ERROR' = 'EMPTY';
  shifts?: Shift[];
  shiftsData?: Partial<ShiftsData>;
  shiftSet?: ShiftSet;
  total: number = 0;
  pages: number = 1;

  error?: any;
  errorDetails?: ICreateOrUpdateShiftSetsData | ICreateOrUpdateShiftSetsError | undefined;

  unsavedShiftSetData?: Partial<ICreateOrUpdateShiftSetsData>;

  shiftTimeDefaults?: IPrefsItem<IDefaultTimes>;
  fieldDefaults?: IPrefsItem<any>[];

  constructor() {
    makeAutoObservable(this);
  }
  @action
  async getShifts({ page, exportToFile, ...rest }: GetShiftsParams): Promise<any> {
    const format = 'yyyy-MM-dd HH:mm:ss';
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const take = PAGE_SIZE;
    const skip = (page - 1) * take;
    const st = rest.startTime;
    const et = rest.endTime;
    const startTime = st ? DateTime.fromJSDate(st).toFormat(format).replace(' ', 'T') : undefined;
    const endTime = et ? DateTime.fromJSDate(et).toFormat(format).replace(' ', 'T') : undefined;
    const result = await api.getShifts({ token, skip, take, ...rest, startTime, endTime });
    if (result.ok && result.data) {
      this.error = undefined;
      this.shifts = result.data.results.map(fromApi);
      this.total = result.data.total;
      this.pages = Math.ceil(this.total / PAGE_SIZE);
      if (exportToFile) {
        const response: any = await api.getShifts({
          token,
          skip,
          take,
          ...rest,
          startTime,
          endTime,
          exportToFile: true,
        });
        if (response.ok && response.data) {
          fileDownload(response.data, `export.csv`, 'txt/csv', '\uFEFF');
        }
      }
      this.status = 'FETCHED';
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
      notify.error(this.error.message || 'error-fetching-shifts');
    }
  }

  @action
  async getShiftSetForEditing(shiftSetId: number) {
    this.status = 'BUSY';
    const result = await api.getShiftSetForEditing(shiftSetId);
    if (result.ok && result.data) {
      // const { closeReservation, shifts, ...rest } = result.data;
      this.error = undefined;
      // make date objects date objects
      const { common, shiftSets } = result.data;
      runInAction(() => {
        (this.unsavedShiftSetData = {
          common,
          shiftSets: shiftSets?.map(({ closeReservation, shifts, ...ss }, index) => ({
            index,
            closeReservation: closeReservation && new Date(closeReservation),
            ...ss,
            shifts: shifts?.map(({ startTime, endTime, ...s }, shiftIndex) => ({
              index: shiftIndex,
              startTime: new Date(startTime),
              endTime: new Date(endTime),
              ...s,
            })),
          })),
        }),
          (this.status = 'FETCHED');
        notify.success('shift-set-fetched');
      });
    } else {
      this.error = result.originalError || undefined;
      this.status = 'ERROR';
      notify.error(this.error?.message || 'error-fetching-shift-set');
    }
  }

  @action
  updateShiftSet(props: Partial<ShiftSet>) {
    this.shiftSet = { ...this.shiftSet!, ...props };
    this.status = 'CHANGED';
  }

  @action
  //get earliest shift of shiftset if closeReservation has not been set manually
  getSmallestStartDate(shifts: IShiftData[]) {
    const minDate = shifts.reduce((prev, curr) => (prev.startTime < curr.startTime ? prev : curr));
    return minDate.startTime;
  }

  @action
  // remove unwriteable fields form outgoing payload
  onlyWriteableFields({ shiftSets, common }: Partial<ICreateOrUpdateShiftSetsData>) {
    return {
      common,
      shiftSets: shiftSets?.map(({ id, closeReservation, shifts, reservedToId }, index) => ({
        id,
        closeReservation: (closeReservation && new Date(closeReservation)) || (shifts && this.getSmallestStartDate(shifts)),
        reservedToId,
        shifts: shifts?.map(({ id, startTime, endTime, shiftKey }, shiftIndex) => ({ id, startTime, endTime, shiftKey })),
      })),
    } as ICreateOrUpdateShiftSetsData;
  }

  get fieldDefaultsKey(): string {
    const { common } = this.unsavedShiftSetData || {};
    return `shift-defaults-${common?.unitLikeitId}-${common?.departmentLikeitId}`;
  }

  get fieldTimeDefaultsKey(): string {
    const { common } = this.unsavedShiftSetData || {};
    return `shift-time-defaults-${common?.unitLikeitId}-${common?.departmentLikeitId}`;
  }

  /* load shift-defaults for current unsavedData */
  @action
  async loadFieldDefaults(type?: ShiftSetPreferenceType): Promise<IPrefsItem<IDefaultTimes> | undefined> {
    if (type === 'SHIFT_TIME_SETTIGS') {
      // load prefs if not already loaded
      if (this.shiftTimeDefaults?.key !== this.fieldTimeDefaultsKey) {
        const loadedPrefs = await prefsStore.loadPrefs(this.fieldTimeDefaultsKey);
        const pref: IPrefsItem<IDefaultTimes> = loadedPrefs?.[0];
        this.shiftTimeDefaults = pref;
      }
      return this.shiftTimeDefaults;
    } else {
      const loadedPrefs = await prefsStore.loadPrefs(this.fieldDefaultsKey);
      const pref: IPrefsItem<any> = loadedPrefs?.[0];
      this.fieldDefaults = loadedPrefs; // save all prefs
      if (this.unsavedShiftSetData && pref?.key === this.fieldDefaultsKey) {
        const { common, shiftSets } = this.unsavedShiftSetData;
        switch (type) {
          case 'NOTIFICATION_SETTINGS':
            this.unsavedShiftSetData = undefined; // for some reason change is not detected without this
            this.unsavedShiftSetData = {
              common: {
                ...common,
                customEmailList: pref.value.emailList || [],
                customNumberList: pref.value.numberList || [userStore.user?.phone],
              },
              shiftSets,
            };
            this.status = 'CHANGED';
            break;
          case 'GENERAL':
            this.unsavedShiftSetData = undefined; // for some reason change is not detected without this
            this.unsavedShiftSetData = {
              common: {
                ...common,
                description: pref.value.description || '',
                notes: pref.value.notes || '',
              },
              shiftSets,
            };
            this.status = 'CHANGED';
            break;
        }
      } else if (this.unsavedShiftSetData && !pref && userStore?.user?.phone) {
        const { common, shiftSets } = this.unsavedShiftSetData;
        this.unsavedShiftSetData = undefined; // for some reason change is not detected without this
        this.unsavedShiftSetData = {
          common: {
            ...common,
            customEmailList: [],
            customNumberList: [userStore.user?.phone],
            description: '',
            notes: '',
          },
          shiftSets,
        };
        this.status = 'CHANGED';
      }
    }
  }

  @action
  async saveShiftTimeDefaults(options: any[]): Promise<void> {
    if (!this.unsavedShiftSetData || !options?.length) return; // nothing to save
    runInAction(() => (this.status = 'BUSY'));
    const oldId = this.shiftTimeDefaults?.id || undefined;
    const value = {
      target: this.unsavedShiftSetData.common?.organizationFullName || 'undefined organization',
      options,
    };
    const savedTimePrefs = await prefsStore.savePrefs({ key: this.fieldTimeDefaultsKey, id: oldId, value });
    runInAction(() => {
      this.shiftTimeDefaults = savedTimePrefs?.[0];
      this.status = 'FETCHED';
    });
  }

  /* save shift-defaults for current unsavedData */
  @action
  async saveFieldDefaults(type: ShiftSetPreferenceType) {
    if (!this.unsavedShiftSetData?.common) return;
    let oldValue = this.fieldDefaults?.[0]?.value || {};
    let oldId = this.fieldDefaults?.[0]?.id || undefined;
    // make sure current data matches
    if (this.fieldDefaults?.[0]?.key !== this.fieldDefaultsKey) {
      const existingPrefs = await prefsStore.loadPrefs(this.fieldDefaultsKey);
      const existing = existingPrefs?.[0];
      if (existing) {
        oldValue = existing.value;
        oldId = existing.id;
      }
    }

    let value: any;
    switch (type) {
      case 'NOTIFICATION_SETTINGS':
        const numberList = this.unsavedShiftSetData?.common?.customNumberList;
        const emailList = this.unsavedShiftSetData?.common?.customEmailList;
        value = { ...oldValue, numberList, emailList };
        break;
      case 'GENERAL':
        const { description, notes } = this.unsavedShiftSetData.common;
        value = { ...oldValue, description, notes };
        break;
    }
    const savedPrefs = await prefsStore.savePrefs({ key: this.fieldDefaultsKey, id: oldId, value });
    runInAction(() => {
      this.fieldDefaults = savedPrefs;
    });
  }

  @action
  async createOrUpdateShiftSet() {
    if (this.status == 'BUSY' || !this.unsavedShiftSetData) return;
    this.status = 'BUSY';
    this.error = undefined;
    this.errorDetails = undefined;
    const successState = this.unsavedShiftSetData?.shiftSets && this.unsavedShiftSetData.shiftSets[0].id ? 'SAVED' : 'SAVED_NEW';
    const result = await api.createOrUpdateShiftSet(this.onlyWriteableFields(this.unsavedShiftSetData));
    if (result.ok && result.data) {
      // make date objects date objects
      const { common, shiftSets } = result.data;
      runInAction(() => {
        this.unsavedShiftSetData = {
          common,
          shiftSets: shiftSets?.map(({ closeReservation, shifts, ...ss }, index) => ({
            index,
            closeReservation: closeReservation && new Date(closeReservation),
            ...ss,
            shifts: shifts?.map(({ startTime, endTime, ...s }, shiftIndex) => ({
              index: shiftIndex,
              startTime: new Date(startTime),
              endTime: new Date(endTime),
              ...s,
            })),
          })),
        };
        this.status = successState;
      });
      notify.success('shift-set-saved');
    } else {
      this.errorDetails = result.data || undefined;
      this.status = 'ERROR';
      const { message } = result.data as ICreateOrUpdateShiftSetsError;

      if (message) {
        this.error = message;
        result?.status === 409 ? notify.error(message.toString(), notificationOptions, false) : notify.error('error-saving-shift-set', notificationOptions);
      }
    }
  }

  @action
  async deleteShiftSet(shiftSetId: number) {
    const { token } = userStore;
    if (!token || !shiftSetId) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.deleteShiftSet({ token, shiftSetId });
    if (result.ok) {
      this.error = undefined;
      this.status = 'DELETED';
      notify.success('shift-set-deleted');
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
      notify.error(this.error.message || 'error-deleting-shift-set');
    }
  }
  @action
  async resendShiftSetToRecipients(shiftSetId: number) {
    this.status = 'BUSY';
    const result = await api.resendShiftSetToRecipients(shiftSetId);
    if (result.ok) {
      this.error = undefined;
      this.status = 'RESENT';
      notify.success('shift-set-resent');
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
      notify.error(this.error.message || 'error-resending-shift-set');
    }
  }

  @action
  createNew() {
    const myPhone = userStore.user?.phone ? userStore.user.phone : '';
    this.unsavedShiftSetData = {
      common: {
        sendEmail: true,
        sendPushNotification: true,
        sendSMS: false,
        notes: '',
        customNumberList: [myPhone],
      },
      shiftSets: [{ shifts: [{ startTime: DEFAULT_DATE.toJSDate(), endTime: DEFAULT_DATE.plus({ hours: 7 }).toJSDate(), shiftKey: 'morning' }], index: 0 }],
    } as ICreateOrUpdateShiftSetsData;
    this.status = 'NEW';
  }

  @action
  async change(props: Partial<ICreateOrUpdateShiftSetsData>, changeOrganization?: boolean) {
    if (changeOrganization && !props.common?.organization) {
      notify.error('Please select an organization!');
      return;
    }

    this.unsavedShiftSetData = {
      ...(this.unsavedShiftSetData || {}),
      ...props,
    };

    //if this is organization change, update unsaved shifts default times
    if (changeOrganization) {
      // 1. load time preferences
      const savedTimes: IPrefsItem<IDefaultTimes> | undefined = await this.loadFieldDefaults('SHIFT_TIME_SETTIGS');
      // timeprefs found ->
      if (savedTimes?.value) {
        // 2.  can if there is existing shifts in unsavedShiftSetData and thath have no id, meaning they are new
        // so we should update their start and end date to any existing preferenes
        this.unsavedShiftSetData?.shiftSets?.forEach(shiftSet => {
          shiftSet.shifts?.forEach(shift => {
            if (!shift.id) {
              // added shift -> update time to defaults
              const { options } = savedTimes.value;
              const shiftKeyOption = options.find(o => o.value === shift.shiftKey) || options[0];
              if (shiftKeyOption.startClock) {
                const [hour, minute] = shiftKeyOption.startClock.split(':').map(Number);
                shift.startTime = DateTime.fromJSDate(shift.startTime).set({ hour, minute }).toJSDate();
              }
              if (shiftKeyOption.endClock) {
                const [hour, minute] = shiftKeyOption.endClock.split(':').map(Number);
                // end hour < start hour, then enddate next day
                if (DateTime.fromJSDate(shift.endTime).set({ hour, minute }).toJSDate() < shift.startTime) {
                  shift.endTime = DateTime.fromJSDate(shift.endTime).set({ hour, minute }).plus({ day: 1 }).toJSDate();
                } else {
                  shift.endTime = DateTime.fromJSDate(shift.endTime).set({ hour, minute }).toJSDate();
                }
              }
            }
          });
        });
      }
    }

    if (this.errorDetails?.message) {
      this.errorDetails.message = this.errorDetails.message.filter((str: string) => {
        for (const key in props.common) {
          if (str.includes(key)) {
            return false;
          }
        }
        return true;
      });
    }

    this.status = changeOrganization ? 'CHANGED ORGANIZATION' : 'CHANGED';
  }
}

export const shiftStore = new ShiftStore();

import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { View } from '../../components/View';
import { chatEventStore } from '../../mobx/chatEventStore';
import ChatRoom from './ChatRoom';
import ChatSideUI from './ChatSideUI';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0px !important;
  background-color: ${p => p.theme.colors.grayDarkest};
  min-height: 90vh !important;
`;

export const Chat: FC = observer(() => {
  const [selectedChat, setSelectedChat] = useState<number | undefined>(undefined); // brb shuolud be selectedChatId
  const { chatList, currentChat, eventStatus } = chatEventStore;

  // useEffect(() => {
  //   if (eventStatus === 'ERROR') {
  //     chatEventStore.reopen();
  //   }
  // }, [eventStatus]);

  useEffect(() => {
    if (selectedChat && selectedChat !== currentChat?.id) chatEventStore.selectChat(selectedChat, chatEventStore.foundMessage.messageId); // select chat on selectedChat change
  }, [selectedChat]);

  return (
    <View noPadding>
      <Container>
        <ChatSideUI chatList={chatList} selectedChat={selectedChat} setSelectedChat={setSelectedChat} />
        {currentChat && <ChatRoom />}
      </Container>
    </View>
  );
});

/*
id: number;
unit: string;
department: string;
reason: string;
task: string;
startTime: Date;
endTime: Date;
shiftKey: ShiftKey;
createdBy: string;
createdAt: Date;
status: ShiftStatus;
date?: string;
time?: string;
*/

export const fromApi = (shift: any) => {
  const unitName = shift.shiftSet?.unit?.name || '–';
  const departmentName = shift.shiftSet?.department?.name || '–';
  const taskName = shift.shiftSet?.task?.name || '–';
  const employee = shift.reservation?.name && shift.reservation?.phoneNumber ? `${shift.reservation.name}, ${shift.reservation.phoneNumber}` : null;

  return {
    ...shift,
    shiftSetId: shift.shiftSetId,
    unit: unitName,
    department: departmentName,
    task: taskName,
    startTime: shift.startTime ? new Date(shift.startTime) : null,
    endTime: shift.endTime ? new Date(shift.endTime) : null,
    createdAt: shift.createdAt ? new Date(shift.createdAt) : null,
    reservation: employee,
  };
};

export const toApi = (shift: any) => {
  return {
    ...shift,
    shiftKey: shift.shiftKey ? shift.shiftKey.value : null,
  };
};

import { action, makeAutoObservable } from 'mobx';

class AppStore {
  status: 'READY' = 'READY';
  isNavOpen: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  @action
  setIsNavOpen(isOpen: boolean) {
    this.isNavOpen = isOpen;
  }
}

export const appStore = new AppStore();

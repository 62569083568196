import React, { FC, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '../../components/inputs';
import SearchOrgForm, { SearchOrgFormParams } from '../../components/SearchOrgForm';

interface Props {
  onSubmit: (searchTerms: Partial<SearchTerms>) => void;
}

type SourceType = 'SENDSMS' | 'SENDMESSAGE' | 'MESSAGETOGROUP';

export const MessageLogsForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [source, setSource] = useState<SourceType>('SENDSMS');
  const [type, setType] = useState<'email' | 'sms' | undefined>();
  const typeOption: SelectOption<MessageType>[] = [
    { value: 'email', label: t('email') },
    { value: 'sms', label: t('sms') },
  ];
  const sourceOption: SelectOption<SourceType>[] = [
    { value: 'SENDSMS', label: t('genericSms') },
    { value: 'SENDMESSAGE', label: t('messageToUser') },
    { value: 'MESSAGETOGROUP', label: t('messageToGroup') },
  ];

  const handleSearch = ({ group, employee }: SearchOrgFormParams) => {
    onSubmit({
      type,
      source,
      search,
      groupId: (group && group.value) || undefined,
      employeeId: (employee && employee.value) || undefined,
    });
  };

  return (
    <Form>
      <Select<MessageType> id="type" label={t('messageType')} options={typeOption} selected={typeOption.find(o => o.value === type)} onChange={o => setType(o?.value)} />
      <Select<SourceType> id="type" label={t('source')} options={sourceOption} selected={sourceOption.find(o => o.value === source)} onChange={o => setSource(o?.value)} />
      <Input id="search" value={search} label={t('searchTerm')} setValue={setSearch} />
      <SearchOrgForm selUnit={false} selDepartment={false} selTask={false} selTime={false} exportCheckbox={false} employeeGroupsCheckbox={false} onSubmit={handleSearch} />
    </Form>
  );
};

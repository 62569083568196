export const PHONE_PREFIX = '+358 ';

const phoneNumber = (input: string) => {
  // Allow only digits and characters ' ' and '+'
  const notAllowed = /[^\d\s]/g;
  return `${PHONE_PREFIX}${input.slice(5).replaceAll(notAllowed, '')}`;
};

const Format = {
  phoneNumber,
};

export default Format;

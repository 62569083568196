import { DateTime } from 'luxon';
import { action, makeAutoObservable } from 'mobx';

import { api } from '../services/api';
import notify from '../services/notify';
import { userStore } from './userStore';

class EmployeeSuggestionStore {
  status: 'IDLE' | 'BUSY' | 'FETCHED' | 'NEW' | 'CHANGED' | 'DELETED' | 'ERROR' | 'SAVED' = 'IDLE';
  error?: { message: string };
  errorDetails?: ApiError;
  total: number = 0;
  page: number = 1;
  pages: number = 1;
  items?: EmployeeSuggestion[];
  employeeSuggestion?: Partial<EmployeeSuggestion>;

  constructor() {
    makeAutoObservable(this);
  }
  @action
  async list({ skip = 0, take = 100, ...rest }: Omit<GetEmployeeSuggestionsRequest, 'token'> = {}): Promise<void | EmployeeSuggestionsResult> {
    const format = 'yyyy-MM-dd HH:mm:ss';
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.page = Math.ceil(skip / take) + 1;
    this.status = 'BUSY';

    const st = rest.startTime as Date;
    const et = rest.endTime as Date;
    const startTime = st ? DateTime.fromJSDate(st).toFormat(format).replace(' ', 'T') : undefined;
    const endTime = et ? DateTime.fromJSDate(et).toFormat(format).replace(' ', 'T') : undefined;
    const result = await api.getEmployeeSuggestions({ token, skip, take, ...rest, startTime, endTime });
    if (result.ok && result.data) {
      this.items = result.data.results;
      this.total = result.data.total;
      this.page = Math.ceil(skip / take) + 1;
      this.pages = Math.ceil(this.total / take);
      this.error = undefined;
      this.status = 'FETCHED';
      return result.data;
    } else {
      this.error = result.originalError || undefined;
      notify.error(result.originalError?.message || 'error-listing-employee-suggestions');
      this.status = 'ERROR';
    }
  }
  @action
  createNew() {
    this.employeeSuggestion = {
      description: '',
      startDateIgnoreTime: true,
      endDateIgnoreTime: true,
    };
    this.status = 'NEW';
  }

  @action
  async deleteById(id: number) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.deleteEmployeeSuggestion({ token, id });
    if (result.ok) {
      this.employeeSuggestion = undefined;
      this.error = undefined;
      this.status = 'DELETED';
      notify.success('employee-suggestion-deleted-successfully');
    } else {
      this.error = result.originalError || undefined;
      this.status = 'ERROR';
      notify.error(this.error?.message || 'error-deleting-employee-suggestion');
    }
  }

  @action
  async fetchById(id: number) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.getEmployeeSuggestionById({ token, id });
    if (result.ok && result.data) {
      const { startDate, endDate, ...rest } = result.data;
      this.employeeSuggestion = {
        startDate: startDate ? new Date(startDate) : undefined,
        endDate: endDate ? new Date(endDate) : undefined,
        ...rest,
      };
      this.error = undefined;
      this.status = 'FETCHED';
      notify.success('employee-suggestion-fetched-successfully');
    } else {
      this.error = result.originalError || undefined;
      this.status = 'ERROR';
      notify.error(this.error?.message || 'error-fetching-employee-suggestion');
    }
  }

  @action
  change(props: Partial<EmployeeSuggestion>) {
    this.employeeSuggestion = {
      ...this.employeeSuggestion,
      ...props,
    };
    if (this.errorDetails) {
      this.errorDetails.message = this.errorDetails.message.filter(d => !Object.keys(props).includes(d.property));
    }
    this.status = 'CHANGED';
  }

  @action
  async save(isCopy: boolean) {
    const { token } = userStore;
    if (!token || !this.employeeSuggestion || this.status !== 'CHANGED') {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    if (isCopy) this.employeeSuggestion = { ...this.employeeSuggestion, id: undefined };

    const validatedParams: CreateOrUpdateEmployeeSuggestionRequest = {
      token,
      ...this.employeeSuggestion,
    } as CreateOrUpdateEmployeeSuggestionRequest;
    const result = await api.createOrUpdateEmployeeSuggestion(validatedParams);
    if (result.ok) {
      this.error = undefined;
      this.errorDetails = undefined;
      this.status = 'SAVED';
      if (this.employeeSuggestion.id) {
        this.fetchById(this.employeeSuggestion.id);
      }
      notify.success('employee-suggestion-saved-successfully');
    } else {
      this.error = result.originalError || undefined;
      this.errorDetails = result.data || undefined;
      this.status = 'ERROR';
      notify.error(this.errorDetails?.error || this.error?.message || 'error-saving-employee-suggestion');
    }
  }

  @action
  async updateStatus(id: number, status: 'open' | 'closed') {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.updateEmployeeSuggestionStatus({ token, id, status });
    if (result.ok) {
      this.list();
      this.error = undefined;
      this.errorDetails = undefined;
      if (this.employeeSuggestion) {
        this.employeeSuggestion = { ...this.employeeSuggestion, ...result.data };
      }
      this.status = 'FETCHED';
      notify.success('employee-suggestion-status-updated-successfully');
    } else {
      this.error = result.originalError || undefined;
      this.errorDetails = result.data || undefined;
      this.status = 'ERROR';
      notify.error(this.errorDetails?.error || this.error?.message || 'error-updating-employee-suggestion-status');
    }
  }
}

export const employeeSuggestionStore = new EmployeeSuggestionStore();

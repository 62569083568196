import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import styled from 'styled-components';

import { ConfirmModal, Layout, Loader } from '../components';
import { Icon } from '../components/Icon';
import { Label } from '../components/inputs';
import Table, { ColumnType, ItemActionProps } from '../components/Table';
import i18n from '../i18n';
import { teamStore } from '../mobx/teamStore';
import { userStore } from '../mobx/userStore';
import ErrorPage from './ErrorPage';

const Container = styled.div`
  table {
    width: 650px;
    font-size: ${p => p.theme.fonts.size.lg};
    border: solid 1px #ccc;
    table-layout: fixed;
    th {
      background-color: #edf5f8;
    }
  }

  @media ${p => p.theme.breakpoints.mobile} {
    overflow-wrap: break-word;
    td:before {
      text-align: left;
      font-size: 13px;
    }
  }
`;

const ProfilePhoto = styled.img`
  max-width: 320px;
`;

export const MyData: FC = observer(() => {
  const { status, user, error } = userStore;
  const { t } = useTranslation();
  // NOTE: Header's UserMenu is responsible of fetching user's data
  //       and therefore it should always be available in the store
  if (status === 'ERROR') return <ErrorPage error={error} />;
  return (
    <Layout>
      <h1 data-testid="my-data-page-title">{t('user profile')}</h1>
      {status === 'BUSY' && <Loader />}
      {user && <UserData user={user} />}
      <a href={process.env.REACT_APP_API_BASEURL + '/announcement/privacy/redirect'}>Tietosuoja</a>
    </Layout>
  );
});

const UserData: FC<{ user: Person }> = ({ user }) => {
  if (!user) return null;
  const { t } = useTranslation();
  const [showEditTeam, setShowEditTeam] = useState<boolean>(false);
  const [showEditLanguage, setShowEditLanguage] = useState<boolean>(false);
  const tableColumns: Array<ColumnType<Person>[]> = [[{ key: 'firstName' }, { key: 'lastName' }], [{ key: 'phone' }, { key: 'email' }], [{ key: 'role' }, { key: 'ownOrganizationName' }], [{ key: 'address' }, { key: 'zipCode' }], [{ key: 'city' }]];

  const teamTableColumns = [{ key: 'name', label: 'team' }];
  const teamTableActions: ItemActionProps<Shift>[] = [{ label: t('edit'), icon: <Icon type="Edit" color="primary" />, onClick: () => setShowEditTeam(true) }];
  const selectedTeam = user.team ? [user.team] : [{ id: -1, likeitEmployeeId: -1, name: '', ownOrganizationName: '' }];

  const languageTableColumns = [{ key: 'language' }];
  const languageTableActions: ItemActionProps<Shift>[] = [{ label: t('edit'), icon: <Icon type="Edit" color="primary" />, onClick: () => setShowEditLanguage(true) }];
  const selectedLanguage = { language: i18n.language === t('fi') ? t('finnish') : t('english') };

  return (
    <Container>
      {user.photo && <ProfilePhoto src={user.photo} />}
      {tableColumns.map((columns, i) => (
        <Table<Person> key={`user-data-table-${i}`} data={[user]} columns={columns} />
      ))}
      <Table<any> data={selectedTeam} columns={teamTableColumns} itemActions={teamTableActions} />
      <Table<any> data={[selectedLanguage]} columns={languageTableColumns} itemActions={languageTableActions} />
      {showEditTeam && <EditMyTeam user={user} onHide={() => setShowEditTeam(false)} />}
      {showEditLanguage && <EditMyLanguage user={user} onHide={() => setShowEditLanguage(false)} />}
    </Container>
  );
};

const EditMyTeam: FC<{ onHide: Function; user: Person }> = observer(({ onHide, user }) => {
  const { t } = useTranslation();
  const { status, teams } = teamStore;
  const [selectedTeam, setSelectedTeam] = useState<SelectOption<number | undefined> | undefined | null>(user.team ? { value: user.team.likeitEmployeeId, label: user.team.name } : undefined);
  const teamOptions = teams?.map(team => ({ value: team.teamId, label: team.name })) || [];
  useEffect(() => {
    if (status === 'IDLE' && !teams) {
      teamStore.getTeams();
    }
  });
  const saveSelectedTeam = () => {
    if (selectedTeam?.value) {
      teamStore.setMyTeam(selectedTeam.value);
    }
    onHide();
  };
  return (
    <ConfirmModal onConfirm={() => saveSelectedTeam()} onHide={onHide} confirmText={t('save')} cancelText={t('cancel')}>
      <Label>{t('team')}</Label>
      <ReactSelect value={selectedTeam} noOptionsMessage={() => t('noOptions')} onChange={setSelectedTeam} placeholder={t('select-team')} options={teamOptions} />
    </ConfirmModal>
  );
});

const EditMyLanguage: FC<{ onHide: Function; user: Person }> = observer(({ onHide }) => {
  const { t } = useTranslation();

  const languageOptions = [
    { value: 'fi', label: `${t('finnish')}` },
    { value: 'en', label: `${t('english')}` },
  ];

  const initialLanguage = i18n.language === 'fi' ? languageOptions[0] : languageOptions[1];

  const [selectedLanguage, setSelectedLanguage] = useState<SelectOption<string | undefined> | undefined | null>(initialLanguage);

  const saveSelectedLanguage = () => {
    if (selectedLanguage?.value) {
      i18n.changeLanguage(selectedLanguage.value);
    }
    onHide();
  };

  return (
    <ConfirmModal onConfirm={() => saveSelectedLanguage()} onHide={onHide} confirmText={t('save')} cancelText={t('cancel')}>
      <Label>{t('language')}</Label>
      <ReactSelect value={selectedLanguage} noOptionsMessage={() => t('noOptions')} onChange={setSelectedLanguage} placeholder={t('select-team')} options={languageOptions} />
    </ConfirmModal>
  );
});

export default MyData;

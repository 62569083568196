import styled from 'styled-components';
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Container = styled.div`
  div.columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media ${p => p.theme.breakpoints.mobile} {
      flex-direction: column;
    }

    > div.column {
      flex: 1;
      :not(:first-child) {
        margin-left: ${p => p.theme.spacings.md};
        @media ${p => p.theme.breakpoints.mobile} {
          margin-left: 0;
        }
      }
      :not(:last-child) {
        margin-right: ${p => p.theme.spacings.md};
        @media ${p => p.theme.breakpoints.mobile} {
          margin-right: 0;
        }
      }
    }
  }
`;

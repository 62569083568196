import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon } from '../../../components';
import { Button, Checkbox, Input } from '../../../components/inputs';
import { prefsStore } from '../../../mobx/prefsStore';
import { shiftStore } from '../../../mobx/shiftStore';
import { userStore } from '../../../mobx/userStore';
import { PHONE_PREFIX } from '../../../utils/format';

const Container = styled.div`
  .notify-custom-contact-info-container {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: column;
    max-width: 70vh;

    .custom-contact-info-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        margin-left: ${p => p.theme.spacings.sm} !important;
      }

      .error {
        color: red;
        font-style: italic;
      }
    }
  }
`;

const NotificationSettings: FC = observer(() => {
  const [busySaving, setBusySaving] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = userStore;
  const { unsavedShiftSetData, status: shiftStatus } = shiftStore;
  const noOrganizationSelected = !unsavedShiftSetData?.common?.unitLikeitId || !unsavedShiftSetData?.common?.departmentLikeitId;
  const { status: prefStatus } = prefsStore;

  const commonShiftSetData = unsavedShiftSetData?.common;

  const myPhoneNumber = user?.phone;
  const notifyMe = (myPhoneNumber && commonShiftSetData?.customNumberList?.includes(myPhoneNumber)) || false;

  const isEditing = unsavedShiftSetData?.shiftSets && unsavedShiftSetData.shiftSets[0].id;

  const isBusy = prefStatus === 'BUSY' || shiftStatus === 'BUSY';

  useEffect(() => {
    if (shiftStatus === 'CHANGED ORGANIZATION' && !isEditing) {
      loadFieldDefaults();
    }
  }, [shiftStatus]);

  useEffect(() => {}, [prefStatus, shiftStatus]);

  const handleAddNewNumber = () => {
    const newEmptyNumber = PHONE_PREFIX;
    const updatedNumberList = commonShiftSetData?.customNumberList ? [...commonShiftSetData?.customNumberList, newEmptyNumber] : [newEmptyNumber];
    shiftStore.change({ common: { ...commonShiftSetData, customNumberList: updatedNumberList } });
  };

  const handleAddNewEmail = () => {
    const newEmptyEmail = '';
    const updatedEmailList = commonShiftSetData?.customEmailList ? [...commonShiftSetData?.customEmailList, newEmptyEmail] : [newEmptyEmail];
    shiftStore.change({ common: { ...commonShiftSetData, customEmailList: updatedEmailList } });
  };

  const updateNumbers = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    shiftStore.change({
      common: {
        ...commonShiftSetData,
        customNumberList: commonShiftSetData?.customNumberList?.map((value, idx) => (idx === index ? event.target.value : value)),
      },
    });
  };

  const updateEmails = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    shiftStore.change({
      common: {
        ...commonShiftSetData,
        customEmailList: commonShiftSetData?.customEmailList?.map((value, idx) => (idx === index ? event.target.value : value)),
      },
    });
  };

  const removeCustomNumber = (removeNumber: string) => {
    const updatedNumberList = commonShiftSetData?.customNumberList?.filter(number => number !== removeNumber);
    shiftStore.change({ common: { ...commonShiftSetData, customNumberList: updatedNumberList } });
  };

  const removeCustomEmail = (removeEmail: string) => {
    const updatedEmailList = commonShiftSetData?.customEmailList?.filter(email => email !== removeEmail);
    shiftStore.change({ common: { ...commonShiftSetData, customEmailList: updatedEmailList } });
  };

  const loadFieldDefaults = async () => {
    setBusySaving(true);
    await shiftStore.loadFieldDefaults('NOTIFICATION_SETTINGS');
    setTimeout(() => setBusySaving(false), 100);
  };

  const saveFieldDefaults = async () => {
    setBusySaving(true);
    await shiftStore.saveFieldDefaults('NOTIFICATION_SETTINGS');
    setTimeout(() => setBusySaving(false), 100);
  };

  const handleNotifyMe = () => {
    if (!myPhoneNumber) return;

    if (commonShiftSetData?.customNumberList?.includes(myPhoneNumber)) {
      const updatedNumberList = commonShiftSetData?.customNumberList?.filter(number => number !== myPhoneNumber);
      shiftStore.change({ common: { ...commonShiftSetData, customNumberList: updatedNumberList } });
    } else {
      const updatedNumberList = commonShiftSetData?.customNumberList ? [...commonShiftSetData?.customNumberList, myPhoneNumber] : [myPhoneNumber];
      shiftStore.change({ common: { ...commonShiftSetData, customNumberList: updatedNumberList } });
    }
  };

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const checkIfIsEmail = (email: string) => {
    return emailRegex.test(email);
  };

  const checkIfIsPhone = (phone: string) => {
    return (phone !== null && phone.startsWith('+') && phone.slice(1).match(/^[\d\s]+$/)) || phone === '';
  };

  return (
    <Container>
      <h1>{t('notifications')}</h1>
      <Checkbox id="new-shift-notify-me" checked={notifyMe} label={t('sendMeNotifcation', { number: myPhoneNumber ?? '' })} setValue={handleNotifyMe} />
      <div className="notify-custom-contact-info-container">
        <div>
          {commonShiftSetData?.customNumberList?.map((number, index) => {
            const isValidPhone = checkIfIsPhone(number);
            return (
              <div key={index + '_numbers'}>
                <div className="custom-contact-info-input-container">
                  <Input
                    id={`new-shift-notify-custom-number-input-${index}`}
                    prepend={<Icon type="Phone" color="primary" />}
                    value={number}
                    placeholder={t('inputPlaceholder.phoneNumber')}
                    onChange={event => updateNumbers(index, event)}
                  />
                  <Button id={`remove-custom-number-button-${index}`} text={t('delete')} variant="mini" isSmall onClick={() => removeCustomNumber(number)} />
                  {!isValidPhone && <div className="error">{t('invalid phonenumber')}</div>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Button id="new-custom-number-button" text={`+ ${t('add number')}`} onClick={handleAddNewNumber} variant="outlined" color="primary" isSmall />

      <div className="notify-custom-contact-info-container">
        <div>
          {commonShiftSetData?.customEmailList?.map((email, index) => {
            const isValidEmail = checkIfIsEmail(email);
            return (
              <div key={index + '_emails'}>
                <div className="custom-contact-info-input-container">
                  <Input
                    id={`new-shift-notify-custom-email-input-${index}`}
                    prepend={<Icon type="Message" color="primary" />}
                    value={email}
                    placeholder={t('inputPlaceholder.email')}
                    onChange={event => updateEmails(index, event)}
                  />
                  <Button id={`remove-custom-email-button-${index}`} text={t('delete')} variant="mini" isSmall onClick={() => removeCustomEmail(email)} />
                  {!isValidEmail && <div className="error">{t('invalid email')}</div>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Button id="new-custom-email-button" text={`+ ${t('add email')}`} onClick={handleAddNewEmail} variant="outlined" color="primary" isSmall />
      <div>
        <Button
          id="shift-load-default"
          disabled={busySaving || noOrganizationSelected}
          isSmall={true}
          variant="mini"
          text={t('load default custom contact info')}
          onClick={loadFieldDefaults}
        />
        <Button
          id="shift-save-default"
          disabled={busySaving || noOrganizationSelected}
          isSmall={true}
          variant="mini"
          text={t('save default custom contact info')}
          onClick={saveFieldDefaults}
        />
      </div>
    </Container>
  );
});

export default NotificationSettings;

import styled from 'styled-components';
import { Colors } from '../../theme/styled';

type ColorType = keyof Colors;

export const LinkButton = styled.a<{ isSmall?: boolean; color?: ColorType }>`
  display: block;
  color: ${p => (p.color === 'primary' ? 'white' : 'black')};
  background-color: ${p => (p.color ? p.theme.colors[p.color] : 'transparent')};

  border-radius: ${p => (p.isSmall ? '5px' : '40px')};
  margin: ${p => p.theme.spacings.sm} 0;
  padding: ${p => p.theme.spacings.md} ${p => p.theme.spacings.lg};

  font-family: ${p => p.theme.fonts.secondary};
  font-size: ${p => (p.isSmall ? p.theme.fonts.size.sm : p.theme.fonts.size.md)};
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;

  svg {
    margin-right: ${p => p.theme.spacings.sm};
    margin-left: ${p => p.theme.spacings.sm};
  }

  @media ${p => p.theme.breakpoints.mobile} {
    display: inline-block;
  }
`;

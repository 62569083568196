import { action, makeAutoObservable } from 'mobx';
import { userStore } from './userStore';
import { api } from '../services/api';

class CustomerSuggestionStore {
  status: 'IDLE' | 'BUSY' | 'FETCHED' | 'NEW' | 'CHANGED' | 'ERROR' = 'IDLE';
  total: number = 0;
  page: number = 1;
  pages: number = 1;
  items?: CustomerSuggestion[];
  selectedItem?: Partial<CustomerSuggestionItem>;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async list(
    { skip, take, status }: Omit<GetCustomerSuggestionsRequest, 'token'> = {
      skip: 0,
      take: 100,
      status: 'open',
    }
  ): Promise<void | { total: number; results: CustomerSuggestion[] }> {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.page = Math.ceil(skip / take) + 1;
    this.status = 'BUSY';
    const result = await api.getCustomerSuggestions({ token, skip, take, status });
    if (result.ok && result.data) {
      this.items = result.data.results;
      this.total = result.data.total;
      this.page = Math.ceil(skip / take) + 1;
      this.pages = Math.ceil(this.total / take);
      this.status = 'FETCHED';
      return result.data;
    } else {
      this.status = 'ERROR';
    }
  }

  @action
  async createOrUpdate(props: Omit<CreateOrUpdateEmployeeSuggestionRequest, 'token'>) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.createOrUpdateEmployeeSuggestion({ token, ...props });
    if (result.ok) {
      this.list();
      this.status = 'FETCHED';
    } else {
      this.status = 'ERROR';
    }
  }

  @action
  createNew() {
    this.selectedItem = {};
    this.status = 'NEW';
  }

  @action
  async fetchById(id: number) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.getCustomerSuggestionById({ token, id });
    if (result.ok && result.data) {
      this.selectedItem = result.data;
      this.status = 'FETCHED';
    } else {
      this.status = 'ERROR';
    }
  }

  @action
  change(props: Partial<CustomerSuggestionItem>) {
    this.selectedItem = { ...this.selectedItem, ...props };
    this.status = 'CHANGED';
  }

  @action
  async save() {
    const { token } = userStore;
    if (!token || !this.selectedItem || this.status !== 'CHANGED') {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const validatedParams: CreateOrUpdateCustomerSuggestionRequest = {
      token,
      ...this.selectedItem,
    } as CreateOrUpdateEmployeeSuggestionRequest;
    const result = await api.createOrUpdateCustomerSuggestion(validatedParams);
    if (result.ok) {
      this.list();
      this.status = 'FETCHED';
    } else {
      this.status = 'ERROR';
    }
  }
}

export const customerSuggestionStore = new CustomerSuggestionStore();

import apisauce from 'apisauce';

import { userStore } from '../../mobx/userStore';
import apiConfig from './config';

export const auth = (token?: string) => {
  if (!token) return;
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

const authHeaders = async () => {
  const { token } = userStore;
  if (token) return { Authorization: `Bearer ${token}` };
};

const create = () => {
  const api = apisauce.create(apiConfig);

  // authorize all requests with token
  api.addAsyncRequestTransform(async request => {
    request.headers = await authHeaders();
  });

  const sendPasscode = ({ email, phoneNumber }: SendPasscodeRequest) => {
    if (phoneNumber?.length > 5) {
      // Phone number's length must be more than just prefix '+358 '
      return api.post('auth/get-sms-code', { phone: phoneNumber }, {});
    }
    return api.post('auth/get-email-code', { email }, {});
  };

  const login = ({ passcode }: LoginRequest) => {
    // This is additional security check preventing bots.
    // Don't change the app code since it's hard coded to the API.
    const appCode = 'BlRVEuPkV1V0EPUx';
    return api.post<{ token: string }>('auth/admin-login', { code: passcode, appCode }, {});
  };

  const logout = ({ token }: LogoutRequest) => {
    return api.get('auth/logout', {}, auth(token));
  };

  const getMe = ({ token }: GetMeRequest) => {
    return api.get<Person>('user/me', {}, auth(token));
  };

  const hasReservations = ({ token, ...params }: GetUserReservationsParams) => {
    const { employeeId, startTime, endTime } = params;
    return api.get(`shift/hasreservations/${employeeId}`, { startTime, endTime }, auth(token));
  };

  const getShifts = ({ token, take = 50, skip = 0, ...params }: GetShiftsRequest) => {
    return api.post<{ total: number; results: Shift[] }>('shift/list', { skip, take, ...params }, auth(token));
  };

  const getShift = ({ token, shiftId }: GetShiftRequest) => {
    return api.get(`shift/${shiftId}`, {}, auth(token));
  };

  const listShiftSetsByReservation = ({ token, take = 50, skip = 0, ...params }: ListShiftSetsByReservationRequest) => {
    return api.post<{ total: number; results: IReservedShift[] }>('shift/byReservation', { skip, take, ...params }, auth(token));
  };

  const deleteShift = ({ token, shiftId }: GetShiftRequest) => {
    return api.delete(`shift/${shiftId}`, {}, auth(token));
  };

  const deleteShiftSet = ({ token, shiftSetId }: GetShiftSetRequest) => {
    return api.delete(`shift/set/${shiftSetId}`, {}, auth(token));
  };

  const cancelReservation = ({ token, shiftId }: CancelReservationRequest) => {
    return api.delete(`shift/${shiftId}/reservation`, {}, auth(token));
  };

  const reserveShiftForUser = ({ shiftId, employeeId, token }: ReserveShiftForUserRequest) => {
    return api.put('shift/reservation/forUser', { employee: employeeId, shift: shiftId }, auth(token));
  };

  const getEmployeeGroups = ({ token }: GetEmployeeGroupsRequest) => {
    return api.get('persongroup', {}, auth(token));
  };

  const getEmployeesOfGroup = ({ token, groupId }: GetEmployeesOfGroupRequest) => {
    return api.get(`persongroup/${groupId}`, {}, auth(token));
  };

  const searchPersonGroup = ({ token, params }: SearchGroupsRequest) => {
    return api.get(`persongroup/search`, params, auth(token));
  };

  const sendMessage = ({ message, token }: SendMessageRequest) => {
    return api.post<{ recipientCount: number }>('messages/send', message, auth(token));
  };

  const messageLog = ({ token, ...params }: MessageLogRequest) => {
    return api.get<MessageLogResponse>('/messages/log', params, auth(token));
  };

  const savePrefs = ({ id, key, value }: { id?: number; key: string; value: any }) =>
    id
      ? api.put<{ key: string; value: any; id: number }[]>(`/prefs/${key}`, { id, key, value })
      : api.post<{ key: string; value: any; id: number }[]>('/prefs', { key, value });

  const loadPrefs = (key: string) => api.get<{ key: string; value: any; id: number }[]>(`/prefs/${key}`);

  const getUnits = ({ search, token, take = 50 }: GetUnitsRequest) => {
    return api.get('units', { search, take }, auth(token));
  };

  const getDepartments = ({ unitId, search, token }: GetDepartmentsRequest) => {
    return api.get(`units/${unitId}`, { search }, auth(token));
  };

  const getReasons = ({ unitId, token }: GetReasonsRequest) => {
    return api.get('shift/reasons', { unitId }, auth(token));
  };

  const getTasks = ({ unitId, departmentId, token }: GetTasksRequest) => {
    return api.get('shift/tasks', { unit: unitId, department: departmentId }, auth(token));
  };

  const getShiftSet = ({ shiftSetId }: GetShiftSetRequest) => {
    return api.get<ShiftSet>(`shift/set/${shiftSetId}`, { mode: 'full' });
  };

  const getShiftSetForEditing = (shiftSetId: number) => {
    return api.get<ICreateOrUpdateShiftSetsData>(`shiftset/${shiftSetId}/forEditing`);
  };

  const sendShiftsData = ({ shiftsData, token }: SendShiftsDataRequest) => {
    return api.post('shift/set', shiftsData, auth(token));
  };

  const updateShiftSet = ({ token, shiftSet }: UpdateShiftSetDataRequest) => {
    return api.put<ShiftSet>(`shift/set/${shiftSet.id}/update`, shiftSet, auth(token));
  };

  const createOrUpdateShiftSet = (unsavedShiftSetData: ICreateOrUpdateShiftSetsData) => {
    return api.post<ICreateOrUpdateShiftSetsData, ICreateOrUpdateShiftSetsError>(`shiftset/createOrUpdate`, unsavedShiftSetData);
  };

  const getOrganizationsBy = ({ token, ...params }: GetOrgsByRequest) => {
    return api.get<{ results: Organization[] }>(`organization`, params, auth(token));
  };

  const organizationSearch = ({ ...params }: GetOrgOptionsRequest) => {
    return api.post<GetOrgOptionsResponse>(`organization/search`, params);
  };

  const getOrganizationTree = ({ token, ...params }: GetOrgTreeRequest) => {
    return api.get<GetOrgTreeResponse>(`organization/tree`, params, auth(token));
  };

  const getTasksByCustomerId = ({ token, customerId }: GetTasksByCustomerIdRequest) => {
    return api.get<GetTasksByCustomerIdResponse, any>(`organization/${customerId}/tasks`, {}, auth(token));
  };

  const compareLikeitUser = ({ token, ...params }: CompareLikeitUserRequest) => {
    return api.post<CompareLikeitUserResult>('import/user/compare', params, auth(token));
  };

  const updateUser = ({ token, id, ...params }: UpdateUserRequest) => {
    return api.put(`user/${id}`, params, auth(token));
  };

  const getCronStatus = ({ token }: { token: string }) => {
    return api.get<CronStatus[]>(`import/cronstatus`, {}, auth(token));
  };

  const updateCronStatus = ({ token, update }: { token: string; update: CronStatus }) => {
    return api.put<CronStatus>(`import/cronstatus`, update, auth(token));
  };

  const startNightlySync = ({ token }: { token: string }) => {
    return api.get(`import/manual-nightly-sync`, {}, auth(token));
  };

  const syncSingleUser = ({ token, ...params }: SyncSingleUserRequest) => {
    return api.get(`import/user/sync`, params, auth(token));
  };

  const getEmployee = ({ token, id }: GetEmployeeRequest) => {
    return api.get<Person>(`employee/staff/user/${id}`, {}, auth(token));
  };

  const patchEmployee = ({ id, token, employeePatch }: PatchEmployeeRequest) => {
    return api.patch<Person>(`employee/${id}`, employeePatch, auth(token));
  };

  const getEmployeeSuggestions = ({ token, ...params }: GetEmployeeSuggestionsRequest) => {
    return api.get<EmployeeSuggestionsResult>(`employee-suggestion/staff`, params, auth(token));
  };

  const getEmployeeSuggestionById = ({ token, id }: { id: number; token: string }) => {
    return api.get<EmployeeSuggestion>(`employee-suggestion/staff/${id}`, {}, auth(token));
  };

  const deleteEmployeeSuggestion = ({ token, id }: { id: number; token: string }) => {
    return api.delete<void>(`/employee-suggestion/${id}`, {}, auth(token));
  };

  const createOrUpdateEmployeeSuggestion = ({ token, ...params }: CreateOrUpdateEmployeeSuggestionRequest) => {
    return api.post<EmployeeSuggestion, any>(`employee-suggestion/save`, params, auth(token));
  };

  const updateEmployeeSuggestionStatus = ({ token, id, status }: { id: number; token: string; status: string }) => {
    return api.put<{ id: number; status: string }, any>(`employee-suggestion/${id}/status`, { status }, auth(token));
  };

  const getCustomerSuggestions = ({ token, ...params }: GetCustomerSuggestionsRequest) => {
    return api.get<CustomerSuggestionsResult>(`customer-suggestion/staff`, params, auth(token));
  };

  const getCustomerSuggestionById = ({ token, id }: GetCustomerSuggestionByIdRequest) => {
    return api.get<CustomerSuggestionItem>(`customer-suggestion/staff/${id}`, {}, auth(token));
  };

  const resendShiftSetToRecipients = (shiftSetId: number) => {
    return api.get<any>(`shift/set/${shiftSetId}/resend`, {});
  };

  const createOrUpdateCustomerSuggestion = ({ token, ...params }: CreateOrUpdateCustomerSuggestionRequest) => {
    return api.post(`customer-suggestion/staff`, params, auth(token));
  };

  const setMyTeam = (teamId: number) => {
    return api.put<any>('team/me', { teamId });
  };

  const healthCheck = () => {
    return api.get<{ message: string; version: string }>(`health-check`);
  };

  const listGuides = ({ token }: { token: string }) => {
    return api.get<ListGuidesResult, any>(`guide`, {}, auth(token));
  };

  const getGuide = ({ token, id }: { token: string; id: number }) => {
    return api.get<Guide, any>(`guide/${id}`, { type: 'MARKDOWN' }, auth(token));
  };

  const saveGuide = ({ token, id, title, content, type }: { token: string; id?: number; title: string; content: string; type: 'MARKDOWN' | 'HTML' }) => {
    if (id) {
      return api.put<Guide, any>(`guide/${id}`, { title, content, type }, auth(token));
    }
    return api.post<Guide, any>(`guide`, { title, content, type }, auth(token));
  };

  const deleteGuide = ({ token, id }: { token: string; id: number }) => {
    return api.delete<void>(`guide/${id}`, {}, auth(token));
  };

  const getPayPeriods = (params: { skip: number; take: number; groupId?: number; startDate?: Date; endDate?: Date }) => {
    return api.get<{ results: PayPeriod[]; total: number }>(`pay-period`, params);
  };

  const deletePayPeriod = ({ token, payPeriodId }: { token: string; payPeriodId: number }) => {
    return api.delete<void>(`pay-period/${payPeriodId}`, {}, auth(token));
  };

  const savePayPeriods = ({ token, payPeriods }: { token: string; payPeriods: PayPeriod[] }) => {
    return Promise.all(
      payPeriods.map(payPeriod => {
        if (payPeriod.id) {
          return api.put<PayPeriod, any>(`pay-period/${payPeriod.id}`, payPeriod, auth(token));
        }
        return api.post<PayPeriod, any>(`pay-period`, payPeriod, auth(token));
      })
    );
  };

  const getTwilioMessageStatus = ({ token, sid }: { token: string; sid: string }) => {
    return api.get<any>(`messages/log/twilio/${sid}`, {}, auth(token));
  };

  const getUsersByIds = (userIds: string) => api.get<IMinimalUser[]>(`user/usersByIds`, { userIds });
  // chat api
  const newChat = (params: INewChatParams) => api.post<ChatInfoResponse, any>(`/chat`, params);
  const archiveChat = (chatId: number) => api.delete<any>(`/chat/${chatId}`);
  const restoreChat = (chatId: number) => api.put<any>(`/chat/restore/${chatId}`);
  const listChats = (params: ISearchChatsParams) => api.get<ChatListResponse>('/chat/list', params);
  const getChatUsers = (chatId: number) => api.get<ChatInfoResponse>(`/chat/${chatId}/users`);
  const getChat = ({ chatId, ...props }: { chatId: number; gotoMessageId?: number; lastMessageId?: number; skip?: number; take?: number }) =>
    api.get<ChatInfoResponse>(`/chat/${chatId}`, props);
  const sendChatMessage = ({ chatId, messageFormData }: { chatId: number; messageFormData: FormData; recipientIds?: number[] }) =>
    api.post<ChatMessage>(`/chat/${chatId}/send`, messageFormData);
  const markMessageAsUnread = ({ messageId, chatId }: { chatId: number; messageId: number }) =>
    api.post<ChatMessage>(`/chat/markUnread`, { messageId, chatId });
  const joinChat = (chatId: number) => api.post<ChatInfoResponse>(`/chat/${chatId}/join`);
  const leaveChat = (chatId: number) => api.post<ChatInfoResponse>(`/chat/${chatId}/leave`);
  const chatAddUsersList = (params: IChatUserSearchParams) => api.get<ChatUserListResponse>(`/chat/addusers/list`, params);
  const chatAddGroupsList = (params: IChatGroupSearchParams) => api.get<ChatGroupListResponse>(`/chat/addpersongroups/list`, params);
  // team data admin
  const getTeams = () => api.get<ITeamsResponse>('/team');
  const updateTeamProps = (teamId: number, props: { name?: string }) => api.post<any>('/team', { teamId, ...props });
  const updateChat = (chatId: number, props: { title?: string }) => api.put<ChatInfoResponse, any>(`/chat/${chatId}`, { ...props });
  const debugRequest = (userId: number, props: { url: string }) => api.post<{ res: string; error?: string }, any>(`/auth/${userId}/debug`, { ...props });
  const getDebugJwt = (userId: number) => api.post<{ token: string; error?: string }, any>(`/auth/${userId}/debug-jwt`);
  //
  return {
    newChat,
    archiveChat,
    restoreChat,
    listChats,
    getTeams,
    updateTeamProps,
    getChat,
    getChatUsers,
    sendChatMessage,
    joinChat,
    leaveChat,
    chatAddUsersList,
    chatAddGroupsList,
    cancelReservation,
    compareLikeitUser,
    createOrUpdateCustomerSuggestion,
    createOrUpdateEmployeeSuggestion,
    createOrUpdateShiftSet,
    deleteShift,
    deletePayPeriod,
    deleteGuide,
    deleteShiftSet,
    deleteEmployeeSuggestion,
    getCronStatus,
    getCustomerSuggestions,
    getCustomerSuggestionById,
    getDepartments,
    getEmployee,
    getEmployeeGroups,
    getEmployeesOfGroup,
    getEmployeeSuggestionById,
    getEmployeeSuggestions,
    getGuide,
    getMe,
    getOrganizationsBy,
    getOrganizationTree,
    getPayPeriods,
    getReasons,
    getShift,
    getShifts,
    getShiftSet,
    getShiftSetForEditing,
    getTasks,
    getTasksByCustomerId,
    getTwilioMessageStatus,
    getUnits,
    getUsersByIds,
    hasReservations,
    healthCheck,
    listGuides,
    listShiftSetsByReservation,
    loadPrefs,
    login,
    logout,
    messageLog,
    organizationSearch,
    patchEmployee,
    reserveShiftForUser,
    resendShiftSetToRecipients,
    saveGuide,
    savePrefs,
    savePayPeriods,
    searchPersonGroup,
    sendMessage,
    sendPasscode,
    sendShiftsData,
    setMyTeam,
    startNightlySync,
    syncSingleUser,
    updateCronStatus,
    updateEmployeeSuggestionStatus,
    updateShiftSet,
    updateUser,
    updateChat,
    debugRequest,
    getDebugJwt,
    markMessageAsUnread,
  };
};

export const api = create();

import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { customerSuggestionStore } from '../../mobx/customerSuggestionStore';
import { Loader, Layout } from '../../components';

export const CreateOrUpdateCustomerSuggestion: FC = observer(() => {
  const { t } = useTranslation();
  const { customerSuggestionId } = useParams<{ customerSuggestionId: string }>();
  const { status, selectedItem } = customerSuggestionStore;
  useEffect(() => {
    if (status !== 'BUSY' && status !== 'ERROR' && !selectedItem) {
      if (customerSuggestionId) {
        customerSuggestionStore.fetchById(Number(customerSuggestionId));
      } else {
        // new
        customerSuggestionStore.createNew();
      }
    }
  });

  return (
    <Layout>
      {status === 'BUSY' && <Loader isFullScreen />}
      <h1 data-testid="customer-suggestion-edit-page-title">{t(customerSuggestionId ? 'edit-customer-suggestion' : 'create-customer-suggestion')}</h1>
      {selectedItem && (
        <>
          <h3>{t('general_info')}</h3>
          <pre>
            <h4>{selectedItem.organization?.name}</h4>
            <b>{t('address')}</b> {selectedItem.organization?.address}
            <b>{t('city')}</b> {selectedItem.organization?.city}
          </pre>
          {selectedItem.customerResponses && selectedItem.customerResponses.length > 0 && (
            <>
              <h3>{t('status')}</h3>
              {selectedItem.customerResponses.map(response => response.type).join(', ')}
            </>
          )}
          {selectedItem.receiverCustomerUser && (
            <>
              <h3>{t('contact_persons')}</h3>
              <pre>
                <b>{t('name')}</b> {selectedItem.receiverCustomerUser.firstName} {selectedItem.receiverCustomerUser.lastName}
                <b>{t('phone')}</b> {selectedItem.receiverCustomerUser.phone}
                <b>{t('email')}</b> {selectedItem.receiverCustomerUser.email}
              </pre>
            </>
          )}
        </>
      )}
    </Layout>
  );
});

import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Input } from '../../components/inputs';
import { Icon } from '../../components';

interface Props {
  phoneNumber: string;
  onPhoneNumberChange: (event: ChangeEvent<any>) => void;
  email: string;
  onEmailChange: (event: ChangeEvent<any>) => void;
  onSubmit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const SendPasscodeForm: FC<Props> = ({ phoneNumber, email, onPhoneNumberChange, onEmailChange, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <>
      <Input id="login-input-phone" value={phoneNumber} label={t('givePhoneNumber')} placeholder={t('inputPlaceholder.phoneNumber')} prepend={<Icon type="Phone" color="primary" />} onChange={onPhoneNumberChange} />
      <Input id="login-input-email" value={email} label={t('giveYourEmail')} placeholder={t('inputPlaceholder.email')} prepend={<Icon type="Message" color="primary" />} onChange={onEmailChange} />
      <ButtonGroup>
        <Button id="send-passcode-button" text={t('sendPasscode')} onClick={onSubmit} color="primary" disabled={phoneNumber.length <= 5 && !email.length} />
      </ButtonGroup>
    </>
  );
};

export default SendPasscodeForm;

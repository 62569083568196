import React, { Dispatch, FC, SetStateAction } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Input from './DatePickerInput';
import { Icon } from '../Icon';

const Container = styled.div`
  min-width: 160px;
  .react-datepicker {
    &__day--in-range {
      background-color: ${p => p.theme.colors.grayLighter};
      color: ${p => p.theme.colors.grayDarkest};
    }
    &__day--in-selecting-range {
      background-color: ${p => p.theme.colors.grayLighter};
      color: ${p => p.theme.colors.grayDarkest};
      :hover {
        background-color: ${p => p.theme.colors.primaryDarker};
        color: ${p => p.theme.colors.white};
      }
    }
    &__day--selected,
    &__day--keyboard-selected {
      background-color: ${p => p.theme.colors.primary};
      color: ${p => p.theme.colors.white};
    }
    &__close-icon {
      top: 14px;
      :after {
        background-color: transparent;
        color: ${p => p.theme.colors.grayDarkest};
      }
    }
  }
`;

interface Props {
  id: string;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  onChange?: (date: Date | [Date, Date] | null, event: React.SyntheticEvent<any, Event> | undefined) => void;
  setDate?: Dispatch<SetStateAction<Date | undefined>>;
  label?: string;
  placeholder?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  placement?: string;
  disabled?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  isClearable?: boolean;
  minDate?: Date;
  maxDate?: Date;
  maxTime?: Date;
}

export const DatePicker: FC<Props> = ({
  id,
  date,
  startDate,
  endDate,
  onChange,
  setDate,
  label,
  placeholder,
  showTimeSelect,
  showTimeSelectOnly,
  placement,
  disabled = false,
  selectsStart,
  selectsEnd,
  isClearable,
  minDate,
  maxDate,
  maxTime = new Date(new Date().setHours(23, 59, 59, 999)),
}) => {
  const { t } = useTranslation();

  const handleChange = (date: Date) => setDate && setDate(date);

  const format = showTimeSelectOnly ? 'H:mm' : showTimeSelect ? 'd.M.yyyy H:mm' : 'd.M.yyyy';

  const iconType = showTimeSelectOnly ? 'Clock' : showTimeSelect ? 'CalendarClock' : 'Calendar';

  const selectedValue = date ? date : selectsStart ? startDate : endDate;

  return (
    <Container>
      <ReactDatePicker
        id={id}
        dateFormat={format}
        disabled={disabled}
        selected={selectedValue}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange || handleChange}
        minDate={minDate}
        timeIntervals={15}
        timeCaption={t('time')}
        showTimeSelect={showTimeSelectOnly || showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        showWeekNumbers
        placeholderText={placeholder}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        isClearable={isClearable}
        calendarStartDay={1}
        timeFormat="HH:mm"
        customInput={<Input id={id} format={format} label={label} showTimeSelectOnly={showTimeSelectOnly} prepend={<Icon type={iconType} color="primary" />} />}
        maxDate={maxDate}
        minTime={new Date(new Date().setHours(0, 0, 0, 0))}
        maxTime={maxTime}
      />
    </Container>
  );
};

export default DatePicker;

import { action, makeAutoObservable } from 'mobx';

import { api } from '../services/api';
import { userStore } from './userStore';

type PayPeriodWithFlags = PayPeriod & { hasChanges?: boolean };

class AdminStore {
  status: 'IDLE' | 'BUSY' | 'FETCHED' | 'ERROR' | 'CHANGED' | 'DELETED' | 'SAVED' = 'IDLE';
  compareLikeitUserResult?: CompareLikeitUserResult;
  syncUserResult?: any;
  cronStatus?: CronStatus[];
  payPeriods?: PayPeriodWithFlags[];
  total: number = 0;
  page: number = 1;
  pages: number = 1;
  skip: number = 0;
  take: number = 100;
  constructor() {
    makeAutoObservable(this);
  }
  @action
  async getPayPeriods({ groupId, startDate, endDate, skip = 0, take = 100 }: { startDate?:Date, endDate?:Date, groupId?:number, skip: number; take: number }) {
    this.status = 'BUSY';
    const result = await api.getPayPeriods({ skip, take, groupId, startDate, endDate });
    if (result.ok && result.data) {
      this.payPeriods = result.data?.results.map((item: PayPeriod) => ({
        id: item.id,
        groupId: item.groupId,
        group: item.group,
        startDate: new Date(item.startDate),
        endDate: new Date(item.endDate),
        payDate: new Date(item.payDate),
      }));
      this.skip = skip;
      this.take = take;
      this.total = result.data.total;
      this.page = Math.ceil(skip / take) + 1;
      this.pages = Math.ceil(this.total / take);
      this.status = 'FETCHED';
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  changePayPeriod(payPeriod: PayPeriod) {
    if (!this.payPeriods) {
      this.status = 'ERROR';
      return;
    }
    this.payPeriods = this.payPeriods.map(item => {
      if (item.id === payPeriod.id) {
        this.status = 'CHANGED';
        return { ...payPeriod, hasChanges: true };
      }
      return item;
    });
  }
  @action
  async saveChangedPayPeriods() {
    const payPeriods: PayPeriod[] | undefined = this.payPeriods?.filter(item => item.hasChanges).map(({ hasChanges, ...rest }) => ({ ...rest }));
    if (!payPeriods || payPeriods.length === 0) {
      this.status = 'FETCHED';
      return;
    }
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.savePayPeriods({ token, payPeriods });
    if (result.length) {
      this.status = 'SAVED';
      this.getPayPeriods({ skip: this.skip, take: this.take });
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  async deletePayPeriod(payPeriodId: number) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.deletePayPeriod({ token, payPeriodId });
    if (result.ok) {
      this.status = 'DELETED';
      this.getPayPeriods({ skip: this.skip, take: this.take });
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  async compareLikeitUser(params: Partial<CompareLikeitUserRequest>) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.compareLikeitUser({ token, ...params });
    if (result.ok && result.data) {
      this.compareLikeitUserResult = result.data;
      this.status = 'FETCHED';
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  async syncSingleUser(params: { userId: number; likeitUserId: number }): Promise<any> {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.syncSingleUser({ token, ...params });
    if (result.ok) {
      this.syncUserResult = result.data;
      this.status = 'FETCHED';
      return result.data;
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  async getCronStatus() {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.getCronStatus({ token });
    if (result.ok) {
      this.cronStatus = result.data;
      this.status = 'FETCHED';
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  async updateCronStatus(cronstatus: CronStatus, date: Date): Promise<CronStatus | undefined | void> {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const update = {
      ...cronstatus,
      lastRunStartedAt: date.toISOString(),
      status: 'TWEAKED',
    };
    const result = await api.updateCronStatus({ token, update });
    if (result.ok) {
      this.status = 'FETCHED';
      this.getCronStatus();
      return result.data;
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  async nightlyNow() {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.startNightlySync({ token });
    if (result.ok) {
      this.status = 'FETCHED';
      this.getCronStatus();
    } else {
      this.status = 'ERROR';
    }
  }
}

export const adminStore = new AdminStore();

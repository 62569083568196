import React, { FC } from 'react';
import { EmployeeDataBaseProps } from '.';
import { Todo } from '../../components/Todo';

interface Props extends EmployeeDataBaseProps {}

export const WorkExperience: FC<Props> = ({ employee }) => {
  return (
    <div>
      <Todo todo="Työkokemus" noMargin />
    </div>
  );
};

export default WorkExperience;

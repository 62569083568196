import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;

  &.is-full-screen-loader {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: ${p => p.theme.colors.white};
    opacity: 0.7;
  }
`;

const Spinner = styled.div<{ size: number }>`
  display: inline;
  animation: rotating 1s linear infinite;
  border-radius: 50%;
  border: ${p => p.size / 10 + 2}px solid transparent;
  border-top: ${p => p.size / 10 + 2}px solid ${p => p.theme.colors.secondaryLightest};
  border-bottom: ${p => p.size / 10 + 2}px solid ${p => p.theme.colors.secondaryLightest};
  width: ${p => p.size}px;
  height: ${p => p.size}px;

  &.is-full-screen-loader {
    width: 80px;
    height: 80px;
    border-width: 14px;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface Props {
  isFullScreen?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const Loader: React.FC<Props> = ({ isFullScreen = false, size = 'md' }) => {
  const isFullScreenClass = isFullScreen ? 'is-full-screen-loader' : '';
  const spinnerSize = size === 'xs' ? 15 : size === 'sm' ? 30 : size === 'md' ? 50 : 80;

  return (
    <Container className={isFullScreenClass}>
      <Spinner className={isFullScreenClass} size={spinnerSize}></Spinner>&nbsp;
    </Container>
  );
};

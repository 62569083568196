import { EditorState, Modifier, RichUtils } from 'draft-js';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Editor, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';

import { ConfirmModal, Icon } from '../../components';
import { Input } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import { chatEventStore, CHATLIST_DEFAULT_PAGE_SIZE } from '../../mobx/chatEventStore';
import { path } from '../../routes/routes';
import notify from '../../services/notify';
import ChatMessage from './ChatMessage';
import {
  Container,
  GetPreviousMessagesButtonContainer,
  Header,
  InputContainer,
  MessagesContainer,
  ScrollToBottomButtonContainer,
} from './styles/ChatRoom.styles';

const ChatRoom: FC = observer(() => {
  const [editorState, setEditorState] = useState<EditorState>();
  const [fileAttachments, setFileAttachments] = useState<File[] | FileList | null>(null);
  const [showEditChatTitle, setShowEditChatTitle] = useState(false);
  const [chatTitle, setChatTitle] = useState<string | undefined>();
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [bottomRefIsVisible, setBottomRefIsVisible] = useState(false);
  const [showFileAttachmentsModal, setShowFileAttachmentsModal] = useState(false);
  const { currentChat, status, gotoMessageId } = chatEventStore;
  const { t } = useTranslation();

  const topRef = useRef<null | HTMLDivElement>(null);
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const foundMsgRef = useRef<null | HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // couldnt find proper type for editorRef
  const editorRef = useRef<any>(null);
  const isBusy = status === 'BUSY';
  const isArchived = currentChat?.status === 'archived';

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setBottomRefIsVisible(entry.isIntersecting)), [bottomRef]);

  useEffect(() => {
    if (bottomRef.current) observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    // scroll to the top when user is fetching older messages by pressing up arrow
    if (status === 'FETCHED OLDER MESSAGES') {
      setTimeout(() => topRef.current?.scrollIntoView(), 100);
    }
    // scroll to the bottom when user types a new own message
    if (status === 'FETCHED' && bottomRefIsVisible) {
      setTimeout(() => bottomRef.current?.scrollIntoView(), 100);
    }
    // scroll to a desired message when chatrooms have been searched by message content and entered to found chatroom
    if (chatEventStore.foundMessage.messageId && gotoMessageId) {
      setTimeout(() => foundMsgRef.current?.scrollIntoView(), 100);
    }
    // scroll to bottom when user normally enters a chatroom
    if (!chatEventStore.foundMessage.messageId && status === 'INITIAL FETCH') {
      setTimeout(() => bottomRef.current?.scrollIntoView(), 100);
    }
  }, [status]);

  useEffect(() => {
    editorRef.current.focusEditor();
  }, [editorRef]);

  useEffect(() => {
    setChatTitle(currentChat?.title);
  }, []);

  if (!currentChat) return null;

  const handleGetOlderMessages = () => {
    chatEventStore.getOlderMessages(currentChat.id);
  };

  const handleChangeChatTitle = () => {
    if (chatTitle === undefined || !chatEventStore.currentChat) return;
    chatEventStore.updateChat(chatEventStore.currentChat.id, chatTitle);
    setShowEditChatTitle(false);
  };

  const handleCancelEditChatTitle = () => {
    setShowEditChatTitle(false);
    setChatTitle(chatEventStore?.currentChat?.title);
  };

  // helper function for clearing the editor content
  const removeSelectedBlocksStyle = (editorState: EditorState) => {
    const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
    if (newContentState) {
      return EditorState.push(editorState, newContentState, 'change-block-type');
    }
    return editorState;
  };
  // helper function for clearing the editor content
  const getResetEditorState = (editorState: EditorState) => {
    const blocks = editorState.getCurrentContent().getBlockMap().toList();
    const updatedSelection = editorState.getSelection().merge({
      anchorKey: blocks.first().get('key'),
      anchorOffset: 0,
      focusKey: blocks.last().get('key'),
      focusOffset: blocks.last().getLength(),
    });
    const newContentState = Modifier.removeRange(editorState.getCurrentContent(), updatedSelection, 'forward');
    const newState = EditorState.push(editorState, newContentState, 'remove-range');
    return removeSelectedBlocksStyle(newState);
  };

  const handleSendMessage = (e: SyntheticKeyboardEvent): boolean => {
    if (editorState === undefined) return false;
    if (!e.getModifierState('Shift')) {
      const messageText = editorState.getCurrentContent().getPlainText();
      const messageFormData = new FormData();

      // if message is only spaces and newlines then dont send it
      if (/^[\s\n]*$/.test(messageText) && !fileAttachments) {
        return false;
      }

      if (fileAttachments) {
        for (let i = 0; i < fileAttachments.length; i++) {
          messageFormData.append('file[]', fileAttachments[i]);
        }
        setFileAttachments(null);
      }

      // if beginning of message has only spaces and newlines, remove them
      const regex = /^[\s\n]*/;
      const cleanedMessage = regex.test(messageText) ? messageText.replace(regex, '') : messageText;

      messageFormData.append('message', cleanedMessage);

      chatEventStore.sendMessage(currentChat.id, messageFormData);

      setEditorState(getResetEditorState(editorState));
      // scroll to bottom if user has scrolled up
      if (!bottomRefIsVisible) {
        bottomRef.current?.scrollIntoView();
      }
      return true;
    }
    return false;
  };

  const handleArchiveOrRestoreChatroom = async () => {
    if (!chatEventStore.currentChat) return;
    switch (isArchived) {
      case true:
        chatEventStore.restoreChat(chatEventStore?.currentChat?.id);
        notify.success('chat restored');
        break;
      case false:
        await chatEventStore.archiveChat(chatEventStore?.currentChat?.id);
        notify.success('chat archived');
        window.location.replace(`/${path('chat')}`);
        break;
    }

    setShowArchiveModal(false);
  };

  const handleGetNewestMessages = () => {
    chatEventStore.getChat({
      chatId: currentChat.id,
      scrollToBottom: true,
      params: { skip: 0, take: CHATLIST_DEFAULT_PAGE_SIZE },
      select: true,
    });
    chatEventStore.getOlderMessages(currentChat.id);
    bottomRef.current?.scrollIntoView();
  };

  const handleAddAFileAttachments = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileList = Array.from(event.target.files);
      const joinedFileList = fileAttachments ? fileList.concat(Array.from(fileAttachments)) : Array.from(fileList);
      const FileListWithoutDuplicates = joinedFileList.filter((item, index, self) => {
        return !self.slice(index + 1).some(other => other.name === item.name && other.size === item.size);
      });

      // const fileList = fileAttachments !== null ? Array.from(fileAttachments).concat(Array.from(event.target.files)) : event.target.files;
      setFileAttachments(FileListWithoutDuplicates);
    }
  };

  const handleFileAttachmentButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleOnClickFileAttachments = (event: React.MouseEvent<HTMLElement>) => {
    const eventTarget = event.target as HTMLInputElement;
    eventTarget.value = '';
  };

  return (
    <Container>
      {!showEditChatTitle ? (
        <Header>
          {currentChat.title}
          <Button
            color="gray"
            variant="link"
            isSmall
            icon={<Icon type="Edit" />}
            id="button-edit-chat-title"
            onClick={() => setShowEditChatTitle(!showEditChatTitle)}
            disabled={isBusy}
          />
          <Button
            isSmall
            text={isArchived ? t('restore') : t('archive')}
            variant="mini"
            id="button-fetch-all"
            onClick={() => setShowArchiveModal(!showArchiveModal)}
            disabled={isBusy}
          />
          {isArchived && <div className="archived-text">{t('archived')}</div>}
        </Header>
      ) : (
        <Header>
          <Input id="input-new-chat-title" value={chatTitle} onChange={e => setChatTitle(e.target.value)} />
          <Button color="black" variant="mini" isSmall text={t('accept')} id="button-edit-chat-title" onClick={handleChangeChatTitle} disabled={isBusy} />
          <Button color="black" variant="mini" isSmall text={t('cancel')} id="button-edit-chat-title" onClick={handleCancelEditChatTitle} disabled={isBusy} />
        </Header>
      )}

      <MessagesContainer>
        <GetPreviousMessagesButtonContainer>
          <Button
            color="grayLighter"
            variant="link"
            isSmall
            icon={<Icon type="ArrowUp" />}
            id="button-get-older-messages"
            onClick={handleGetOlderMessages}
            disabled={isBusy}
          />
        </GetPreviousMessagesButtonContainer>
        <div ref={topRef} />
        {currentChat.messages.map((msg: ChatMessage, index: number) => {
          const messageFoundWithSearch = chatEventStore.foundMessage.messageId === msg.id && chatEventStore.foundMessage.roomId === currentChat.id;

          return messageFoundWithSearch ? (
            <div ref={foundMsgRef} key={`${index}-${msg.id}`}>
              <ChatMessage chatRoomId={currentChat.id} msg={msg} messageFoundWithSearch={messageFoundWithSearch} />
            </div>
          ) : (
            <ChatMessage chatRoomId={currentChat.id} msg={msg} key={`${index}-${msg.id}`} messageFoundWithSearch={messageFoundWithSearch} />
          );
        })}
        <div ref={bottomRef} />
      </MessagesContainer>
      <ScrollToBottomButtonContainer>
        <Button color="grayLighter" variant="link" isSmall icon={<Icon type="ArrowDown" />} id="button-get-newer-messages" onClick={handleGetNewestMessages} />
      </ScrollToBottomButtonContainer>
      <InputContainer>
        <Editor
          ref={editorRef}
          toolbar={{ options: ['emoji'] }}
          handleReturn={handleSendMessage}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName="wrapperClass"
          editorClassName="editorClass"
          toolbarClassName="toolbarClass"
        />

        <div className="file-attachments-container">
          <>
            <Button id="add-file-attachments" onClick={handleFileAttachmentButtonClick} variant="mini" color="black" text={t('add attachments')} isSmall />
            <input
              type="file"
              multiple
              ref={fileInputRef}
              style={{ display: 'none' }}
              onInputCapture={handleAddAFileAttachments}
              onClick={handleOnClickFileAttachments}
              accept=".png,.jpeg,.jpg,.gif,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
            />
          </>
          <Button
            id="show-file-attachments"
            onClick={() => setShowFileAttachmentsModal(true)}
            variant="mini"
            color="black"
            text={t('show attachments')}
            isSmall
            disabled={!fileAttachments}
          />
        </div>
      </InputContainer>

      {showFileAttachmentsModal && (
        <ConfirmModal onConfirm={() => setShowFileAttachmentsModal(false)} onHide={() => setShowFileAttachmentsModal(false)} confirmText={t('close')}>
          {t('file attachments')}:
          {fileAttachments?.length && (
            <div>
              {Object.entries(fileAttachments).map((file, index) => {
                return <div key={`${index}-${file[1].name}`}>{file[1].name} </div>;
              })}
              <Button id="add-file-attachments" onClick={() => setFileAttachments(null)} variant="mini" color="black" text={t('remove attachments')} isSmall />
            </div>
          )}
        </ConfirmModal>
      )}

      {showArchiveModal && (
        <ConfirmModal
          onConfirm={handleArchiveOrRestoreChatroom}
          onHide={() => setShowArchiveModal(!showArchiveModal)}
          confirmText={t('accept')}
          cancelText={t('cancel')}
        >
          {isArchived ? <div>{t('restore chat')}</div> : <div>{t('archive chat')}</div>}
        </ConfirmModal>
      )}
    </Container>
  );
});

export default ChatRoom;

import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { Button } from './inputs';

const ModalFooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${p => p.theme.breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ConfirmModal: FC<{
  bodyText?: string;
  cancelText?: string;
  confirmText?: string;
  onHide: Function;
  onConfirm: Function;
  children?: React.ReactNode;
}> = ({ bodyText, children, cancelText, confirmText, onHide, onConfirm }) => {
  return (
    <Modal show={true} onHide={() => onHide()}>
      <Modal.Body>
        {bodyText}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <ModalFooterButtons>
          {cancelText && <Button id="close-button" onClick={() => onHide()} text={cancelText} color="secondary" disabled={false} />}
          {confirmText && <Button id="confirm-button" type="submit" text={confirmText} onClick={() => onConfirm()} color="primary" />}
        </ModalFooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

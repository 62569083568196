import styled from 'styled-components';

export const Sect = styled.div`
  display: flex;
  flex-direction: column;

  &.status {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    background-color: ${p => p.theme.colors.grayLightest};
    padding: ${p => p.theme.spacings.sm};
    margin-top: ${p => p.theme.spacings.md};
    margin-right: ${p => p.theme.spacings.sm};
    border: 1px dashed grey;
  }

  a {
    display: flex;
    justify-content: center !important;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${p => p.theme.spacings.xl} 0;
  > * {
    max-width: 25%;
    :not(:last-child) {
      margin-right: ${p => p.theme.spacings.lg};
    }
  }
  @media ${p => p.theme.breakpoints.mobile} {
    display: block;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${p => p.theme.spacings.lg} 0;
  max-width: 400px;
`;

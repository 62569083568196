import { action, makeAutoObservable } from 'mobx';
import { userStore } from './userStore';
import { api } from '../services/api';
import config from '../services/api/config';
import { SingleValue } from 'react-select';

class OrgStore {
  status: 'IDLE' | 'BUSY' | 'INITIAL FETCH' | 'FETCHED' | 'ERROR' | 'SELECTED' = 'IDLE';
  error?: any;
  customerTasks?: GetTasksByCustomerIdResponse & { CustomerId: number };
  organization?: Organization;
  organizationTree?: GetOrgTreeResponse;
  options: { [name: string]: SelectOption<number>[] } = {};
  selectedOptions: { [key: string]: SingleValue<SelectOption<number | undefined>> } = {};
  constructor() {
    makeAutoObservable(this);
  }
  get organizationDefaultContactPerson(): { name: string; phone?: string } | undefined {
    const person1 = this.organization?.contactPersons?.[0];
    if (person1) {
      return {
        name: `${person1.firstName} ${person1.lastName}`,
        phone: person1.phone,
      };
    }
    return undefined;
  }
  @action
  async getTasksByCustomerId(customerId: number) {
    const { token } = userStore;
    if (!token || !customerId) {
      this.status = 'ERROR';
      return;
    }
    if (customerId === -1) {
      return;
    }
    const result = await api.getTasksByCustomerId({ token, customerId });
    if (result.ok && result.data) {
      this.customerTasks = { ...result.data, CustomerId: customerId };
      this.status = 'FETCHED';
      return result.data;
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
    }
  }
  @action
  async treeSearch(params: { Id?: number; SellerPersonId?: number; ParentOrganizationId?: number; Name_CONTAINS?: string; populate?: boolean }, initial?: boolean): Promise<GetOrgTreeResponse | void> {
    const { token, user } = userStore;
    if (!token || !user) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    // don't filter with sellerperson Id if it's the initial call
    const SellerPersonId = initial ? undefined : user.teamId || config.defaultLikeItSellerPersonId;
    const result = await api.getOrganizationTree({ token, ...params, Page: 1, PageSize: initial ? 1 : 10, SellerPersonId });
    if (result.ok && result.data) {
      this.error = undefined;
      this.organizationTree = result.data;
      this.status = initial ? 'INITIAL FETCH' : 'FETCHED';
      return result.data;
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
    }
  }
  @action
  async organizationSearch(params: Omit<GetOrgOptionsRequest, 'token' | 'likeitSellerPersonId'>, name?: string): Promise<{ [name: string]: SelectOption<number>[] }> {
    const { token, user } = userStore;
    if (!token || !user) {
      this.status = 'ERROR';
    } else {
      const likeitSellerPersonId = user.teamId || config.defaultLikeItSellerPersonId;
      this.status = 'BUSY';
      const result = await api.organizationSearch({ token, likeitSellerPersonId, ...params });
      if (result.ok && result.data) {
        this.status = 'FETCHED';
        const { data } = result;
        Object.keys(data).forEach((k: string) => {
          switch (k) {
            case 'employees':
            case 'groups':
              this.options[k] = data[k].map(({ name, id }: any) => ({ label: name, value: id }));
              break;
            case 'units':
            case 'departments':
              this.options[k] = data[k].map(({ name, id, pid }: any) => ({
                label: name,
                value: id,
                pid,
              }));
              break;
            case 'tasks':
              const { departmentId, unitId } = params;
              if (departmentId || unitId) {
                this.options.tasks = data.tasks.filter(({ pid }: any) => pid && (pid === unitId || pid === departmentId)).map(({ name, id, pid }: any) => ({ label: name, value: id, pid }));
              } else {
                this.options.tasks = [];
              }
              break;
          }
        });
      } else {
        this.error = result.originalError;
        this.status = 'ERROR';
      }
    }
    return this.options;
  }
  @action
  select(key: string, value: any) {
    this.selectedOptions[key] = value;
    this.status = 'SELECTED';
    if (typeof value === 'number') this.getById(value);
  }
  @action
  async getById(likeitId: number) {
    const { token } = userStore;
    if (!token || !likeitId) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.getOrganizationsBy({ token, likeitId });
    if (result.ok && result.data) {
      this.error = undefined;
      this.organization = result.data.results[0];
      this.status = 'FETCHED';
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
    }
  }
}

export const orgStore = new OrgStore();

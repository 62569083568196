import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { chatEventStore } from './mobx/chatEventStore';
import { userStore } from './mobx/userStore';
import AppRouter from './routes/AppRouter';
import { NotificationContainer, notificationOptions } from './services/notify';
import { theme } from './theme/theme';

const App = observer(() => {
  useEffect(() => {
    if (userStore.user) {
      chatEventStore.open(); // start chat event listener (automatically fetches chat list)
      return () => chatEventStore.close(); // close chat event listener on unmount}
    }
  }, [userStore.user]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationContainer {...notificationOptions} />
      <AppRouter />
    </ThemeProvider>
  );
});

export default App;

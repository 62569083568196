import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../components/Icon';
import { Button, ButtonGroup, Input } from '../../components/inputs';

interface Props {
  passcode: string;
  onPasscodeChange: (event: ChangeEvent<any>) => void;
  onSubmit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const LoginForm: FC<Props> = ({ passcode, onPasscodeChange, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  return (
    <>
      <Input id="login-input-passcode" value={passcode} label={t('givePasscode')} type="password" prepend={<Icon type="Lock" color="primary" />} onChange={onPasscodeChange} />
      <ButtonGroup>
        <Button id="login-button" text={t('login')} onClick={onSubmit} color="primary" />
        <Button id="cancel-login-button" text={t('cancel')} onClick={onCancel} variant="link" color="primary" />
      </ButtonGroup>
    </>
  );
};

export default LoginForm;

import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { userStore } from '../../mobx/userStore';
import { dateToString } from '../../utils/date';
import { Icon } from '../../components';
import { Dropdown } from 'react-bootstrap';
import { messageStore } from '../../mobx/messageStore';
import notify from '../../services/notify';
import { path } from '../../routes/routes';
import { chatEventStore } from '../../mobx/chatEventStore';
import { useHistory } from 'react-router-dom';

const MessageRow = styled.div<{ messageSentByMe: boolean }>`
  display: flex;
  justify-content: ${p => (p.messageSentByMe ? 'flex-end' : 'flex-start')};
`;

const SameRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const IconButton = styled.button``;

const MessageContainer = styled.div<{ messageSentByMe: boolean; messageFoundWithSearch: boolean }>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 60vw;
  margin-bottom: 6px;
  padding: 20px 20px 12px;
  background-color: ${p => (p.messageSentByMe ? '#a3cde5' : '#eaeff3')};
  border: ${p => (p.messageFoundWithSearch ? '3px solid black' : '1px solid rgba(0, 0, 0, 0.05)')};
  border-radius: ${p => (p.messageSentByMe ? '24px 24px 0px 24px' : '0px 24px 24px 24px')};
  word-wrap: break-word;
`;

const MessageContextMenuButton = styled.button`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: none;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.33, 1, 0.68, 1);

  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  ${MessageContainer}:hover & {
    opacity: 1;
  }
`;

const AuthorName = styled.div`
  font-size: 14px;
  color: #676974;
`;

const MessageText = styled.div`
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 50px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const TimeContainer = styled.div`
  display: flex;
  justify-content: flex-end !important;
`;

const MessageCreatedAt = styled.div`
  display: flex;
  align-text: right;
  color: #676974;
  font-size: ${p => p.theme.fonts.size.sm};
`;

const FileAttachments = styled.div`
  margin-top: ${p => p.theme.spacings.lg};
  margin-bottom: ${p => p.theme.spacings.lg};
  padding: ${p => p.theme.spacings.lg};
  border: 1px dotted;
  border-radius: ${p => p.theme.borderRadius[0]};

  a {
    line-height: 200%;
  }

  .header {
    color: #676974;
    font-size: ${p => p.theme.fonts.size.sm};
  }
`;
const renderTime = (createdAt: string | Date) => {
  return <>{`${dateToString(createdAt, 'H:mm d.M.')}`} </>;
};

interface ChatMessageProps {
  msg: ChatMessage;
  messageFoundWithSearch: boolean;
  chatRoomId: number | undefined;
}

const ChatMessage = observer(({ msg, messageFoundWithSearch, chatRoomId }: ChatMessageProps) => {
  const { user } = userStore;
  const { markMessageAsUnread } = messageStore;
  const { leaveChat } = chatEventStore;

  const history = useHistory();
  const { t } = useTranslation();
  const messageSentByMe = (user && msg && msg.user && Boolean(user?.id === msg?.user?.id)) ?? false;

  const formatLinks = (text: string) => {
    const urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        const partIncludesHTTPS = part.includes('http');
        const href = partIncludesHTTPS ? part : `https://${part}`;
        return (
          <a key={`${index}-${part}`} href={href} target="_blank">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  async function handleSetUnread() {
    if (!chatRoomId) return console.log('No chat room id');

    const ok = await markMessageAsUnread(msg.id, chatRoomId);

    if (ok) {
      location.replace(`/${path('chat')}?markedUnread=true`);
    } else {
      notify.error(t('message marked unread error'));
    }
  }

  return (
    <div>
      <MessageRow messageSentByMe={messageSentByMe}>
        <MessageContainer messageSentByMe={messageSentByMe} messageFoundWithSearch={messageFoundWithSearch}>
          <SameRow>
            <AuthorName>{messageSentByMe ? t('you') : msg?.user?.name}</AuthorName>

            <Dropdown>
              <Dropdown.Toggle as={MessageContextMenuButton}>
                <Icon type="ArrowDown" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleSetUnread}>{t('message mark unread')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </SameRow>

          <MessageText>{msg.message && formatLinks(msg?.message)}</MessageText>
          {msg.files && msg.files?.length > 0 && (
            <FileAttachments>
              <div className="header">{t('file attachments')}:</div>
              {msg.files.map((file: IChatMessageFileAttachments, index: number) => {
                return (
                  <div key={`${index}-${file.id}`}>
                    <a href={file.fileLink} style={{ cursor: 'pointer' }} target="_blank">
                      {file.fileName}
                    </a>
                  </div>
                );
              })}
            </FileAttachments>
          )}
          <TimeContainer>
            <MessageCreatedAt>{renderTime(msg?.createdAt)}</MessageCreatedAt>
          </TimeContainer>
        </MessageContainer>
      </MessageRow>
    </div>
  );
});

export default ChatMessage;

import React, { FC } from 'react';
import { EmployeeDataBaseProps } from '.';
import { Todo } from '../../components/Todo';

interface Props extends EmployeeDataBaseProps {}

export const Groups: FC<Props> = ({ employee }) => {
  return (
    <div>
      <Todo todo="Ryhmät" noMargin />
    </div>
  );
};

export default Groups;

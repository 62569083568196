import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { appStore } from '../../mobx/appStore';
import { navBarRoutes, NavLinkRoute } from '../../routes/routes';
import { Icon } from '../Icon';
import { useTranslation } from 'react-i18next';

const WIDTH_CLOSED = 50;
const WIDTH_OPEN = 200;

// removed from Container &.nav-is-open:  transform: translateX(${WIDTH_OPEN - WIDTH_CLOSED}px);

const Container = styled.div`
  position: fixed;
  top: 0;
  margin-top: ${p => p.theme.spacings.headerHeight};
  width: ${WIDTH_CLOSED}px;
  height: 100%;
  z-index: 1;
  background: ${p => p.theme.navBar.bg};
  transition: width 200ms;

  &.nav-is-open {
    width: ${WIDTH_OPEN}px;

    & + .main-content-area {
      max-width: 100%;
      opacity: 0.4;
    }

    .main-nav {
      span {
        text-indent: 15px;
      }
    }
  }
`;

const Nav = styled.nav`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    opacity: 0.7;
    position: relative;
    clear: both;
    width: 100%;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: ${p => p.theme.colors.secondaryLighter};
    }
    &:hover,
    &.is-active {
      opacity: 1;
    }
    &.is-active {
      a {
        font-weight: bold;
      }
    }
  }

  a {
    display: flex;
    padding: 15px ${p => p.theme.spacings.md};
    align-items: center;
    width: 100%;

    font-family: ${p => p.theme.fonts.secondary};
    font-weight: 500;
    color: ${p => p.theme.navBar.link};
    text-decoration: none;
    user-select: none;

    span {
      position: relative;
      display: block;
      z-index: 0;
      text-indent: -20em;
      white-space: nowrap;
      transition: text-indent 300ms ease-in-out;
    }

    svg {
      position: relative;
      max-width: 80px;
      max-height: 30px;
      margin-left: 5px;
      margin-right: 5px;
      z-index: 1;
    }
  }
`;

const SeparatorLine = styled.div`
  border-top: 1px solid ${p => p.theme.colors.secondaryLightest};
  opacity: 0.5;
  margin: 0 ${p => p.theme.spacings.lg};
`;

const ContentArea = styled.div`
  min-height: 100%;
  margin-left: ${WIDTH_CLOSED}px;
  margin-top: ${p => p.theme.spacings.headerHeight};
  position: relative;
  transition: transform 200ms, opacity 200ms;
`;
interface NavItemProps extends NavLinkRoute {
  isActive: boolean;
}
const NavItem: FC<NavItemProps> = ({ path, icon, title, isActive }) => {
  const { t } = useTranslation();

  return (
    <li className={isActive ? 'is-active' : ''}>
      <NavLink to={path}>
        {icon && <Icon type={icon} color="white" />}
        <span>{t(title)}</span>
      </NavLink>
    </li>
  );
};

const NavBar: FC<{ children: ReactNode }> = observer(({ children: pageContent }) => {
  const { isNavOpen } = appStore;
  const toggleNav = (isOpen: boolean) => () => appStore.setIsNavOpen(isOpen);

  return (
    <>
      <Container className={isNavOpen ? 'nav-is-open' : ''} onMouseEnter={toggleNav(true)} onMouseLeave={toggleNav(false)} onTouchStart={toggleNav(true)}>
        <Nav className="main-nav">
          <ul>
            {navBarRoutes.map((route, i) => (
              <div key={route.path}>
                <NavItem {...route} isActive={window.location.pathname === route.path} />
                {i < navBarRoutes.length - 1 && <SeparatorLine />}
              </div>
            ))}
          </ul>
        </Nav>
      </Container>

      <ContentArea className="main-content-area" onTouchStart={toggleNav(false)}>
        {pageContent}
      </ContentArea>
    </>
  );
});

export default NavBar;

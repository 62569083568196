import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Icon } from '../../components/Icon';
import { guideStore } from '../../mobx/guideStore';
import Table, { ColumnType, ItemActionProps } from '../../components/Table';
import { path } from '../../routes/routes';
import { Pagination, Loader, Toolbar } from '../../components';
import { LinkButton } from '../../components/inputs';

export const ListGuides: FC = observer(() => {
  const { t } = useTranslation();
  const { status, results, total } = guideStore;

  const makeLink = (guide: Omit<Guide, 'content'>) => (guide.id ? `${path('guide')}/${guide.id}` : undefined);

  const tableColumns: ColumnType<Omit<Guide, 'content'>>[] = [{ key: 'id' }, { key: 'title' }, { key: 'createdAt' }, { key: 'updatedAt' }];

  const tableRowActions: ItemActionProps<Omit<Guide, 'content'>>[] = [{ label: t('edit'), icon: <Icon type="Edit" color="primary" />, makeLink }];

  const renderToolbar = () => (
    <Toolbar>
      <LinkButton href={`${path('guide')}/${path('new')}`} color="primary">
        <Icon type="Add" color="white" />
        {t('create-guide')}
      </LinkButton>
    </Toolbar>
  );

  useEffect(() => {
    if (status === 'IDLE' || (status !== 'BUSY' && !results)) {
      guideStore.list();
    }
  });
  return (
    <>
      {status === 'BUSY' && <Loader isFullScreen />}
      {renderToolbar()}
      {results && <Table<Omit<Guide, 'content'>> data={results} columns={tableColumns} itemActions={tableRowActions} linkRow={makeLink} />}
    </>
  );
});

import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { customerSuggestionStore } from '../../mobx/customerSuggestionStore';
import { path } from '../../routes/routes';
import { Loader, Layout, Toolbar, Icon, Pagination, SearchListContainer } from '../../components';
import { LinkButton } from '../../components/inputs';
import Table, { ColumnType, ItemActionProps } from '../../components/Table';
import SearchOrgForm, { SearchOrgFormParams } from '../../components/SearchOrgForm';

export const ListCustomerSuggestions: FC = observer(() => {
  const { t } = useTranslation();
  const { status, items, total, page, pages } = customerSuggestionStore;

  const subTableColumns: ColumnType<CustomerSuggestionItem>[] = [
    { key: 'id' },
    { key: 'status' },
    { key: 'updatedAt' },
    {
      key: 'organization',
      customRender: ({ organization: { name } }: CustomerSuggestionItem) => name,
    },
    {
      key: 'receiverCustomerUser',
      label: 'reciever',
      customRender: ({ receiverCustomerUser: { firstName, lastName, email } }: CustomerSuggestionItem) => `${firstName} ${lastName} (${email})`,
    },
    {
      key: 'customerResponses',
      label: 'response',
      customRender: ({ customerResponses }: CustomerSuggestionItem) => (customerResponses.length === 0 ? '-' : customerResponses.map(r => r.type).join(',')),
    },
  ];
  const subTableRowActions: ItemActionProps<CustomerSuggestionItem>[] = [
    {
      label: t('edit'),
      icon: <Icon type="Edit" color="primary" />,
      makeLink: (suggestion: CustomerSuggestionItem) => suggestion.id && `${path('customer-suggestion-edit')}/${suggestion.id}`,
    },
  ];

  const tableColumns: ColumnType<CustomerSuggestion>[] = [
    {
      key: 'employee',
      customRender: ({ employee: { firstName, lastName, email } }: CustomerSuggestion) => `${firstName} ${lastName}`,
    },
    {
      key: 'employee',
      label: 'email',
      customRender: ({ employee: { email } }: CustomerSuggestion) => email,
    },
    {
      key: 'suggestions',
      customRender: ({ suggestions }: CustomerSuggestion) => <Table<CustomerSuggestionItem> columns={subTableColumns} itemActions={subTableRowActions} data={suggestions} />,
    },
  ];

  useEffect(() => {
    if (status !== 'BUSY' && status !== 'ERROR' && !items) {
      customerSuggestionStore.list({ skip: 0, take: 10, status: 'open' });
    }
  }, [status]);
  const handlePageChange = (page: number) => {
    customerSuggestionStore.list({ skip: (page - 1) * 10, take: 10, status: 'open' });
  };
  const handleSearch = (params: SearchOrgFormParams) => {};
  const renderToolbar = () => (
    <Toolbar>
      <LinkButton href={path('customer-suggestion-edit')} color="primary">
        <Icon type="Add" color="white" />
        {t('createNewCustomerSuggestion')}
      </LinkButton>
      <Pagination total={total} pageCount={pages} page={page} onPageChange={handlePageChange} />
    </Toolbar>
  );

  return (
    <Layout>
      {status === 'BUSY' && <Loader isFullScreen />}
      <h1 data-testid="customer-suggestions-page-title">{t('customer-suggestions')}</h1>
      <SearchListContainer>
        <div className="secondary-column">
          <SearchOrgForm onSubmit={handleSearch} exportCheckbox={false} />
        </div>
        <div className="main-column">
          {renderToolbar()}
          {items && <Table<CustomerSuggestion> columns={tableColumns} data={items} />}
          {items && items.length && renderToolbar()}
        </div>
      </SearchListContainer>
    </Layout>
  );
});

import React from 'react';

import { IconType } from '../components/Icon';
import i18n from '../i18n';
import {
  Admin,
  Chat,
  CreateOrUpdateEmployeeSuggestion,
  CreateOrUpdateShiftSet,
  EmployeeData,
  Guide,
  Home,
  ListEmployeeSuggestions,
  ListShifts,
  MessageLogs,
  Messages,
  MyData,
  ViewEmployee,
} from '../views';
import {
  BasicData,
  Education,
  EmployeeDataBaseProps,
  Groups,
  Notifications,
  PersonalData,
  WorkExperience,
} from '../views/EmployeeData';
import Login from '../views/Login';

export interface Route {
  path: string;
  title?: string;
  component: React.FC | React.FC<any>;
  exact: boolean;
}

export interface NavLinkRoute extends Route {
  title: string;
  icon?: IconType;
}

interface EmployeeDataRoute {
  path: string;
  title: string;
  renderComponent: (props: any) => JSX.Element;
  exact: boolean;
}

// Helpers for route-related translations:
export const rt = (routeKey: string) => `route.${routeKey}`;
export const path = (routeKey: string) => i18n.t(`route.${routeKey}`).toLowerCase().replace(/\s/g, '-').replace(/[äå]/g, 'a').replace(/[ö]/g, 'o');

export const navBarRoutes: NavLinkRoute[] = [
  {
    path: `/${path('home')}`,
    title: rt('home'),
    component: Home,
    icon: 'Home',
    exact: true,
  },
  {
    path: `/${path('shifts')}`,
    title: rt('shifts'),
    component: ListShifts,
    icon: 'CalendarClock',
    exact: true,
  },
  {
    path: `/${path('employee-suggestions')}`,
    title: rt('employee-suggestions'),
    component: ListEmployeeSuggestions,
    icon: 'Person',
    exact: true,
  },
  {
    path: `/${path('chat')}`,
    title: rt('chat'),
    component: Chat,
    icon: 'Chat',
    exact: true,
  },
  {
    path: `/${path('messages')}`,
    title: rt('messages'),
    component: Messages,
    icon: 'Message',
    exact: true,
  },
  {
    path: `/${path('admin')}`,
    title: rt('admin'),
    component: Admin.Main,
    icon: 'BarChart',
    exact: true,
  },
  {
    path: `/${path('guide')}`,
    title: rt('guide'),
    component: Guide.Main,
    icon: 'Add',
    exact: true,
  },
];

export const userRoutes: NavLinkRoute[] = [
  {
    path: `/${path('user profile')}`,
    title: rt('user profile'),
    component: MyData,
    icon: 'Edit',
    exact: true,
  },
];

export const loginRoutes: Route[] = [{ path: `/${path('login')}`, component: Login, exact: true }];

export const employeeDataBaseRoute: Route = {
  path: `/${path('employee')}/:id`,
  component: EmployeeData,
  exact: true,
};

export const employeeDataRoutes: Route[] = [
  {
    path: `${employeeDataBaseRoute.path}/${path('basicData')}`,
    title: rt('basicData'),
    component: (props: EmployeeDataBaseProps) => <BasicData {...props} />,
    exact: true,
  },
  {
    path: `${employeeDataBaseRoute.path}/${path('groups')}`,
    title: rt('groups'),
    component: (props: EmployeeDataBaseProps) => <Groups {...props} />,
    exact: true,
  },
  {
    path: `${employeeDataBaseRoute.path}/${path('personalData')}`,
    title: rt('personalData'),
    component: (props: EmployeeDataBaseProps) => <PersonalData {...props} />,
    exact: true,
  },
  {
    path: `${employeeDataBaseRoute.path}/${path('education')}`,
    title: rt('education'),
    component: (props: EmployeeDataBaseProps) => <Education {...props} />,
    exact: true,
  },
  {
    path: `${employeeDataBaseRoute.path}/${path('workExperience')}`,
    title: rt('workExperience'),
    component: (props: EmployeeDataBaseProps) => <WorkExperience {...props} />,
    exact: true,
  },
  {
    path: `${employeeDataBaseRoute.path}/${path('notifications')}`,
    title: rt('notifications'),
    component: (props: EmployeeDataBaseProps) => <Notifications {...props} />,
    exact: true,
  },
];

export const otherRoutes: Route[] = [
  {
    path: `/${path('editShiftSet')}/:shiftSetId/:shiftId`,
    component: CreateOrUpdateShiftSet,
    exact: true,
  },
  {
    path: `/${path('view-employee')}/:employeeId`,
    title: rt('view-employee'),
    component: ViewEmployee,
    exact: true,
  },
  {
    path: `/${path('employee-suggestion-edit')}`,
    title: rt('create-employee-suggestion'),
    component: CreateOrUpdateEmployeeSuggestion,
    exact: true,
  },
  {
    path: `/${path('employee-suggestion-edit')}/:employeeSuggestionId`,
    title: rt('edit-employee-suggestion'),
    component: CreateOrUpdateEmployeeSuggestion,
    exact: true,
  },
  {
    path: `/${path('employee-suggestion-edit')}/:employeeSuggestionId/:copy`,
    title: rt('create-employee-suggestion'),
    component: CreateOrUpdateEmployeeSuggestion,
    exact: true,
  },
  {
    path: `/${path('create-new-shifts')}`,
    title: rt('create-new-shifts'),
    component: CreateOrUpdateShiftSet,
    exact: true,
  },
  {
    path: `/${path('chat')}/:chatId`,
    title: rt('chat'),
    component: Chat,
    exact: true,
  },
  {
    path: `/${path('chat')}/:chatId/:messageId`,
    title: rt('chat'),
    component: Chat,
    exact: true,
  }

];

export const messageRoutes: Route[] = [
  {
    path: `/${path('messages')}`,
    title: rt('sendMessages'),
    component: MessageLogs,
    exact: true,
  },
  {
    path: `/${path('messages')}/${path('logs')}`,
    title: rt('messageLogs'),
    component: MessageLogs,
    exact: true,
  },
];

export const adminRoutes: Route[] = [
  {
    path: `/${path('admin')}/${path('admin-users')}`,
    title: rt('admin-users'),
    component: Admin.Users,
    exact: true,
  },
  {
    path: `/${path('admin')}/${path('admin-teams')}`,
    title: rt('admin-teams'),
    component: Admin.Teams,
    exact: true,
  },
  {
    path: `/${path('admin')}/${path('admin-cronstatus')}`,
    title: rt('admin-cronstatus'),
    component: Admin.CronStatus,
    exact: true,
  },
  {
    path: `/${path('admin')}/${path('admin-payperiods')}`,
    title: rt('admin-payperiods'),
    component: Admin.PayPeriods,
    exact: true,
  },
  {
    path: `/${path('admin')}/${path('admin-chats')}`,
    title: rt('admin-chats'),
    component: Admin.Chats,
    exact: true,
  },
  {
    path: `/${path('admin')}/${path('admin-debug')}`,
    title: rt('admin-debug'),
    component: Admin.Debug,
    exact: true,
  },
];

export const guideRoutes: Route[] = [
  {
    path: `/${path('guide')}/:id`,
    title: rt('edit-guide'),
    component: Guide.GuideEditor,
    exact: true,
  },
];

const appRoutes: (Route | NavLinkRoute)[] = [...navBarRoutes, ...userRoutes, ...loginRoutes, ...otherRoutes, ...messageRoutes, ...adminRoutes, ...guideRoutes, employeeDataBaseRoute, ...employeeDataRoutes];

export default appRoutes;

import styled from 'styled-components';

export const SearchListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  > div {
    &.main-column {
      flex: 1;
    }
    &.secondary-column {
      margin-right: ${p => p.theme.spacings.lg};
    }
  }
  @media ${p => p.theme.breakpoints.smalldesktop} {
    display: block;
  }
`;

import React, { ChangeEvent, Dispatch, forwardRef, SetStateAction } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import Label from './Label';

const Container = styled.div`
  margin-top: ${p => p.theme.spacings.md};
  margin-bottom: ${p => p.theme.spacings.md};

  .input-group {
    .input-group-prepend,
    .input-group-append {
      .input-group-text {
        background-color: ${p => p.theme.colors.white};
        color: ${p => p.theme.colors.primary};
      }
    }
  }
`;

interface Props {
  id: string;
  value?: string | number;
  label?: string;
  placeholder?: string;
  type?: string;
  prepend?: string | JSX.Element;
  append?: string | JSX.Element;
  onChange?: (event: ChangeEvent<any>) => void;
  onClick?: (event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  setValue?: Dispatch<SetStateAction<string>> | Function;
  asTextarea?: boolean;
  disabled?: boolean;
  autoComplete?: string;
}

// Note: forwardRef is used because of the DatePicker component,
// which is using this as a custom input and requires some handling for the ref
// Still, the ref is not used in this component in any way.
export const Input = forwardRef<any, Props>((props, _ref: any) => {
  const { id, value, label, placeholder, type, prepend, append, onChange, onClick, onKeyDown, setValue, asTextarea = false, disabled, autoComplete = 'on' } = props;

  const handleChange = (event: ChangeEvent<any>) => {
    if (setValue) setValue(event.currentTarget.value);
  };

  return (
    <Container>
      {label && <Label htmlFor={id}>{label}</Label>}
      <InputGroup>
        {prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <FormControl
          id={id}
          data-testid={id}
          placeholder={placeholder}
          value={value}
          onKeyDown={onKeyDown ?? onKeyDown}
          onChange={onChange ?? handleChange}
          type={type}
          as={asTextarea ? 'textarea' : undefined}
          rows={asTextarea ? 5 : undefined}
          disabled={disabled}
          onClick={onClick}
          autoComplete={autoComplete}
        />
        {append && (
          <InputGroup.Append>
            <InputGroup.Text>{append}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </Container>
  );
});

export default Input;

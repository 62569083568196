import React, { FC, useEffect, useRef, useState } from 'react';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { Icon } from '../../components/Icon';
import { Button, Checkbox, Input } from '../../components/inputs';
import { Loader } from '../../components/Loader';
import Table from '../../components/Table';
import { chatEventStore } from '../../mobx/chatEventStore';
import { ChatRoom } from '../../mobx/ChatRoom';
import { userStore } from '../../mobx/userStore';
import { path } from '../../routes/routes';
import { dateToString } from '../../utils/date';
import ChatAddParticipantsModal from './ChatAddParticipantsModal';
import {
  BottomButtonsContainer,
  ChatLabel,
  ChatNewEvents,
  ChatRoomListItem,
  ChatRoomUpdatedContainer,
  ChatTitle,
  ChatUpdateTime,
  Container,
  FormContainer,
  GreetMessageInputContainer,
  ImageContainer,
  ListContainer,
  ListHeader,
  ListItemTextContent,
  ListLoaderContainer,
  RemoveParticipantButton,
  SelectionInfo,
  TopButtonsContainer,
} from './styles/ChatSideUI.styles';
import { useQueryParams } from '../../utils/useQueryParams';
import notify from '../../services/notify';

interface IProps {
  chatList: any;
  selectedChat?: number;
  setSelectedChat: (chat: number | undefined) => void;
}

export type ParticipantModalOption = 'group' | 'user';

export type ChatUIState = 'select chatroom' | 'in chat' | 'create chatroom' | 'add participants modal';

const ChatSideUI: FC<IProps> = ({ setSelectedChat }) => {
  const [chatUIState, setChatUIState] = useState<ChatUIState>('select chatroom');
  const [newChatTitle, setNewChatTitle] = useState('');
  const [selectedUserList, setSelectedUserList] = useState<ChatUser[]>([]);
  const [selectedGroupList, setSelectedGroupList] = useState<ChatGroup[]>([]);
  const [searchChatRoomsResult, setSearchChatRoomsResult] = useState<ChatRoom[] | undefined>(undefined);
  const [showAddParticipantsModal, setShowAddParticipantsModal] = useState(false);
  const [participantModalOption, setParticipantModalOption] = useState<ParticipantModalOption>('group');
  const [editorState, setEditorState] = useState<EditorState>();
  const [showArchived, setShowArchived] = useState(false);
  const [searchedByMessage, setSearchedByMessage] = useState(false);

  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const { chatId } = useParams<{ chatId: string; messageId: string }>();
  const { chatList, currentChat, availableUsers, availableGroups, status } = chatEventStore;
  const { user } = userStore;

  const history = useHistory();
  const currentChatId = currentChat?.id;

  useEffect(() => {
    if (chatId) {
      handleOpenChat(chatId);
    } else setChatUIState('select chatroom');
  }, []);

  useEffect(() => {
    if (Boolean(queryParams.get('markedUnread')) === true) {
      notify.success(t('message marked unread success'));

      const newQueryParams = new URLSearchParams(queryParams);
      newQueryParams.delete('markedUnread');
      history.replace(`/${path('chat')}?${newQueryParams.toString()}`);
    }
  }, [queryParams.keys()]);

  useEffect(() => {
    if (currentChatId !== undefined) history.replace(`/${path('chat')}/${chatEventStore?.currentChat?.id}`);
  }, [currentChatId]);

  const renderUpdatedAt = ({ timestamp, newEvents, newMessages }: ChatRoom) => {
    const time = new Date(timestamp);
    const today: Date = new Date();
    const updatedToday = time.getDate() === today.getDate() && time.getMonth() === today.getMonth() && time.getFullYear() === today.getFullYear();
    const minutes = new Date(time).getMinutes();
    const renderMinutes = String(minutes <= 9 ? '0' + minutes : minutes);
    const totalUnreadMessages = newMessages ? newMessages + newEvents : newEvents;
    const hasUnreadMessages = totalUnreadMessages > 0;

    return (
      <ChatRoomUpdatedContainer>
        {updatedToday ? (
          <ChatUpdateTime>{`${new Date(time).getHours()}:${renderMinutes}`}</ChatUpdateTime>
        ) : (
          <ChatUpdateTime> {timestamp ? dateToString(time, 'd/M') : ''}</ChatUpdateTime>
        )}
        <ChatNewEvents renderChatEvents={hasUnreadMessages}>{hasUnreadMessages && totalUnreadMessages}</ChatNewEvents>
      </ChatRoomUpdatedContainer>
    );
  };

  const renderLabelAndTitle = ({ title, label, messages, status, createdBy }: any) => {
    const renderTitle = title?.length > 25 ? title.slice(0, 25) + '...' : title;
    const renderLabel =
      messages?.[0]?.message && messages[0].message.length < 25
        ? `${messages[0]?.user?.name}: ${messages[0]?.message}`
        : `${messages[0]?.user?.name}: ${messages[0]?.message}`.slice(0, 25) + '...';

    const hasMessages = messages[0] !== undefined;

    const hasFileAttachments = messages?.[0] && messages?.[0].files?.length > 0;
    const isActive = status === 'active';
    return (
      <ChatRoomListItem isActive={isActive}>
        <ChatTitle>{renderTitle}</ChatTitle>
        <ChatLabel>
          {hasMessages ? renderLabel : ''} {hasFileAttachments ? `(${t('file attachments')})` : ''}
        </ChatLabel>
      </ChatRoomListItem>
    );
  };

  const renderChatImage = () => {
    return (
      <ImageContainer>
        <Icon type="Group2" width={32} />
      </ImageContainer>
    );
  };

  const handleOpenChat = (chat: string | Chat) => {
    if (chatUIState === 'select chatroom') {
      if (typeof chat === 'string') {
        setSelectedChat(Number(chatId));
        setChatUIState('in chat');
      } else {
        setSelectedChat(chat.id);
        setChatUIState('in chat');
        if (chat.messages && searchedByMessage) {
          chatEventStore.setFoundMessage(chat.id, chat.messages[0].id);
        }
      }
    }
  };

  const renderUserDetails = ({ name, role, email }: any) => {
    return (
      <ListItemTextContent>
        <div>{name}</div>
        <div className="list-item-secondary-text">
          {t(role)} {email}
        </div>
      </ListItemTextContent>
    );
  };

  const renderUserImage = ({ photo }: any) => {
    return <ImageContainer>{photo ? <img src={photo} /> : <Icon type="Person" width={32} />}</ImageContainer>;
  };

  const handleRemoveParticipant = (item: ChatUser | ChatGroup) => {
    const filteredGroups = selectedGroupList.filter(e => e !== item);
    setSelectedGroupList(filteredGroups);
    const filteredUsers = selectedUserList.filter(e => e !== item);
    setSelectedUserList(filteredUsers);
  };

  const renderRemoveParticipantButton = (item: ChatUser | ChatGroup) => {
    return (
      <RemoveParticipantButton onClick={() => handleRemoveParticipant(item)}>
        <Icon type="Close" color="primary" />
      </RemoveParticipantButton>
    );
  };

  const handleCreateNewChat = () => {
    setChatUIState('create chatroom');
  };

  const handleStartNewChat = () => {
    const selectedUserIdList = selectedUserList.map(user => user.id);
    const selectedGroupIdList = selectedGroupList.map(group => group.id);
    const greetMessage = editorState ? editorState.getCurrentContent().getPlainText() : undefined;

    chatEventStore.newChat({ title: newChatTitle, message: greetMessage, userIds: selectedUserIdList, groupIds: selectedGroupIdList });
    setChatUIState('in chat');
    setSelectedGroupList([]);
    setSelectedUserList([]);
    setEditorState(undefined);
    setNewChatTitle('');
  };

  const handleSearchFromChatrooms = async (e: React.KeyboardEvent<Element>, searchType: 'title' | 'message' | 'group' | 'user') => {
    const value = (e.target as HTMLInputElement).value;

    if (e.key === 'Enter') {
      switch (searchType) {
        case 'title': {
          const foundByTitle = await chatEventStore.getAvailableChats({ skip: 0, searchTitle: value, status: showArchived ? 'archived' : 'active' });
          setSearchChatRoomsResult(foundByTitle);
          setSearchedByMessage(false);
          break;
        }
        case 'message':
          const foundByMessage = await chatEventStore.getAvailableChats({ skip: 0, searchMessage: value, status: showArchived ? 'archived' : 'active' });
          setSearchChatRoomsResult(foundByMessage);
          setSearchedByMessage(true);
          break;
        case 'group':
          const foundByGroup = await chatEventStore.getAvailableChats({ skip: 0, searchGroup: value, status: showArchived ? 'archived' : 'active' });
          setSearchChatRoomsResult(foundByGroup);
          setSearchedByMessage(false);
          break;
        case 'user':
          const foundByUser = await chatEventStore.getAvailableChats({ skip: 0, searchUser: value, status: showArchived ? 'archived' : 'active' });
          setSearchChatRoomsResult(foundByUser);
          setSearchedByMessage(false);
          break;
      }
    }
  };

  const tableColumns: any[] =
    chatUIState === 'select chatroom'
      ? [{ customRender: renderChatImage }, { key: 'label', customRender: renderLabelAndTitle }, { key: 'updatedAt', customRender: renderUpdatedAt }]
      : chatUIState === 'in chat'
      ? [{ customRender: renderUserImage }, { customRender: renderUserDetails }]
      : [{ key: 'photo', customRender: renderUserImage }, { key: 'name', customRender: renderUserDetails }, { customRender: renderRemoveParticipantButton }];

  const groupsInChatTableColumns: any[] = [{ customRender: renderUserImage }, { customRender: renderUserDetails }];

  const listData: any =
    chatUIState === 'select chatroom'
      ? searchChatRoomsResult?.filter(
          (chatroom: ChatRoom) => chatroom?.messages?.length > 0 || (chatroom?.messages?.length === 0 && chatroom?.createdBy?.id === user?.id)
        ) ||
        chatList?.filter((chatroom: ChatRoom) => chatroom?.messages?.length > 0 || (chatroom?.messages?.length === 0 && chatroom?.createdBy?.id === user?.id)) // filter out chatrooms that dont have any message content
      : chatUIState === 'in chat'
      ? currentChat?.users
      : chatUIState === 'add participants modal' && participantModalOption == 'group'
      ? [...availableGroups]
      : [...availableUsers];

  const groupsInCurrentChat = currentChat?.groups;

  const handleLeaveChatRoom = () => {
    history.replace(`/${path('chat')}`);
    setChatUIState('select chatroom');
    chatEventStore.setFoundMessage(undefined, undefined);
    chatEventStore.leaveChat();
    setSelectedChat(undefined);
  };

  const handleOpenPrivateChat = (user: ChatUser) => {
    chatEventStore.newChat({ userIds: [user.id], title: `${user.name}` });
    // if (!privateChatId) {
    //   notify.error('error');
    // } else {
    //   history.replace(`/${path('chat')}/${privateChatId}`);
  };

  const canStartNewChat = chatUIState === 'create chatroom' && (selectedUserList.length > 0 || selectedGroupList.length > 0) && newChatTitle.length > 0;
  const hasParticipants = selectedGroupList.length > 0 || selectedUserList.length > 0;

  const isBusy = status === 'BUSY';

  const handleOpenParticipantsModal = (option: ParticipantModalOption) => {
    setChatUIState('add participants modal');
    setParticipantModalOption(option);
    setShowAddParticipantsModal(true);
  };

  const handleClearSelections = () => {
    setSelectedGroupList([]);
    setSelectedUserList([]);
  };

  // couldnt find proper type for editorRef
  const editorRef = useRef<any>(null);

  const fetchAllChatrooms = async () => {
    const searchResults = await chatEventStore.getAvailableChats({ skip: 0, status: showArchived ? 'archived' : 'active' });
    setSearchChatRoomsResult(searchResults);
    setSearchedByMessage(false);
  };

  return (
    <Container>
      <TopButtonsContainer>
        {chatUIState !== 'select chatroom' && (
          <Button isSmall text={t('back')} color="primary" variant="outlined" id="edit-organization-address" onClick={handleLeaveChatRoom} />
        )}
        {chatUIState === 'select chatroom' && (
          <Button
            isSmall
            text={t('create new chat')}
            color="primary"
            variant="outlined"
            id="edit-organization-address"
            onClick={handleCreateNewChat}
            icon={<Icon type="Edit" color="primary" />}
          />
        )}
      </TopButtonsContainer>

      {chatUIState === 'select chatroom' && (
        <>
          <ListHeader>{t('select chat')}</ListHeader>
          <FormContainer chatUIState={chatUIState}>
            <Input
              autoComplete="off"
              id="search-chat-rooms-by-title"
              onKeyDown={(e: React.KeyboardEvent<Element>) => {
                handleSearchFromChatrooms(e, 'title');
              }}
              placeholder={t('search chatrooms by title')}
            />
            <Input
              autoComplete="off"
              id="search-chat-rooms-by-message"
              onKeyDown={(e: React.KeyboardEvent<Element>) => {
                handleSearchFromChatrooms(e, 'message');
              }}
              placeholder={t('search chatrooms by message')}
            />
            <Input
              autoComplete="off"
              id="search-chat-rooms-by-group"
              onKeyDown={(e: React.KeyboardEvent<Element>) => {
                handleSearchFromChatrooms(e, 'group');
              }}
              placeholder={t('search chatrooms by group')}
            />
            <Input
              autoComplete="off"
              id="search-chat-rooms-by-user"
              onKeyDown={(e: React.KeyboardEvent<Element>) => {
                handleSearchFromChatrooms(e, 'user');
              }}
              placeholder={t('search chatrooms by user')}
            />

            <div className="buttons">
              <Button isSmall text={t('fetch all')} variant="mini" id="button-fetch-all" onClick={fetchAllChatrooms} disabled={isBusy} />
              <Checkbox id="show-archived-chatrooms" label={t('show archived')} checked={showArchived} setValue={setShowArchived} disabled={isBusy} />
            </div>
          </FormContainer>

          <ListContainer chatUIState={chatUIState}>
            {isBusy ? (
              <ListLoaderContainer>
                <Loader size="sm" />
              </ListLoaderContainer>
            ) : (
              listData && <Table showHeader={false} data={listData} columns={tableColumns} onClickRow={handleOpenChat} />
            )}
          </ListContainer>
        </>
      )}

      {(chatUIState === 'create chatroom' || chatUIState === 'add participants modal') && (
        <>
          <ListHeader>{t('create new chat')}</ListHeader>
          <FormContainer chatUIState={chatUIState}>
            <div>
              <Input
                autoComplete="off"
                id="new-chat-title"
                value={newChatTitle}
                onChange={e => setNewChatTitle(e.target.value)}
                placeholder={t('new chat title')}
              />
              <GreetMessageInputContainer>
                <Editor
                  placeholder={t('new chat greet message')}
                  ref={editorRef}
                  toolbar={{ options: ['emoji'] }}
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                />
              </GreetMessageInputContainer>
              <div className="buttons">
                <Button isSmall text={t('add groups')} variant="mini" id="add-group" onClick={() => handleOpenParticipantsModal('group')} />
                <Button isSmall text={t('add users')} variant="mini" id="add-users" onClick={() => handleOpenParticipantsModal('user')} />
              </div>
            </div>
          </FormContainer>
          <ListContainer chatUIState={chatUIState}>
            <ListHeader>{t('selected groups')}</ListHeader>
            {selectedGroupList.length > 0 ? (
              <Table showHeader={false} data={selectedGroupList} columns={tableColumns} />
            ) : (
              <SelectionInfo>{t('no selected groups')}</SelectionInfo>
            )}
            <ListHeader>{t('selected users')}</ListHeader>
            {selectedUserList.length > 0 ? (
              <Table showHeader={false} data={selectedUserList} columns={tableColumns} />
            ) : (
              <SelectionInfo>{t('no selected users')}</SelectionInfo>
            )}
          </ListContainer>
        </>
      )}

      {chatUIState === 'in chat' && (
        <>
          <ListContainer chatUIState={chatUIState}>
            {groupsInCurrentChat && (
              <>
                <ListHeader>{t('groups')}</ListHeader>
                <Table showHeader={false} data={groupsInCurrentChat} columns={groupsInChatTableColumns} />
              </>
            )}
            <ListHeader>{t('active participants')}</ListHeader>
            {listData && <Table showHeader={false} data={listData} columns={tableColumns} onClickRow={handleOpenPrivateChat} />}
          </ListContainer>
        </>
      )}

      {showAddParticipantsModal && (
        <ChatAddParticipantsModal
          participantModalOption={participantModalOption}
          setSelectedUserList={setSelectedUserList}
          setSelectedGroupList={setSelectedGroupList}
          setShowAddParticipantsModal={setShowAddParticipantsModal}
          setChatUIState={setChatUIState}
          selectedGroupList={selectedGroupList}
          selectedUserList={selectedUserList}
        />
      )}

      <BottomButtonsContainer>
        {(chatUIState === 'create chatroom' || chatUIState === 'add participants modal') && (
          <>
            <Button
              isSmall
              text={t('clear selections')}
              color="primary"
              variant="outlined"
              id="clear-groups-and-users"
              onClick={() => handleClearSelections()}
              disabled={!hasParticipants}
            />
            <Button
              isSmall
              text={t('start chat')}
              color="primary"
              variant="outlined"
              id="edit-organization-address"
              onClick={handleStartNewChat}
              disabled={!canStartNewChat}
            />
          </>
        )}
      </BottomButtonsContainer>
    </Container>
  );
};

export default ChatSideUI;

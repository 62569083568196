import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Container = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 50%;

  li {
    display: flex;
    justify-content: space-between;
    padding: ${p => p.theme.spacings.md};
    border-bottom: 1px solid ${p => p.theme.colors.grayLighter};

    .list-data-object-item-title {
      font-weight: bold;
    }
  }
`;

interface BaseProps<T> {
  objectKeys: (keyof T)[];
}

interface WithCustomRender<T> extends BaseProps<T> {
  renderValueInKey: (key: keyof T) => string | JSX.Element;
  object?: T;
}

interface NoCustomRender<T> extends BaseProps<T> {
  renderValueInKey?: (key: keyof T) => string | JSX.Element;
  object: T;
}

/**
 * List of object values in a given keys.
 *
 * Besides object keys, either object itself OR custom render function has to be given as props
 */
const List = <T,>({ objectKeys, renderValueInKey, object }: WithCustomRender<T> | NoCustomRender<T>) => {
  const { t } = useTranslation();

  return (
    <Container>
      {objectKeys.map(key => (
        <li key={`list-data-object-item-${key as string}`}>
          <div className="list-data-object-item-title">{t(key as string)}</div>
          <div>{renderValueInKey ? renderValueInKey(key) : object ? object[key] + '' : ''}</div>
        </li>
      ))}
    </Container>
  );
};

export default List;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: ${p => p.theme.spacings.lg};
  white-space: pre-line;
  text-align: center;
  font-size: ${p => p.theme.fonts.size.md};
`;

const LoginInfo = () => {
  const { t } = useTranslation();
  return <Container>{t('infoText.login')}</Container>;
};

export default LoginInfo;

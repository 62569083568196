import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Async from 'react-select/async';

import { userStore } from '../mobx/userStore';
import { api } from '../services/api';
import config from '../services/api/config';
import Container from './inputs/Container';
import Label from './inputs/Label';

interface Props {
  selectedEmployees?: SelectedOption<number>[];
  selectedBlacklisted?: SelectedOption<number>[];
  selectedGroups?: SelectedOption<number>[];
  setSelectedEmployees?: Function;
  setSelectedGroups?: Function;
  setSelectedBlacklisted?: Function;
}

export const SelectRecipients: FC<Props> = ({
  selectedEmployees,
  selectedGroups,
  selectedBlacklisted,
  setSelectedGroups,
  setSelectedEmployees,
  setSelectedBlacklisted,
}) => {
  const { t } = useTranslation();
  const { token } = userStore;
  const { likeitSellerPersonId } = config;
  const [params, setParams] = useState<GetOrgOptionsParams>({
    filter: ['groups', 'employeeTableSearch'],
    likeitSellerPersonId,
    take: 100,
  });
  const onChange = (name: string) => (options: unknown) => {
    switch (name) {
      case 'group':
        setSelectedGroups && setSelectedGroups(options);
        const newParams = {
          ...params,
          groupName: undefined,
          groupIds: (options as any[]).map((o: any): number => Number(o.value)),
        };
        setParams(newParams);
        break;
      case 'employee':
        setSelectedEmployees && setSelectedEmployees(options);
        break;
      case 'blacklist':
        setSelectedBlacklisted && setSelectedBlacklisted(options);
        break;
    }
  };
  const loadOptions = (name: string) => async (value: string) => {
    const newParams: GetOrgOptionsParams & { [name: string]: any } = { ...params };
    newParams[`${name}Name`] = value;
    setParams(newParams);
    if (!token) {
      return;
    }
    const { data } = await api.organizationSearch({ token, ...params });
    if (data) {
      const optsRes: any = data;
      return optsRes[`${name}s`].map(({ name, id }: any) => ({ label: name, value: id }));
    }
  };
  return (
    <div>
      <h1>{t('recipients')}</h1>
      {setSelectedGroups && (
        <Container>
          <Label>{t('recipientGroups')}</Label>
          <Async
            id="group-async"
            loadOptions={loadOptions('group')}
            value={selectedGroups}
            onChange={onChange('group')}
            isMulti={true}
            placeholder={t('select')}
          />
        </Container>
      )}
      {setSelectedEmployees && (
        <Container>
          <Label>{t('recipientEmployees')}</Label>
          <Async
            id="employee-async"
            loadOptions={loadOptions('employee')}
            value={selectedEmployees}
            onChange={onChange('employee')}
            isMulti={true}
            placeholder={t('select')}
          />
        </Container>
      )}
      {setSelectedBlacklisted && (
        <Container>
          <Label>⛔{t('blacklistedUsers')}</Label>
          <Async
            id="blacklist-async"
            loadOptions={loadOptions('employee')}
            value={selectedBlacklisted}
            onChange={onChange('blacklist')}
            isMulti={true}
            placeholder={t('select')}
          />
        </Container>
      )}
    </div>
  );
};

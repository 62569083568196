import { action, makeAutoObservable } from 'mobx';

import { api } from '../services/api';
import notify from '../services/notify';
import { userStore } from './userStore';

class TeamStore {
  status: 'IDLE' | 'BUSY' | 'FETCHED' | 'ERROR' | 'UPDATED' = 'IDLE';
  error?: any;

  params?: { skip?: number; take?: number };
  teams?: ITeam[];
  total?: number;

  constructor() {
    makeAutoObservable(this);
  }

  // list availble team data for admin/staff
  @action
  async getTeams(params?: { skip?: number; take?: number }) {
    this.status = 'BUSY';
    const result = await api.getTeams();
    if (result.ok && result.data) {
      const { results, total, params } = result.data;
      (this.status = 'FETCHED'), (this.teams = results);
      this.total = total;
      this.params = params;
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
    }
  }

  // save team properties
  @action
  async updateTeamProps(teamId: number, props: Partial<ITeam>) {
    this.status = 'BUSY';
    const result = await api.updateTeamProps(teamId, props);
    if (result.ok && result.data) {
      this.status = 'UPDATED';
      notify.success('team-updated');
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
    }
  }

  @action
  async setMyTeam(teamId: number) {
    this.status = 'BUSY';
    const result = await api.setMyTeam(teamId);
    if (result.ok && result.data) {
      this.status = 'UPDATED';
      notify.success('team-updated');
      userStore.getMe();
      return result.data;
    } else {
      this.error = result.originalError;
      this.status = 'ERROR';
      notify.error(this.error?.message || 'error-updating-team');
    }
  }
}
export const teamStore = new TeamStore();

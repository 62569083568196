import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox } from '../../../components/inputs';
import { shiftStore } from '../../../mobx/shiftStore';

const Container = styled.div`
  margin-bottom: 20px;
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    > div {
      margin-right: 30px;
    }
  }
  @media ${p => p.theme.breakpoints.mobile} {
    > div {
      display: block;
    }
  }
`;

const EmployeeNotificationSettings: FC = () => {
  const { unsavedShiftSetData } = shiftStore;
  const { t } = useTranslation();

  const commonShiftSetData = unsavedShiftSetData?.common;

  const handleChangePushNotification = () => {
    shiftStore.change({ common: { ...commonShiftSetData, sendPushNotification: !commonShiftSetData?.sendPushNotification } });
  };

  const handleChangeEmailNotification = () => {
    shiftStore.change({ common: { ...commonShiftSetData, sendEmail: !commonShiftSetData?.sendEmail } });
  };

  const handleChangeSMSNotification = () => {
    shiftStore.change({ common: { ...commonShiftSetData, sendSMS: !commonShiftSetData?.sendSMS } });
  };

  return (
    <Container>
      <h1>{t('notificationsToEmployees')}</h1>
      <div>
        <Checkbox id="new-shift-notify-employee-push" checked={commonShiftSetData?.sendPushNotification === true || false} label={t('pushNotification')} setValue={handleChangePushNotification} />
        <Checkbox id="new-shift-notify-employee-email" checked={commonShiftSetData?.sendEmail || false} label={t('email')} setValue={handleChangeEmailNotification} />
        <Checkbox id="new-shift-notify-employee-sms" checked={commonShiftSetData?.sendSMS || false} label={t('sms')} setValue={handleChangeSMSNotification} />
      </div>
    </Container>
  );
};

export default EmployeeNotificationSettings;

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { employeeStore } from '../../mobx/employeeStore';
import styled from 'styled-components';
import { EmployeeDataBaseProps } from '.';
import { Button, ButtonGroupRow, Checkbox } from '../../components/inputs';
import Label from '../../components/inputs/Label';
import { Loader } from '../../components/Loader';
import { Todo } from '../../components/Todo';

const Container = styled.div``;

interface Props extends EmployeeDataBaseProps {}

export const Notifications: FC<Props> = () => {
  const { t } = useTranslation();
  const { status, employee } = employeeStore;

  // TODO: Set initial values from employee object when available
  const [isPush] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [isSms, setIsSms] = useState(false);

  const handleSave = () => {
    if (!employee) {
      return;
    }
    employeeStore.patchEmployee({
      id: employee.id,
      employeePatch: {
        // TODO: Person type does not have notification property (or anything related?)
        //       Fix this when known what API is actually expecting
        notifications: {
          isPush,
          isEmail,
          isSms,
        },
      },
    });
  };

  const handleCancel = () => {
    console.log('TODO: Handle cancel (set values from current employee object to internal state)');
  };

  return (
    <Container>
      {status === 'BUSY' && <Loader isFullScreen />}

      <Label>{`${t('notificationsFromNewShifts').toUpperCase()}:`}</Label>

      <Checkbox id="employee-data-notify-push-checkbox" label={`${t('pushNotification')} (${t('managedInMobileApp')})`} checked={isPush} disabled />
      <Checkbox id="employee-data-notify-email-checkbox" label={t('email')} checked={isEmail} setValue={setIsEmail} />
      <Checkbox id="employee-data-notify-sms-checkbox" label={t('sms')} checked={isSms} setValue={setIsSms} />

      <ButtonGroupRow>
        <Button id="employee-data-notify-save-button" text={t('save')} onClick={handleSave} color="primary" />
        <Button id="employee-data-notify-save-button" text={t('cancel')} onClick={handleCancel} variant="outlined" />
      </ButtonGroupRow>

      <Todo todo="Tallennus" notes="Mm. tyypityksiä ja API-parametrejä pitää yhteensovitella" noMargin />
    </Container>
  );
};

export default Notifications;

import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConfirmModal, Loader } from '../../components';
import { Icon } from '../../components/Icon';
import { Input } from '../../components/inputs';
import Table from '../../components/Table';
import { chatEventStore } from '../../mobx/chatEventStore';
import { ChatUIState, ParticipantModalOption } from './ChatSideUI';

interface IProps {
  participantModalOption: ParticipantModalOption;
  setSelectedGroupList: React.Dispatch<React.SetStateAction<ChatGroup[]>>;
  setSelectedUserList: React.Dispatch<React.SetStateAction<ChatUser[]>>;
  setShowAddParticipantsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setChatUIState: React.Dispatch<React.SetStateAction<ChatUIState>>;
  selectedUserList: ChatUser[];
  selectedGroupList: ChatGroup[];
}

const ModalContainer = styled.div`
  * input {
    margin: 0px !important;
  }
`;

const ModalHeader = styled.div`
  font-size: ${p => p.theme.fonts.size.lg};
  font-weight: bold;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
  svg {
    fill: white;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background: linear-gradient(180deg, #ff1926 0%, #d91722 100%);
    padding: 9px;
    overflow: visible;
  }
`;

const SelectedItemContainer = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border: 1px solid ${p => p.theme.colors.gray};
  border-radius: 50%;
  font-size: ${p => p.theme.fonts.size.lg};
  font-weight: bold;

  padding: 0 !important;

  svg {
    width: 100%;
    margin: 0 !important;
    border-radius: 50px;
    fill: white;
    background: linear-gradient(180deg, #ff1926 0%, #d91722 100%);
    padding: 3px;
  }
`;

const ListItemTextContent = styled.div`
  flex-direction: column;

  overflow: hidden;
  .list-item-secondary-text {
    max-width: 50%;
    color: ${p => p.theme.colors.grayDarker};
    font-size: ${p => p.theme.fonts.size.sm};
  }
`;

const SelectedParticipantsContainer = styled.div`
  background-color: ${p => p.theme.colors.grayLighter};
  padding: ${p => p.theme.spacings.sm} !important;
  margin-top: ${p => p.theme.spacings.sm} !important;
  height: 150px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  * button {
    padding: 0 !important;
    margin: 0;
  }
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

const SelectedParticipantsHeader = styled.div`
  color: black;
  font-size: ${p => p.theme.fonts.size.md};
  margin: ${p => p.theme.spacings.md} !important;
  font-weight: bold;
`;

const SelectedParticipant = styled.div`
  display: inline-block;
  text-align: center;
  max-width: 150px;
  height: 30px;
  color: ${p => p.theme.colors.white};
  font-size: ${p => p.theme.fonts.size.sm};
  background-color: ${p => p.theme.colors.primary};
  margin: 5px !important;
  padding: ${p => p.theme.spacings.sm} !important;
  border-radius: 40px;
  :hover {
    cursor: pointer;
  }
`;

const ModalListContainer = styled.div`
  height: 40vh !important;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

const ChatAddParticipantsModal: FC<IProps> = observer(
  ({ participantModalOption, setSelectedGroupList, setSelectedUserList, setShowAddParticipantsModal, setChatUIState, selectedGroupList, selectedUserList }) => {
    const alreadySelected = participantModalOption === 'group' ? selectedGroupList : selectedUserList;
    const [selectedParticipantsListInModal, setSelectedParticipantsListInModal] = useState<ChatGroup[] | ChatUser[]>([...alreadySelected]);
    const { t } = useTranslation();
    const { availableUsers, availableGroups, status } = chatEventStore;

    const renderUserDetails = ({ name, role, email }: any) => {
      return (
        <ListItemTextContent>
          <div>{name}</div>
          <div className="list-item-secondary-text">
            {t(role)} {email}
          </div>
        </ListItemTextContent>
      );
    };

    const renderUserImage = ({ photo }: any) => {
      return <ImageContainer>{photo ? <img src={photo} /> : <Icon type="Person" width={32} />}</ImageContainer>;
    };

    const renderSelectedItem = (item: ChatUser | ChatGroup) => {
      const isSelected = selectedParticipantsListInModal.some(participant => participant.id === item.id);
      return <SelectedItemContainer>{isSelected && <Icon type="Checked" color="primary" />}</SelectedItemContainer>;
    };

    const handleSearch = async (e: React.KeyboardEvent<Element>, searchType: 'group' | 'user' | 'chatroom') => {
      const value = (e.target as HTMLInputElement).value;

      if (e.key === 'Enter') {
        switch (searchType) {
          case 'group':
            chatEventStore.chatAddGroupsList({ searchGroup: value });

            break;
          case 'user':
            chatEventStore.chatAddUsersList({ searchUser: value });
            break;
          default:
        }
      }
    };

    const tableColumns: any[] = [
      { key: 'photo', customRender: renderUserImage },
      { key: 'name', customRender: renderUserDetails },
      { customRender: renderSelectedItem },
    ];

    const listData: any = participantModalOption == 'group' ? availableGroups : availableUsers;

    const selectListItem = (item: ChatUser | ChatGroup) => {
      const isSelected = selectedParticipantsListInModal.some(participant => participant.id === item.id);
      isSelected
        ? setSelectedParticipantsListInModal(selectedParticipantsListInModal.filter(e => e.id !== item.id))
        : setSelectedParticipantsListInModal([...selectedParticipantsListInModal, item]);
    };

    const onClickRow = selectListItem;
    const isFetching = status === 'BUSY';

    const handleRemoveParticipantInModal = (participant: ChatGroup | ChatUser) => {
      const filteredList = selectedParticipantsListInModal.filter(e => e !== participant);
      setSelectedParticipantsListInModal(filteredList);
    };

    const handleConfirmAddChatParticipants = () => {
      switch (participantModalOption) {
        case 'group':
          setSelectedGroupList(selectedParticipantsListInModal);
          break;
        case 'user':
          setSelectedUserList(selectedParticipantsListInModal);
          break;
      }
      setShowAddParticipantsModal(false);
      setChatUIState('create chatroom');
      setSelectedParticipantsListInModal([]);
      chatEventStore.clearSearchResults();
    };

    const handleCancelAddChatParticipants = () => {
      setShowAddParticipantsModal(false);
      setChatUIState('create chatroom');
      setSelectedParticipantsListInModal([]);
      chatEventStore.clearSearchResults();
    };

    return (
      <ConfirmModal onConfirm={handleConfirmAddChatParticipants} onHide={handleCancelAddChatParticipants} confirmText={t('accept')} cancelText={t('cancel')}>
        <ModalContainer>
          <ModalHeader>{participantModalOption === 'group' ? t('add groups') : t('add users')}</ModalHeader>
          <Input
            autoComplete="off"
            id="new-chat-search-participants"
            onKeyDown={(e: React.KeyboardEvent<Element>) => {
              handleSearch(e, participantModalOption);
            }}
            placeholder={participantModalOption === 'group' ? t('search groups') : t('search users')}
          />
          <ModalListContainer>
            {isFetching ? <Loader size="sm" /> : listData && <Table showHeader={false} data={listData} columns={tableColumns} onClickRow={onClickRow} />}
          </ModalListContainer>
          <SelectedParticipantsContainer>
            <SelectedParticipantsHeader>{participantModalOption === 'group' ? t('groups') : t('users')}:</SelectedParticipantsHeader>
            {selectedParticipantsListInModal.map(participant => {
              return (
                <SelectedParticipant onClick={() => handleRemoveParticipantInModal(participant)}>
                  {participant.name.length > 10 ? participant.name.substring(0, 10) + '...' : participant.name}
                  <Icon color="white" type="Close" />
                </SelectedParticipant>
              );
            })}
          </SelectedParticipantsContainer>
        </ModalContainer>
      </ConfirmModal>
    );
  }
);

export default ChatAddParticipantsModal;

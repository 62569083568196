import { action, makeAutoObservable, runInAction } from 'mobx';

import { api } from '../services/api';

class PrefsStore {
  status: 'EMPTY' | 'BUSY' | 'FETCHED' | 'SAVED' | 'ERROR' = 'EMPTY';
  prefs: { [key: string]: any } = {};
  constructor() {
    makeAutoObservable(this);
  }
  @action
  async loadPrefs(key: string): Promise<any> {
    runInAction(() => {
      this.status = 'BUSY';
    });
    const result = await api.loadPrefs(key);
    if (result.ok) {
      runInAction(() => {
        this.status = 'FETCHED';
        this.prefs[key] = result.data;
      });
      return result.data;
    } else {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }
  @action
  async savePrefs(params: { key: string; value: any; id?: number }): Promise<any> {
    runInAction(() => {
      this.status = 'BUSY';
    });
    const result = await api.savePrefs(params);
    if (result.ok) {
      runInAction(() => {
        this.status = 'SAVED';
      });
      return this.loadPrefs(params.key);
    } else {
      this.status = 'ERROR';
    }
  }
}

export const prefsStore = new PrefsStore();

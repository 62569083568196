import { action, makeAutoObservable } from 'mobx';
import { userStore } from './userStore';
import { api } from '../services/api';

class EmployeeStore {
  status: 'IDLE' | 'BUSY' | 'FETCHED' | 'ERROR' = 'IDLE';
  error?: any;
  employee?: Person;
  constructor() {
    makeAutoObservable(this);
  }
  @action
  async getEmployee(id: number) {
    const { token } = userStore;
    if (!token) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.getEmployee({ token, id });
    if (result.ok && result.data) {
      this.employee = result.data;
      this.status = 'FETCHED';
    } else {
      this.status = 'ERROR';
    }
  }
  @action
  async patchEmployee(employeePatch: Partial<PatchEmployeeRequest>) {
    const { token } = userStore;
    if (!token || !this.employee) {
      this.status = 'ERROR';
      return;
    }
    this.status = 'BUSY';
    const result = await api.patchEmployee({ token, id: this.employee.id, employeePatch });
    if (result.ok && result.data) {
      this.employee = result.data;
      this.status = 'FETCHED';
    } else {
      this.status = 'ERROR';
    }
  }
}

export const employeeStore = new EmployeeStore();

import { DateTime } from 'luxon';

import { saveAsDefaultDateTime } from '.';
import i18n from '../../../../i18n';

const setTime = (source: DateTime, target: DateTime) => {
  return target.set({ hour: source.hour, minute: source.minute });
};

const setDate = (source: DateTime, target: DateTime) => {
  saveAsDefaultDateTime('shiftStart', source.toJSDate());
  return target.set({ year: source.year, month: source.month, day: source.day });
};

const addDay = (target: DateTime) => {
  return target.plus({ days: 1 });
};

export const updateDates = (type: 'startDate' | 'startTime' | 'endTime', newTime: Date | null, startTime: Date, endTime: Date) => {
  if (newTime === null && type === 'endTime') {
    saveAsDefaultDateTime('shiftStart', startTime);
    return [startTime, null];
  }
  if (newTime === null && type !== 'endTime') {
    saveAsDefaultDateTime('shiftEnd', endTime);
    return [null, endTime];
  }

  // @ts-ignore
  const _newTime = DateTime.fromJSDate(newTime);
  const _startTime = DateTime.fromJSDate(startTime);
  const _endTime = DateTime.fromJSDate(endTime);

  const updatedStartTime = type === 'startTime' ? setTime(_newTime, _startTime) : _startTime;
  const updatedStartDate = type === 'startDate' ? setDate(_newTime, updatedStartTime) : updatedStartTime;
  const equalDatesEndTime = setDate(updatedStartDate, _endTime);
  const updatedEndTime = type === 'endTime' ? setTime(_newTime, equalDatesEndTime) : type === 'startTime' ? setTime(_newTime.plus({ hours: 7 }), equalDatesEndTime) : equalDatesEndTime;
  const updatedEndDate = updatedEndTime.diff(updatedStartDate).valueOf() < 0 ? addDay(updatedEndTime) : updatedEndTime;

  return [updatedStartDate.toJSDate(), updatedEndDate.toJSDate()];
};

export type ShiftKeyValue = 'morning' | 'evening' | 'night';

export const MORNING_OPTION: SelectOptionWithProps<ShiftKeyValue> = { value: 'morning', label: `${i18n.t('morning')}`, startClock: '07:00', endClock: '12:00' };
export const EVENING_OPTION: SelectOptionWithProps<ShiftKeyValue> = { value: 'evening', label: `${i18n.t('evening')}`, startClock: '12:00', endClock: '18:00' };
export const NIGHT_OPTION: SelectOptionWithProps<ShiftKeyValue> = { value: 'night', label: `${i18n.t('night')}`, startClock: '18:00', endClock: '24:00' };
export const SHIFT_TYPE_OPTIONS: SelectOption<ShiftKeyValue>[] = [MORNING_OPTION, EVENING_OPTION, NIGHT_OPTION];

const shiftKeyItems = [
  { startHour: 7, endHour: 12, option: MORNING_OPTION },
  { startHour: 12, endHour: 18, option: EVENING_OPTION },
  { startHour: 18, endHour: 24, option: NIGHT_OPTION },
  { startHour: 0, endHour: 7, option: NIGHT_OPTION },
];

export const getShiftKeyByStartTime = (startTime?: Date | null) => {
  if (!startTime) {
    return;
  }
  const { hour } = DateTime.fromJSDate(startTime);
  const shiftKeyItem = shiftKeyItems.find(({ startHour, endHour }) => {
    return hour >= startHour && hour < endHour;
  });
  return shiftKeyItem && shiftKeyItem.option;
};

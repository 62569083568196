import { chatEventStore, ChatEventType, ChatMessageEventPayload } from './chatEventStore';

/** store newEvent counts in localstorage, so that they are persistent over reloads etc. */

/** this is storage class used for storing chat room data */
export class ChatRoom {
  uid: string;
  id: number;
  teamId?: number;
  team?: { teamId: number; name: string };
  newEvents: number = 0;
  timestamp: number = 0;
  updatedAt?: Date;
  createdAt?: Date;
  createdBy?: IMinimalUser;
  status: 'active' | 'opening' | 'archived' = 'opening';
  title?: string;
  label?: string;
  users: ChatUser[] = [];
  groups: ChatGroup[] = [];
  messages: ChatMessage[] = [];
  messagesTotal?: number;
  newMessages?: number;
  photo?: string;
  params?: { skip: number; take: number }; // used for message paging

  constructor(id: number, props?: Partial<Chat>) {
    this.uid = new Date().getTime().toString();
    //const storedChatData = STORAGE.read({ key: `CHAT${id}` });
    //this.newEvents = storedChatData?.newEvents || 0;
    this.id = id;
    if (props) {
      Object.entries(props).forEach(([key, value]) => {
        switch (key) {
          case 'updatedAt':
          case 'createdAt':
            this[key] = new Date(value as string);
            break;
          default:
            (this as any)[key] = value;
            break;
        }
      });
    }
    // this.timestamp = this.updatedAt ? this.updatedAt.getTime() : 0;
  }

  resetEventCount() {
    this.newEvents = 0;
    // save to store
    //STORAGE.write({ key: `CHAT${this.id}`, value: { newEvents: this.newEvents } });
  }

  async registerChatEvent(chatEvent: ChatMessageEventPayload) {
    const [chatId, type, timestamp, userId, messageId, _, messageData] = chatEvent;
    const now = timestamp ? new Date() : new Date(timestamp as number);
    if (chatId === this.id) {
      this.newEvents++;
      this.updatedAt = now; // update chat timestamp
      this.timestamp = now.getTime();
      if (type === ChatEventType.NEW_MESSAGE && messageId) {
        const updatedMessages = [
          ...this.messages,
          {
            id: messageId,
            user: { name: messageData?.user.name, id: messageData?.user.id },
            message: messageData?.message,
            files: messageData?.files,
            userId,
            createdAt: now,
            updatedAt: now,
          } as ChatMessage,
        ].sort((a, b) => b.id - a.id);
        this.messages = updatedMessages;
        chatEventStore.sortChatList(chatEventStore.chatList);
        if (chatEventStore.chatNotificationStatus === "EMPTY") {
          chatEventStore.setNotificationStatus('PENDING');
        }
      } else if (type === ChatEventType.USER_JOINED && userId) {
        // add new user placeholder, will be updated later by getChatUsers
        this.users.push({ id: userId, chatId, createdAt: now, updatedAt: now, name: messageData?.user.name || "...." } as ChatUser);
      } else if (type === ChatEventType.USER_LEFT && userId) {
        // remove user, will be updated later by getChatUsers
        this.users = this.users.filter(user => user.id !== userId);
      }
    }
  }
}

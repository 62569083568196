/* tslint:disable */
import { DateTime } from 'luxon';

const ABC = 'abcdefghijklmnopqrstuvwxyz';

export const filterByAllowedChars =
  (allowChars?: string) =>
  (groupStr: string = '') => {
    if (!allowChars) {
      return true;
    }
    return allowChars.includes(groupStr[0]);
  };

export const toCharGroups = (str: string = '', allowChars?: string) => {
  const strArr = Array.from(str);
  return strArr
    .reduce((acc: Array<string>, chr: string) => {
      if (!acc.length) {
        return [chr];
      }
      const reversedAcc = [...acc];
      const [lastGroup, ...restAcc] = reversedAcc;
      const lastChar = lastGroup && lastGroup[0];
      const isSame = chr === lastChar;
      const newAcc = isSame ? [`${lastGroup}${chr}`, ...restAcc] : [chr, lastGroup, ...restAcc];
      return newAcc;
    }, [])
    .filter(filterByAllowedChars(allowChars))
    .reverse();
};

export const isNumberString = (val: string) => val !== ' ' && Number.isFinite(Number(val));

export const toNumberGroups = (str: string) => {
  const strArr = Array.from(str);
  return strArr
    .reduce((acc: Array<string>, chr: string) => {
      if (!acc.length) {
        return [chr];
      }
      const reversedAcc = [...acc];
      const [lastGroup, ...restAcc] = reversedAcc;
      const lastChar = lastGroup && lastGroup[0];
      const isSame = isNumberString(chr) === isNumberString(lastChar);
      const newAcc = isSame ? [`${lastGroup}${chr}`, ...restAcc] : [chr, lastGroup, ...restAcc];
      return newAcc;
    }, [])
    .filter(filterByAllowedChars('1234567890'))
    .reverse();
};
export const _extractDateValue = (dateString: string, format: string) => {
  const formatCharGroups = toCharGroups(format, `${ABC}${ABC.toUpperCase()}`);
  const dateStringGroups = toNumberGroups(dateString);

  const minLength = Math.min(formatCharGroups.length, dateStringGroups.length);

  const _formatCharGroups = formatCharGroups.slice(0, minLength);
  const _dateStringGroups = dateStringGroups.slice(0, minLength);

  const _format = _formatCharGroups.join('-');
  const _dateString = _dateStringGroups.join('-');

  const dateTime = DateTime.fromFormat(_dateString, _format);

  return dateTime.toFormat(format);
};
export const splitTimeCanonicalFormat = (timeString: string) => {
  return timeString.split(':');
};
export const splitTimeShortFormat = (timeString: string) => {
  if (!timeString || !timeString.length) {
    return ['0', '00'];
  }
  if (timeString.length <= 2) {
    return [timeString, '00'];
  }
  if (timeString.length === 3) {
    const hours = timeString.slice(0, 1);
    const minutes = timeString.slice(-2);
    return [hours, minutes];
  }

  const hours = timeString.slice(0, 2);
  const minutes = timeString.slice(2, 4);
  return [hours, minutes];
};
export const splitTimeToHoursAndMinutes = (timeString: string) => {
  // Proper format for time
  if (timeString.indexOf(':') > -1) {
    return splitTimeCanonicalFormat(timeString);
  }

  // Handle shortcut format without colon character
  return splitTimeShortFormat(timeString);
};
export const _extractTimeValue = (timeString: string, format: string) => {
  const [hoursString, minutesString] = splitTimeToHoursAndMinutes(timeString);
  const hours = hoursString.slice(-2);
  const minutes = minutesString.slice(0, 2);

  const _timeString = `${hours}:${minutes}`;

  const dateTime = DateTime.fromFormat(_timeString, 'H:mm');

  return dateTime.toFormat(format);
};

export const extractDateValue = (timeString: string, format: string, showTimeSelectOnly: boolean) => {
  return !!showTimeSelectOnly ? _extractTimeValue(timeString, format) : _extractDateValue(timeString, format);
};

import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';
import Label from './Label';

const Container = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;

  label {
    white-space: nowrap;
    margin-left: ${p => p.theme.spacings.md};
    margin-right: ${p => p.theme.spacings.md};
    user-select: none;
    color: ${p => (p.isDisabled ? p.theme.colors.grayDarker : 'default')};
  }
`;

interface Props {
  id: string;
  checked: boolean;
  label: string;
  onChange?: (event: ChangeEvent<any>) => void;
  setValue?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}

export const Checkbox: FC<Props> = ({ id, checked, label, onChange, setValue, disabled = false }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (setValue) return setValue(event.currentTarget.checked);
  };

  return (
    <Container isDisabled={disabled}>
      <input type="checkbox" id={id} data-testid={id} checked={checked} onChange={onChange ?? handleChange} disabled={disabled} />
      <Label htmlFor={id}>{label}</Label>
    </Container>
  );
};

export default Checkbox;

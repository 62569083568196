import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getBlankShift } from '.';
import { Button, DatePicker } from '../../../../components/inputs';
import Label from '../../../../components/inputs/Label';
import { shiftStore } from '../../../../mobx/shiftStore';
import { dateToString } from '../../../../utils/date';
import { ReserveTo } from '../ReserveTo';
import ShiftEditing from './ShiftEditing';
import { ShiftSetContainer } from './styledComponents';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  > Label {
    margin-right: 10px;
  }
`;

const ShiftSetSettings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .shift-set-employee {
    width: 500px;
    margin-right: 10px;
  }
  .shift-set-close-reservation {
    width: 215px;
    margin-right: 10px;
  }

  @media ${p => p.theme.breakpoints.mobile} {
    display: block !important;
    .shift-set-employee {
      width: 215px;
      margin-right: 10px;
    }
  }
`;

const DeleteButtonContainer = styled.div<{ isFirst: boolean }>`
  padding-top: ${p => (p.isFirst ? '30px' : '0px')};
`;

const ShiftSetStatusContainer = styled.div`
  margin-top: ${p => p.theme.spacings.sm};
  max-width: 350px;
  div {
    margin-top: 2px !important;
  }
`;

const ShiftSetStatus = styled.div`
  margin-top: 30px;
  margin-right: 10px;
  padding: ${p => p.theme.spacings.sm} ${p => p.theme.spacings.md};
  background-color: ${p => p.theme.colors.grayLighter};
`;

interface Props {
  shiftSet: Partial<IShiftSetData>;
  shiftSetIndex: number;
  onChange: (updatedSet: Partial<IShiftSetData>) => void;
  onSaveDefaultTimePeriod?: (timePeriod: any) => void;
  fieldDefaults?: any;
  onDelete: () => void;
}

const ShiftSetEditing: FC<Props> = ({ shiftSet, onChange, onSaveDefaultTimePeriod, fieldDefaults, onDelete, shiftSetIndex }) => {
  const { t } = useTranslation();
  const { shifts = [] } = shiftSet;
  const [reservedTo, setReservedTo] = useState<SelectedOption<number | undefined>>(
    shiftSet.reservedTo ? { label: shiftSet.reservedTo.name, value: shiftSet.reservedTo.id } : undefined
  );
  const [maxCloseDateAndTime, setMaxCloseDateAndTime] = useState<Date>();
  const closeReservation = shiftSet.closeReservation ? shiftSet.closeReservation : undefined;

  useEffect(() => {
    const earliestShift = new Date(Math.min(...shifts.map(e => e.startTime?.getTime() || 0)));
    setMaxCloseDateAndTime(earliestShift);

    if (closeReservation && maxCloseDateAndTime) {
      onChange({
        ...shiftSet,
        closeReservation: earliestShift,
      });
    }
  }, [shifts]);

  const handleClosingDateChange = (newDate: Date | [Date, Date] | null) => {
    onChange({
      ...shiftSet,
      closeReservation: (Array.isArray(newDate) ? closeReservation : newDate) || undefined,
    });
  };

  const handleAddNewShift = () => {
    // Initialize new shift with values computed from latest shift
    const latestShift = shifts.length ? shifts[shifts.length - 1] : undefined;
    const startDate = latestShift && latestShift.startTime && DateTime.fromJSDate(latestShift.startTime).plus({ days: 1 }).toJSDate();
    const endDate = latestShift && latestShift.endTime && DateTime.fromJSDate(latestShift.endTime).plus({ days: 1 }).toJSDate();
    const shiftKey = latestShift?.shiftKey;
    const newShift: IShiftData = {
      ...getBlankShift(startDate, endDate, shiftKey),
      index: shifts.length,
    };
    onChange({
      ...shiftSet,
      shifts: [...shifts, newShift],
    });
  };

  const handleRemoveShift = (index: number) => {
    const { shifts = [], ...rest } = shiftSet;
    onChange({
      ...rest,
      shifts: shifts.filter((s, i) => i !== index),
    });
  };

  const handleShiftUpdate = (index: number, updatedShift: IShiftData) => {
    const { shifts = [], ...rest } = shiftSet;
    onChange({
      ...rest,
      shifts: shifts.map((shift, i) => (i === index ? updatedShift : shift)),
    });
  };

  const updateReservedTo = (option: SelectOption<number>) => {
    setReservedTo(option);

    const value = option ? option.value : null;
    if (shiftSet) {
      onChange({
        ...shiftSet,
        reservedToId: value,
      });
    }
  };

  const closeReservationDay = closeReservation?.getDay();
  const closeReservationMonth = closeReservation?.getMonth();
  const maxCloseTimeDay = maxCloseDateAndTime?.getDay();
  const maxCloseTimeMonth = maxCloseDateAndTime?.getMonth();
  const maxTime =
    closeReservationDay === maxCloseTimeDay && closeReservationMonth === maxCloseTimeMonth
      ? maxCloseDateAndTime
      : new Date(new Date().setHours(23, 59, 59, 999));
  const hasMoreThanOneShiftSet = shiftStore?.unsavedShiftSetData?.shiftSets && shiftStore?.unsavedShiftSetData?.shiftSets?.length > 1;
  const hasMoreThanOneShift = shifts.length > 1;

  return (
    <ShiftSetContainer>
      <Header>
        <Label>
          {t('shiftSet').toUpperCase()} ({shiftSetIndex + 1})
        </Label>
      </Header>
      {hasMoreThanOneShiftSet && <Button id={`remove-shift-set-button`} text={t('deleteShiftSet')} variant="mini" isSmall onClick={onDelete} />}
      <ShiftSetStatusContainer>
        {shiftSet?.deleted && <ShiftSetStatus>{t('shift-set-deleted')}</ShiftSetStatus>}
        {shiftSet?.createdBy && (
          <ShiftSetStatus>
            {t('createdBy')}: {shiftSet?.createdBy}
          </ShiftSetStatus>
        )}

        {shiftSet?.createdAt && (
          <ShiftSetStatus>
            {t('createdAt')}: {dateToString(shiftSet?.createdAt, 'yyyy-MM-dd HH:mm:ss')}
          </ShiftSetStatus>
        )}
        {shiftSet?.updatedAt && (
          <ShiftSetStatus>
            {t('updatedAt')}: {dateToString(shiftSet?.updatedAt, 'yyyy-MM-dd HH:mm:ss')}
          </ShiftSetStatus>
        )}
        {shiftSet?.reservedAt && shiftSet.reservedToId && (
          <ShiftSetStatus>
            {t('reservedAt')}: {dateToString(shiftSet?.reservedAt, 'yyyy-MM-dd HH:mm:ss')}
          </ShiftSetStatus>
        )}
        {shiftSet?.reservedBy && shiftSet.reservedToId && (
          <ShiftSetStatus>
            {t('reservedBy')}: {shiftSet?.reservedBy?.name}
          </ShiftSetStatus>
        )}

        {shiftSet?.taskPrice && (
          <ShiftSetStatus>
            <div>
              <small>
                {' '}
                {t('taskPrice')}: {shiftSet?.taskPrice?.Task?.ToString} / {shiftSet?.taskPrice?.Employee?.ToString}
              </small>
            </div>

            <div>
              <small>
                {dateToString(new Date(shiftSet?.taskPrice?.StartTime), 'yyyy-MM-dd')} - {dateToString(new Date(shiftSet?.taskPrice?.EndTime), 'yyyy-MM-dd')}
              </small>
            </div>
          </ShiftSetStatus>
        )}
      </ShiftSetStatusContainer>

      <ShiftSetSettings>
        <div className="shift-set-employee">{shiftSet.shifts?.length && <ReserveTo reservedTo={reservedTo} setReservedTo={updateReservedTo} />}</div>
        {!shiftSet?.reservedToId && (
          <div className="shift-set-close-reservation">
            <DatePicker
              id={`shiftset-${shiftSet.index}-close-reservation-date`}
              label={t('closeReservation')}
              date={closeReservation || undefined}
              onChange={handleClosingDateChange}
              placement="bottom-end"
              minDate={new Date()}
              showTimeSelect
              isClearable
              maxDate={maxCloseDateAndTime}
              maxTime={maxTime}
            />
          </div>
        )}
      </ShiftSetSettings>

      <div className="shift-container">
        <div className="shift-details-column">
          {shifts?.map((shift, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div className="shift-details-row shift-details-row__time-details">
                <ShiftEditing
                  noLabels={index > 0}
                  onChange={updatedShift => handleShiftUpdate(index, updatedShift)}
                  shift={shift as IShiftData}
                  shiftSetIndex={shiftSetIndex}
                />
              </div>
              {hasMoreThanOneShift && (
                <DeleteButtonContainer isFirst={index === 0}>
                  <Button id={`remove-shift-set-button`} text={t('deleteShift')} variant="mini" isSmall onClick={() => handleRemoveShift(index)} />
                </DeleteButtonContainer>
              )}
            </div>
          ))}

          <Button
            id={`shiftset-${shiftSet.index}-new-shift-button`}
            text={`+ ${t('addShift')}`}
            onClick={handleAddNewShift}
            variant="outlined"
            color="primary"
            isSmall
          />
        </div>
      </div>
    </ShiftSetContainer>
  );
};
export default ShiftSetEditing;

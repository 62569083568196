import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as Attention } from '../assets/icons/attention-sign.svg';
import { ReactComponent as BarChart } from '../assets/icons/bar-chart.svg';
import { ReactComponent as CalendarClock } from '../assets/icons/calendar.svg';
import { ReactComponent as Phone } from '../assets/icons/call.svg';
import { ReactComponent as Chat } from '../assets/icons/chat.svg';
import { ReactComponent as Checked } from '../assets/icons/checked.svg';
import { ReactComponent as Edit } from '../assets/icons/edit.svg';
import { ReactComponent as Hidden } from '../assets/icons/hidden.svg';
import { ReactComponent as History } from '../assets/icons/history-clock-button.svg';
import { ReactComponent as Home } from '../assets/icons/home-icon-silhouette.svg';
import { ReactComponent as Help } from '../assets/icons/information.svg';
import { ReactComponent as Add } from '../assets/icons/ios-add.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/ios-arrow-down.svg';
import { ReactComponent as ArrowUp } from '../assets/icons/ios-arrow-up.svg';
import { ReactComponent as Calendar } from '../assets/icons/ios-calendar.svg';
import { ReactComponent as Close } from '../assets/icons/ios-close.svg';
import { ReactComponent as Info } from '../assets/icons/ios-information-circle.svg';
import { ReactComponent as Search } from '../assets/icons/ios-search.svg';
import { ReactComponent as Lock } from '../assets/icons/md-lock.svg';
import { ReactComponent as Person } from '../assets/icons/md-person.svg';
import { ReactComponent as Clock } from '../assets/icons/md-time.svg';
import { ReactComponent as Message } from '../assets/icons/message.svg';
import { ReactComponent as ArrowRight } from '../assets/icons/right-arrow.svg';
import { ReactComponent as Group2 } from '../assets/icons/ryhma.svg';
import { ReactComponent as Group } from '../assets/icons/teamwork.svg';
import { Colors } from '../theme/styled';

const Icons = {
  Add,
  ArrowUp,
  ArrowDown,
  ArrowRight,
  Attention,
  BarChart,
  Calendar,
  CalendarClock,
  Chat,
  Checked,
  Clock,
  Close,
  Edit,
  Group,
  Group2,
  Help,
  Hidden,
  History,
  Home,
  Info,
  Lock,
  Message,
  Person,
  Phone,
  Search,
};

export type IconType = keyof typeof Icons;

interface Props {
  type: IconType;
  color?: keyof Colors;
  width?: number;
}

export const Icon: FC<Props> = ({ type, color = 'text', width = 18 }) => {
  const themeContext = useContext(ThemeContext);
  const fill = themeContext.colors[color];
  const iconProps = {
    width,
    fill,
  };
  const IconComponent = Icons[type];
  return <IconComponent {...iconProps} />;
};

import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { adminStore } from '../../mobx/adminStore';
import { Icon } from '../../components/Icon';
import Table, { ColumnType, ItemActionProps } from '../../components/Table';
import { Input, Button } from '../../components/inputs';
import { View, SearchFields } from '../../components/View';
import { adminRoutes } from '../../routes/routes';
import { Loader } from '../../components/Loader';

const renderEmployee = ({ employee }: ComparedLikeitUserData) => {
  if (!employee) {
    return <>-</>;
  }
  return <>{employee.Id}</>;
};

const renderPersonGroups = ({ personGroups }: ComparedUserData) => {
  if (!personGroups) {
    return <>-</>;
  }
  return <pre>{personGroups.map(({ name }) => name).join('\n')}</pre>;
};

export const Users: FC = observer(() => {
  const { t } = useTranslation();
  const { status, compareLikeitUserResult } = adminStore;
  const [startSearch, setStartSearch] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<number>();

  const liketUserTable: ColumnType<ComparedLikeitUserData>[] = [
    {
      key: 'Id',
      label: 'likeitUserId',
      customRender: ({ Id }) =>
        compareLikeitUserResult && compareLikeitUserResult.users.find(u => u.likeitUserId === Id) ? (
          <b>
            {Id} 👈{t('in-use')}
          </b>
        ) : (
          <>{Id}</>
        ),
    },
    { key: 'employee', label: 'likeitEmployeeId', customRender: renderEmployee },
    { key: 'EmailAddress', label: 'email' },
    {
      key: 'employee',
      label: 'phone',
      customRender: ({ employee }) => employee?.MobileNumber || '-',
    },
    { key: 'FirstName', label: 'firstName' },
    { key: 'LastName', label: 'lastName' },
  ];

  const liketUserTableRowActions: ItemActionProps<ComparedLikeitUserData>[] = [
    {
      label: `${t('use-as-application-user')}:${selectedUser}`,
      icon: <Icon type="Edit" color="primary" />,
      onClick: r => makeActive(r),
      getDisabled: r => !compareLikeitUserResult || !compareLikeitUserResult.users.find(u => u.id === selectedUser),
    },
  ];

  const userTable: ColumnType<ComparedUserData>[] = [
    { key: 'likeitUserId' },
    { key: 'likeitEmployeeId' },
    { key: 'email' },
    { key: 'phone' },
    { key: 'organizationName' },
    { key: 'id', customRender: ({ id }) => <b>{id}</b> },
    { key: 'role' },
    { key: 'firstName' },
    { key: 'lastName' },
    { key: 'personGroups', customRender: renderPersonGroups },
  ];

  const userTableRowActions: ItemActionProps<ComparedUserData>[] = [
    {
      label: t('select-user'),
      icon: <Icon type="Edit" color="primary" />,
      onClick: r => setSelectedUser(r.id),
    },
  ];

  const makeActive = async (r: ComparedLikeitUserData) => {
    const likeitUserId = r.Id;
    const user = compareLikeitUserResult && compareLikeitUserResult.users && compareLikeitUserResult.users.find(u => u.id === selectedUser);
    if (user) {
      const updated = await adminStore.syncSingleUser({ userId: user.id, likeitUserId });
      adminStore.compareLikeitUser({ email: updated.email });
    }
  };

  useEffect(() => {
    if (status !== 'BUSY' && startSearch) {
      setStartSearch(false);
      adminStore.compareLikeitUser({ email, firstName, lastName, phone });
    }
  }, [status, startSearch, email, firstName, lastName]);

  return (
    <View routes={adminRoutes}>
      {status === 'BUSY' && <Loader isFullScreen />}
      <SearchFields>
        <Input id="search-users-by-email" label={t('email')} value={email} onChange={e => setEmail(e.target.value)} />
        <Input id="search-users-by-firstname" label={t('firstName')} value={firstName} onChange={e => setFirstName(e.target.value)} />
        <Input id="search-users-by-firstname" label={t('lastName')} value={lastName} onChange={e => setLastName(e.target.value)} />
        <Input id="search-users-by-phone" label={t('phoneNumber')} value={phone} onChange={e => setPhone(e.target.value)} />
      </SearchFields>
      <Button
        id="search-users-button"
        text={t('search')}
        disabled={!email && !(firstName && lastName) && !phone}
        onClick={() => setStartSearch(true)}
        color="primary"
      />

      {compareLikeitUserResult && (
        <>
          <div>
            <h3>{t('application-user')}</h3>
            <Table<ComparedUserData> data={compareLikeitUserResult.users} columns={userTable} itemActions={userTableRowActions} />
          </div>
          <div>
            <h3>{t('like-it-users')}</h3>
            <Table<ComparedLikeitUserData> data={compareLikeitUserResult.likeitUsers} columns={liketUserTable} itemActions={liketUserTableRowActions} />
          </div>
        </>
      )}
    </View>
  );
});

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../components/Icon';
import Table, { ColumnType, ItemActionProps } from '../../components/Table';
import { dateToString } from '../../utils/date';
interface Props {
  total?: number;
  results?: MessageLog[];
  onClick?: (item: MessageLog) => void;
}

const renderRecipient = ({ user, personGroup, type }: MessageLog) => (
  <>
    {user && (
      <>
        <div>
          {user?.firstName} {user.lastName}
        </div>
        <div>{type === 'email' ? user.email : user.phone}</div>
      </>
    )}
    {personGroup && (
      <>
        <div>{personGroup.name}</div>
      </>
    )}
  </>
);

const renderContent = ({ title, content, createdAt, sender }: MessageLog) =>
  sender ? (
    <>
      <div>{dateToString(new Date(createdAt), 'd.M.yyyy')}</div>
      <div>
        <b>{title}</b>
      </div>
      <div>{content?.slice(0, 40)}...</div>
      {sender && (
        <div>
          {' '}
          {sender.firstName} {sender.lastName}
        </div>
      )}
    </>
  ) : (
    <>{content?.slice(0, 40)}</>
  );

const renderSent = ({ error, sent }: MessageLog) => (
  <>
    {sent && <div>{dateToString(new Date(sent), 'd.M.yyyy')}</div>}
    {error && <div>{error}</div>}
  </>
);

export const MessageLogResult: FC<Props> = ({ total, results, onClick }) => {
  const { t } = useTranslation();
  if (!results) {
    return null;
  }

  const tableRowActions: ItemActionProps<MessageLog>[] = [{ label: t('view'), icon: <Icon type="Info" color="primary" />, onClick }];

  const tableColumns: ColumnType<MessageLog>[] = [{ key: 'type' }, { key: 'user', customRender: renderRecipient }, { key: 'content', customRender: renderContent }, { key: 'sent', align: 'center', customRender: renderSent }];

  return <Table data={results} itemActions={tableRowActions} columns={tableColumns} />;
};

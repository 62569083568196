import { observer } from 'mobx-react';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { Loader } from '../../components/Loader';
import { View } from '../../components/View';
import { teamStore } from '../../mobx/teamStore';
import { adminRoutes } from '../../routes/routes';

export const Teams: FC = observer(() => {
  const { status, teams } = teamStore;
  const [selectedTeamId, setSelectedTeamId] = useState<number>();
  const [newName, setNewName] = useState<string>();

  useEffect(() => {
    teamStore.getTeams({ skip: 0, take: 100 });
  }, []);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const updateName = async () => {
    if (selectedTeamId && newName !== undefined) {
      await teamStore.updateTeamProps(selectedTeamId, { name: newName });
      teamStore.getTeams({ skip: 0, take: 100 });
    }
  };

  return (
    <View routes={adminRoutes}>
      {status === 'BUSY' && <Loader isFullScreen />}
      {teams?.length && (
        <table>
          <tr>
            <th>Id</th>
            <th>Team Name</th>
            <th>Team Lead</th>
          </tr>
          {teams.map(({ teamId, name, responsiblePerson }) => (
            <tr
              onClick={() => {
                setNewName(name);
                setSelectedTeamId(teamId);
              }}
            >
              <td>{teamId}</td>
              <td>{selectedTeamId === teamId ? <input type="text" value={newName || name} onChange={onChangeName} onBlur={updateName} /> : <>{name !== responsiblePerson?.name ? <b>{name}</b> : name}</>}</td>
              {responsiblePerson && (
                <>
                  <td>
                    {responsiblePerson.name} {}
                  </td>
                </>
              )}
            </tr>
          ))}
        </table>
      )}
    </View>
  );
});

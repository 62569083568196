import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { SingleValue } from 'react-select';
import Async from 'react-select/async';

import { orgStore } from '../mobx/orgStore';
import { shiftStore } from '../mobx/shiftStore';
import { Label, SelectContainer } from './inputs';
import { ShowErrors } from './ShowErrors';
import { ShowTeam } from './ShowTeam';

interface OrgSelect {
  id: number;
  pid?: number;
  value?: SelectOption<number | undefined> | null;
  data?: { address: string; name: string; phone: string };
  options: SelectOption<number | undefined>[];
  isOpen?: boolean;
}

interface Props {
  onSelectOrganization?: (selection: { unit: any; department: any; organizationFullName: string; organization?: any[] }) => void;
  onSelectTask?: Function;
  preSelectedOrganization?: SelectOption<number | undefined>;
  preSelectedTask?: SelectOption<number | undefined>;
  mode?: 'generalSettings';
}

export const SelectOrganization: FC<Props> = observer(({ onSelectOrganization, onSelectTask, preSelectedOrganization, preSelectedTask, mode }) => {
  const { t } = useTranslation();
  const { status, customerTasks } = orgStore;
  const [selects, setSelects] = useState<OrgSelect[]>([{ id: -1, options: [] }]);
  const [selectedTask, setSelectedTask] = useState<SelectOption<number | undefined> | undefined | null>();
  const taskOptions: SelectOption<number | undefined>[] = customerTasks?.Data?.map(({ Name, Id }) => ({ label: Name, value: Id })) || [];
  const onChange = (id: number, initial?: boolean) => async (option: SingleValue<SelectOption<number | undefined>>) => {
    if (option?.value) {
      const newSelects = [];
      const populated = await orgStore.treeSearch({ Id: option.value, populate: true }, initial);
      if (populated?.Data?.[0]?.Id === option.value) {
        const {
          children,
          Id,
          Address,
          Name,
          ContactInfo: { PhoneNumber },
        } = populated.Data[0];
        let { parent } = populated.Data[0];
        newSelects.push({
          id: Id,
          pid: parent?.Id,
          value: option,
          data: { address: `${Address?.Address}\n${Address?.ZipCode} ${Address.City}`, name: Name, phone: PhoneNumber },
          options: children?.map(c => ({ label: c.Name, value: c.Id })) || [],
          isOpen: !initial && Boolean(children?.length),
        });
        while (parent) {
          newSelects.unshift({
            id: parent.Id,
            pid: parent.parent?.Id,
            data: { address: `${parent.Address?.Address}\n${parent.Address?.ZipCode} ${parent.Address.City}`, name: parent.Name, phone: parent.ContactInfo.PhoneNumber },
            value: { label: parent.Name, value: parent.Id },
            options: selects.find(({ id }) => id === parent.Id)?.options || [{ label: parent.Name, value: parent.Id }],
          });
          parent = parent.parent;
        }
      }
      setSelects(newSelects);
    } else {
      setSelectedTask(null); // clear task because it can be false
      selects.some((s, idx) => {
        if (s.id === id) {
          if (idx === 0) {
            // start over
            setSelects([{ id: -1, options: [] }]);
          } else {
            setSelects(selects.slice(0, idx));
          }
          return true;
        }
      });
    }
  };

  const loadOptions = (id: number) => async (Name_CONTAINS: string) => {
    const existing = selects.find(s => s.id === id);
    const ParentOrganizationId = existing?.pid;
    const result = await orgStore.treeSearch({ Name_CONTAINS, ParentOrganizationId });
    if (result?.Data?.length) {
      return result.Data.map(({ Id, Name }) => ({ label: `${Name}`, value: Id }));
    }
    return [];
  };

  if (onSelectTask) {
    useEffect(() => {
      if (status !== 'BUSY' && status !== 'ERROR' && selects) {
        // fetch selects
        const customerId = selects[selects.length - 1]?.id;
        if (customerId && customerId > -1) {
          orgStore.getTasksByCustomerId(customerId);
        }
      }
    }, [selects]);
  }

  useEffect(() => {
    if (onSelectOrganization && selects[0] && selects[0].id !== -1) {
      if (mode === 'generalSettings') {
        const { phone, name, address } = selects[selects.length - 1].data || {};
        onSelectOrganization({
          unit: selects[0].value,
          department: selects[selects.length - 1].value,
          organizationFullName: name || '',
          organization: selects?.map(({ data }) => data) || [],
        });
      } else {
        onSelectOrganization({
          unit: selects[0].value,
          department: selects[selects.length - 1].value,
          organizationFullName: selects.map(({ data }) => data?.name).join(';'),
        });
      }
    }
    if (onSelectTask && selectedTask?.value) {
      onSelectTask({
        task: selectedTask,
      });
    }
  }, [selectedTask, selects]);

  useEffect(() => {
    if (status == 'IDLE' && !selectedTask && preSelectedOrganization?.value && selects.find(s => s.id === preSelectedOrganization.value) === undefined) {
      setSelectedTask(preSelectedTask);
      onChange(preSelectedOrganization.value, true)(preSelectedOrganization);
    }
  }, [preSelectedOrganization]);

  useEffect(() => {
    // clear task if the highest level of organization is changed
    if (status === 'FETCHED') setSelectedTask(null);
  }, [selects[0]]);
  return (
    <>
      <ShowTeam />
      <SelectContainer>
        <ShowErrors property="common.departmentLikeitId" errorDetails={shiftStore.errorDetails}>
          <Label>{t('organization')}</Label>
          {selects.map(({ id, value, options, isOpen }) => (
            <Async
              isLoading={status === 'BUSY'}
              key={`${id}-async`}
              id={`${id}-async`}
              placeholder={t('select-organization')}
              defaultOptions={options}
              value={value}
              defaultMenuIsOpen={isOpen}
              onChange={onChange(id)}
              loadOptions={loadOptions(id)}
              noOptionsMessage={() => t('no-search-results')}
              isClearable={true}
              isSearchable={true}
              components={options.length ? undefined : { DropdownIndicator: () => null }}
            />
          ))}
        </ShowErrors>
      </SelectContainer>
      {Boolean(onSelectTask) && (
        <SelectContainer>
          <ShowErrors property="common.likeitTaskId" errorDetails={shiftStore.errorDetails}>
            <Label>{t('task')}</Label>
            <ReactSelect value={selectedTask} noOptionsMessage={() => t('noOptions')} onChange={setSelectedTask} placeholder={t('select-task')} options={taskOptions} />
          </ShowErrors>
        </SelectContainer>
      )}
    </>
  );
});

import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Input, Select } from '../../../components/inputs';
import { SelectOrganization } from '../../../components/SelectOrganization';
import { ShowErrors } from '../../../components/ShowErrors';
import { shiftStore } from '../../../mobx/shiftStore';

const Container = styled.div`
  div.info {
    float: right;
  }

  div.columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media ${p => p.theme.breakpoints.mobile} {
      flex-direction: column;
    }

    > div.column {
      flex: 1;
      :not(:first-child) {
        margin-left: ${p => p.theme.spacings.md};
        @media ${p => p.theme.breakpoints.mobile} {
          margin-left: 0;
        }
      }
      :not(:last-child) {
        margin-right: ${p => p.theme.spacings.md};
        @media ${p => p.theme.breakpoints.mobile} {
          margin-right: 0;
        }
      }
    }
  }
`;

const OrgContactSelector = styled.div<{ savedValueText: string }>`
  .css-4ljt47-MenuList {
    div {
      display: flex;
      justify-content: space-between;
    }
    div:first-child::after {
      font-size: ${p => p.theme.fonts.size.sm};
      content: '${p => p.savedValueText}';
    }
  }
`;

export const GeneralSettings: FC = observer(() => {
  const [busyLoading, setBusyLoading] = useState<boolean>(false);
  const [busySaving, setBusySaving] = useState<boolean>(false);
  const [editOrganizationAddress, setEditOrganizationAddress] = useState(false);
  const [editOrganizationPhone, setEditOrganizationPhone] = useState(false);

  const [orgAddressOptions, setOrgAddressOptions] = useState<SelectOption<number>[]>([{ label: '', value: 0 }]);
  const [selectedAddressOption, setSelectedAddressOption] = useState<number | undefined>(0);

  const [orgPhoneOptions, setOrgPhoneOptions] = useState<SelectOption<number>[]>([{ label: '', value: 0 }]);
  const [selectedPhoneOption, setSelectedPhoneOption] = useState<number | undefined>(0);

  const { t } = useTranslation();
  const { unsavedShiftSetData, status: shiftStatus } = shiftStore;
  const noOrganizationSelected = !unsavedShiftSetData?.common?.unitLikeitId || !unsavedShiftSetData?.common?.departmentLikeitId;

  const commonShiftSetData = unsavedShiftSetData?.common;

  const isEditing = unsavedShiftSetData?.shiftSets && unsavedShiftSetData.shiftSets[0].id;
  useEffect(() => {
    if (shiftStatus === 'CHANGED ORGANIZATION' && !isEditing) {
      loadFieldDefaults();
    }
    if ((shiftStatus === 'FETCHED' || shiftStatus === 'CHANGED ORGANIZATION') && unsavedShiftSetData?.common) {
      // modified or loaded shift set
      // get addresses from list
      const { organization, organizationFullAddress, organizationPhone } = unsavedShiftSetData.common;
      // current returned organization structure as options
      const organizationAddressListOptions =
        organization
          ?.filter(({ address }) => address?.length > 3)
          .map(({ address }, idx) => ({ label: address.replaceAll('\n', ', ').replaceAll(';', ', '), value: idx + 1 })) || [];

      if (organizationFullAddress) {
        const addressFound = organizationAddressListOptions.find(
          e => e.label.trim().toLocaleLowerCase() === organizationFullAddress.trim().toLocaleLowerCase()
        );
        if (addressFound) {
          setSelectedAddressOption(addressFound.value);
        } else {
          organizationAddressListOptions.unshift({ label: organizationFullAddress, value: 0 });
          setSelectedAddressOption(0);
        }
      } else {
        setSelectedAddressOption(undefined);
      }
      setOrgAddressOptions(organizationAddressListOptions);

      // same for phone number
      const organizationPhoneListOptions =
        organization?.filter(({ phone }) => phone?.length > 3).map(({ phone }, idx) => ({ label: phone, value: idx + 1 })) || [];

      if (organizationPhone) {
        const phoneFound = organizationPhoneListOptions.find(e => e.label === organizationPhone);
        if (phoneFound) {
          setSelectedPhoneOption(phoneFound.value);
        } else {
          organizationPhoneListOptions.unshift({ label: organizationPhone, value: 0 });
          setSelectedPhoneOption(0);
        }
      } else {
        setSelectedPhoneOption(undefined);
      }
      setOrgPhoneOptions(organizationPhoneListOptions);
    }
  }, [shiftStatus, commonShiftSetData?.organizationFullAddress]);

  useEffect(() => {
    // if organization data doesn't contain proper addresses or phones, show input fields for address and phone
    if (shiftStatus !== 'CHANGED ORGANIZATION') return;
    if (commonShiftSetData?.organization?.every(org => !org.address || org.address.length < 3)) {
      setEditOrganizationAddress(true);
    }
    if (commonShiftSetData?.organization?.every(org => !org.phone || org.phone.length < 3)) {
      setEditOrganizationPhone(true);
    }
  }, [shiftStatus]);

  const saveFieldDefaults = async () => {
    setBusySaving(true);
    await shiftStore.saveFieldDefaults('GENERAL');
    setTimeout(() => setBusySaving(false), 100);
  };

  const loadFieldDefaults = async () => {
    setBusyLoading(true);
    await shiftStore.loadFieldDefaults('GENERAL');
    setTimeout(() => setBusyLoading(false), 100);
  };

  const preSelectedOrganization = commonShiftSetData?.department || commonShiftSetData?.unit || undefined;
  const preSelectedOrganizationOption = preSelectedOrganization ? { value: preSelectedOrganization.likeitId, label: preSelectedOrganization.name } : undefined;
  const preselectedTask = commonShiftSetData?.task || undefined;
  const preselectedTaskOption = preselectedTask ? { value: preselectedTask.likeitId, label: preselectedTask.name } : undefined;
  return (
    <Container>
      <h1>{t('shiftGeneralSettings')}</h1>

      {/* SELECT ORGANIZATION AND TASK */}

      <div className="columns">
        <div className="column">
          <SelectOrganization
            mode="generalSettings"
            preSelectedOrganization={preSelectedOrganizationOption}
            preSelectedTask={preselectedTaskOption}
            onSelectOrganization={({ unit, department, organizationFullName, organization }) => {
              shiftStore.change(
                {
                  common: {
                    ...commonShiftSetData,
                    unitLikeitId: unit.value,
                    unit: undefined,
                    departmentLikeitId: department.value,
                    department: undefined,
                    organizationFullName,
                    organization,
                    organizationFullAddress: organization?.[organization.length - 1]?.address || '',
                    organizationPhone: organization?.[organization.length - 1]?.phone || '',
                  },
                },
                true // organization was changed
              );
            }}
            onSelectTask={({ task }: any) => {
              shiftStore.change({ common: { ...commonShiftSetData, likeitTaskId: task.value } });
            }}
          />
        </div>

        {/* WRITE ORGANIZATION NAME */}

        <div className="column">
          <ShowErrors property="common.organizationFullName" errorDetails={shiftStore.errorDetails}>
            <Input
              id="shift-set-org-name"
              value={commonShiftSetData?.organizationFullName?.split(';').join('\n')}
              label={t('displayedOrganizationName')}
              onChange={({ target: { value } }) => shiftStore.change({ common: { ...commonShiftSetData, organizationFullName: value?.split('\n').join(';') } })}
              placeholder={t('inputPlaceholder.organizationName')}
              asTextarea
            />
          </ShowErrors>

          {/* SELECT OR WRITE ORGANIZATION ADDRESS */}

          <ShowErrors property="common.organizationFullAddress" errorDetails={shiftStore.errorDetails}>
            {!editOrganizationAddress && (
              <>
                <Select
                  id="organization-address"
                  label={t('displayedOrganizationAddress')}
                  options={orgAddressOptions}
                  selected={orgAddressOptions?.find(({ value }) => value === selectedAddressOption) || 0}
                  onChange={o => {
                    setSelectedAddressOption(o.value);
                    shiftStore.change({ common: { ...commonShiftSetData, organizationFullAddress: o.label } });
                  }}
                  isClearable={false}
                />
                <Button
                  isSmall
                  text={t('edit organization address')}
                  color="primary"
                  variant="mini"
                  id="edit-organization-address"
                  onClick={() => setEditOrganizationAddress(!editOrganizationAddress)}
                />
              </>
            )}
            {editOrganizationAddress && (
              <>
                <Input
                  id="shift-set-org-address"
                  value={commonShiftSetData?.organizationFullAddress?.split(';').join('\n')}
                  label={t('edit organization address')}
                  onChange={({ target: { value } }) => shiftStore.change({ common: { ...commonShiftSetData, organizationFullAddress: value } })}
                  placeholder={t('inputPlaceholder.organizationAddress')}
                  asTextarea
                />
                <Button
                  isSmall
                  text={t('select from list')}
                  color="primary"
                  variant="mini"
                  id="cancel-edit-organization-address"
                  onClick={() => setEditOrganizationAddress(!editOrganizationAddress)}
                />
              </>
            )}
          </ShowErrors>

          {/* SELECT OR WRITE ORGANIZATION PHONE */}

          {!editOrganizationPhone && (
            <>
              <Select
                id="organization-phone"
                label={t('displayedOrganizationPhone')}
                options={orgPhoneOptions}
                selected={orgPhoneOptions?.find(({ value }) => value === selectedPhoneOption) || 0}
                onChange={o => {
                  setSelectedPhoneOption(o.value);
                  shiftStore.change({ common: { ...commonShiftSetData, organizationPhone: o.label } });
                }}
                isClearable={false}
              />
            </>
          )}

          {editOrganizationPhone && (
            <>
              <Input
                id="shift-set-org-phone"
                value={commonShiftSetData?.organizationPhone?.split(';').join('\n')}
                label={t('edit organization phone')}
                onChange={({ target: { value } }) => shiftStore.change({ common: { ...commonShiftSetData, organizationPhone: value } })}
                placeholder={t('inputPlaceholder.organizationPhone')}
                asTextarea
              />
              <Button
                isSmall
                text={t('select from list')}
                color="primary"
                variant="mini"
                id="cancel-edit-organization-phone"
                onClick={() => setEditOrganizationPhone(!editOrganizationPhone)}
              />
            </>
          )}

          {!editOrganizationPhone && (
            <Button
              isSmall
              text={t('edit organization phone')}
              color="primary"
              variant="mini"
              id="edit-organization-phone"
              onClick={() => setEditOrganizationPhone(!editOrganizationPhone)}
            />
          )}
        </div>
      </div>

      {/* WRITE DESCRIPTION */}

      <div className="columns">
        <div className="column">
          <Input
            id="new-shift-description"
            value={commonShiftSetData?.description}
            label={t('newShiftDescription')}
            onChange={({ target: { value } }) => shiftStore.change({ common: { ...commonShiftSetData, description: value } })}
            placeholder={t('inputPlaceholder.shiftDescription')}
            asTextarea
          />
          <Button
            id="shift-load-default"
            isSmall={true}
            disabled={noOrganizationSelected || busyLoading}
            variant="mini"
            text={t('load-default')}
            onClick={loadFieldDefaults}
          />
          <Button
            id="shift-save-default"
            isSmall={true}
            disabled={noOrganizationSelected || busySaving}
            variant="mini"
            text={t('save-default')}
            onClick={saveFieldDefaults}
          />
        </div>

        {/* WRITE NOTES */}

        <div className="column">
          <Input
            id="new-shift-notes"
            value={commonShiftSetData?.notes}
            label={t('notes')}
            onChange={({ target: { value } }) => shiftStore.change({ common: { ...commonShiftSetData, notes: value } })}
            placeholder={t('inputPlaceholder.shiftNotes')}
            asTextarea
          />
        </div>
      </div>
    </Container>
  );
});

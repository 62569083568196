import './notify.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { toast, ToastContainer, ToastOptions } from 'react-toastify';

import i18n from '../../i18n';

// Helper for notification-related translations:
const nt = (key: string, useLocalTranslation: boolean = true) => (useLocalTranslation ? i18n.t(`notification.${key}`) : key);

interface Options extends ToastOptions {}

export const notificationOptions: Options = {
  position: 'bottom-right',
  autoClose: 4000,

};

const notify = {
  error: (textKey: string, options?: Options, useLocalTranslation: boolean = true) => toast.error(nt(textKey, useLocalTranslation), options),
  success: (textKey: string, options?: Options, useLocalTranslation: boolean = true) => toast.success(nt(textKey, useLocalTranslation), options),
  info: (textKey: string, options?: Options, useLocalTranslation: boolean = true) => toast.info(nt(textKey, useLocalTranslation), options),
  close: (id?: string | number | undefined) => toast.dismiss(id)
};

export const NotificationContainer = ToastContainer;

export default notify;

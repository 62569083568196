import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon } from './Icon';
import { Button, LinkButton } from './inputs';

const StyledTable = styled.table<{ columnNames?: string[] }>`
  width: 100%;
  margin: 5px 0;
  font-size: ${p => p.theme.fonts.size.md};
  cursor: default;



  @media ${p => p.theme.breakpoints.mobile} {
    width: 100% !important;
    
		table, thead, tbody, th, td, tr {
			display: block;
      
		} 

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }


    ${p => p.columnNames?.map((name: string, i: number) => `td:nth-of-type(${i + 1}):before { content: "${name}"; }`) || ''}

    tr { 
      border: 1px solid ${p => p.theme.colors.grayDarkest};
    }

    td {
      border: none;
      border-bottom: 1px solid ${p => p.theme.colors.secondaryLightest};
      position: relative;
      padding-left: 30% !important;
      text-align: left;
    }

    td:before {
			position: absolute;
      text-align: right;
			top: 0;
			left: 6px;
			width: 28%;
			padding-right: 10px;
			white-space: nowrap;
      font-weight:bold;  
		}

  }


  .hover td a {
    color: black;
    background-color: red
    display: block;
  }

  tr:nth-child(even) {
    background-color: #edf5f8;
  }

  tr.hover:hover {
    outline: 1px solid ${p => p.theme.colors.grayDarkest};
  }

  tr.success {
    background-color: ${p => p.theme.colors.successBg};
  }

  tr.error {
    background-color: ${p => p.theme.colors.errorBg};
  }

  tr.hidden {
    background-color: ${p => p.theme.colors.grayDarker};
  }

  th {
    padding: ${p => p.theme.spacings.sm};
    border-bottom: 1px solid ${p => p.theme.colors.secondaryLightest};

    button {
      color: ${p => p.theme.colors.textAccent};
      border: none;
      padding: 0;
      display: inline-block;
    }

    button.sort {
      width: 80%;
    }

    button.order {
      width: 20%;
    }

    &.is-sort button {
      font-weight: bold;
    }
  }

  .hover td a,
  td {
    padding: 0 ${p => p.theme.spacings.sm};

    &.no-word-wrap {
      white-space: nowrap;

      @media ${p => p.theme.breakpoints.mobile} {
      white-space: normal;
      padding: 0 !important;

      display: flex;
      justify-content: center;
    
      }
    }
    &.is-accent {
      color: #25a5b5;
      font-weight: bold;
    }
  }

  td,
  th {
    &.align-left {
      text-align: left;
    }
    &.align-center {
      text-align: center;
    }
    &.align-right {
      text-align: right;
    }

  
  }
`;

export interface ColumnType<T> {
  key: keyof T;
  label?: string;
  isSortable?: boolean;
  isAccent?: boolean;
  noWrap?: boolean;
  align?: 'left' | 'center' | 'right';
  customRender?: (value: T) => JSX.Element | string;
}

export interface ItemActionProps<T> {
  label?: string;
  icon?: JSX.Element;
  makeLink?: Function;
  getDisabled?: (item: T) => boolean;
  onClick?: (item: T) => void;
}

interface Props<T> {
  sort?: string;
  order?: string;
  onChangeSortOrder?: Function;
  columns: ColumnType<T>[];
  data: T[];
  onClickRow?: (row: T) => void;
  linkRow?: (row: T) => string | undefined;
  itemActions?: ItemActionProps<T>[];
  getRowClass?: (row: T, i: number) => string;
  showHeader?: boolean;
}

const Table = <T,>({
  columns,
  data,
  itemActions,
  getRowClass,
  sort,
  order,
  linkRow,
  onClickRow = () => {},
  onChangeSortOrder,
  showHeader = true,
}: PropsWithChildren<Props<T>>) => {
  const { t } = useTranslation();

  const getColumnClass = (isAccent?: boolean, noWrap?: boolean, align?: string, isSort?: boolean) =>
    `align-${align} ${noWrap ? 'no-word-wrap' : ''} ${isAccent ? 'is-accent' : ''} ${isSort ? 'is-sort' : ''}`;

  // prettier-ignore
  const actionButton = (row: T) => ({ label, icon, onClick = () => { }, makeLink, getDisabled = () => false }: ItemActionProps<T>, i: number) => makeLink ? (<LinkButton
    key={`shifts-item-action-button--${i}`}
    id={`shifts-item-action-button--${i}`}
    href={makeLink(row)}>{icon}{label}</LinkButton>) : (
    <Button
      key={`shifts-item-action-button--${i}`}
      id={`shifts-item-action-button--${i}`}
      onClick={() => onClick(row)}
      text={label}
      icon={icon}
      disabled={getDisabled(row)}
      variant="link"
      isSmall
    />
  )

  const tableHeadColumn = ({ key, label, align = 'left', isSortable = false }: ColumnType<T>, j: number) => (
    <th key={`column-${String(key)}-title-${j}`} className={getColumnClass(false, false, align, key === sort)}>
      {onChangeSortOrder && isSortable && (
        <>
          <button className="sort" onClick={() => onChangeSortOrder({ sort: key })}>
            {t(label || (key as string))}
          </button>
          {key === sort && (
            <button className="order" onClick={() => onChangeSortOrder({ order })}>
              <Icon type={order === 'ASC' ? 'ArrowUp' : 'ArrowDown'} />
            </button>
          )}
        </>
      )}
      {!isSortable && t(label || (key as string))}
    </th>
  );

  const tableRow = (row: T, i: number) => (
    <tr key={i} className={((getRowClass && getRowClass(row, i)) || '') + (linkRow && ' hover')} onClick={() => onClickRow(row)}>
      {columns.map(({ key, isAccent, noWrap, align = 'left', customRender }, j: number) => (
        <td key={`tbl-column-${String(key)}-${i}-${j}`} className={getColumnClass(isAccent, noWrap, align)}>
          {linkRow ? (
            <a href={linkRow(row)}>{customRender ? customRender(row) : row[key] + ''}</a>
          ) : customRender ? (
            customRender(row)
          ) : row[key] ? (
            row[key] + ''
          ) : (
            '-'
          )}
        </td>
      ))}
      {itemActions && <td className="no-word-wrap">{itemActions.map(actionButton(row))}</td>}
    </tr>
  );

  const columnNames: string[] = columns.map(({ key, label }) => t(label || (key as string)));

  return (
    <StyledTable columnNames={columnNames}>
      <thead>
        <tr>
          {showHeader && columns.map(tableHeadColumn)}
          {itemActions && <th></th>}
        </tr>
      </thead>
      <tbody>{data && data.map(tableRow)}</tbody>
    </StyledTable>
  );
};

export default Table;

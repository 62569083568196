import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { View } from '../../components/View';
import { adminRoutes } from '../../routes/routes';
import { Chats } from './Chats';
import { CronStatus } from './CronStatus';
import { PayPeriods } from './PayPeriods';
import { Teams } from './Teams';
import { Users } from './Users';
import { Debug } from './Debug';

export const Main: FC = () => {
  const { t } = useTranslation();
  return (
    <View routes={adminRoutes} defaultTitle="route.admin">
      {t('admin tools')}
    </View>
  );
};

export const Admin = { Main, Users, Teams, CronStatus, PayPeriods, Chats, Debug };

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';

interface Props {
  error?: ErrorType;
}

const ErrorPage: FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <h1 data-testid="my-data-page-title">Pahoittelut, tapahtui virhe... :(</h1>
      {error && (
        <>
          <strong>{t(`notification.${error.key}`)}</strong>
          {error.message && error.message}
        </>
      )}
    </Layout>
  );
};

export default ErrorPage;

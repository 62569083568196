import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { userStore } from '../mobx/userStore';

const Container = styled.div`
  white-space: nowrap;
  > div {
    display: block;
  }
`;
export const ShowTeam: FC = observer(() => {
  const { t } = useTranslation();
  const { user } = userStore;
  const { team } = user || {};
  if (!team) {
    return null;
  }
  return (
    <Container>
      <div>{t('team')}</div>
      <b>{team.name}</b>
    </Container>
  );
});

import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConfirmModal, Loader } from '../../../../components';
import { DatePicker } from '../../../../components/inputs';
import { shiftStore } from '../../../../mobx/shiftStore';
import notify from '../../../../services/notify';
import { SHIFT_TYPE_OPTIONS } from './utils';

const DefaultTimesContainer = styled.div`
  h1 {
    font-size: 14px;
  }

  h2 {
    font-size: 16px;
  }

  .rows {
    display: block;
    margin-left: 20px;
    .row {
      display: flex;
      flex-direction: row;
      width: 100%;
      .key {
        font-weight: bold;
        width: 100%;
      }
      .time {
        margin-left: 10px;
        -right: 10px;
        width: 30%;
      }
    }
  }
`;

interface IProps {
  onHide: () => void;
}

export const DefaultShiftTimes: FC<IProps> = observer(({ onHide }) => {
  const { t } = useTranslation();
  const { shiftTimeDefaults, unsavedShiftSetData, status } = shiftStore;
  const { target, options = [] } = shiftTimeDefaults?.value || {};

  const existingOptions = () =>
    SHIFT_TYPE_OPTIONS.map(defaultOption => {
      const existingSavedOption = options?.find(o => o.value === defaultOption.value);
      return existingSavedOption || defaultOption;
    });

  const [currentOptions, setCurrentOptions] = useState<any[]>(existingOptions());

  const handleSaveChanges = async () => {
    if (currentOptions) {
        await shiftStore.saveShiftTimeDefaults(currentOptions)
    }
    onHide()

  };

  const handleDateChange = (type: 'startTime' | 'endTime', opt: any) => (newDate: Date | [Date, Date] | null) => {
    const shiftKey = opt.value;
    const changedOption = { ...opt };
    const selectedTime = DateTime.fromJSDate(newDate as Date);
    switch (type) {
      case 'startTime':
        changedOption.startClock = selectedTime.toFormat('H:mm');
        changedOption.startTime = selectedTime;
        break;
      case 'endTime':
        changedOption.endClock = selectedTime.toFormat('H:mm');
        changedOption.endTime = selectedTime;
        break;
    }
    setCurrentOptions(currentOptions.map(o => o.value === shiftKey ? changedOption : o));
  };

  if (!unsavedShiftSetData) {
    notify.error(t('select organization first'));
    return null;
  }

  useEffect(() => {}, [currentOptions]);

  if (status === 'BUSY') {
    return <Loader/>
  }

  return (
    <ConfirmModal onConfirm={handleSaveChanges} onHide={onHide} confirmText={t('save')} cancelText={t('cancel')}>
      <DefaultTimesContainer>
        <h1>{t('set-default-times')}</h1>
        <h2>{target || unsavedShiftSetData.common?.organizationFullName}</h2>
        <div className="rows">
          {currentOptions.map((opt: any, idx: number) => {
            const idPrefix = `${idx}`;
            const noLabels = idx > 0;
            const { startClock, endClock, value, label } = opt;
            const startTime = DateTime.fromJSDate(new Date())
              .set({
                hour: startClock.split(':')[0],
                minute: startClock.split(':')[1],
              })
              .toJSDate();
            const endTime = DateTime.fromJSDate(new Date())
              .set({
                hour: endClock.split(':')[0],
                minute: endClock.split(':')[1],
              })
              .toJSDate();
            return (
              <div className="row">
                <div className="key">
                  {label} ({value})
                </div>
                <div className="time">
                  <DatePicker id={`${idPrefix}-start-time-input`} label={noLabels ? undefined : t('starts')} date={startTime} onChange={handleDateChange('startTime', {...opt})} showTimeSelectOnly minDate={new Date()} />
                </div>
                <div className="time">
                  <DatePicker id={`${idPrefix}-end-time-input`} label={noLabels ? undefined : t('ends')} date={endTime} onChange={handleDateChange('endTime', {...opt})} showTimeSelectOnly minDate={new Date()} />
                </div>
              </div>
            );
          })}
        </div>
      </DefaultTimesContainer>
    </ConfirmModal>
  );
});

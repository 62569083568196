import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Loader } from '../../components';
import { View } from '../../components/View';
import { chatEventStore } from '../../mobx/chatEventStore';
import { ChatRoom } from '../../mobx/ChatRoom';
import { adminRoutes } from '../../routes/routes';

const Table = styled.table`
  font-family: ${p => p.theme.fonts.main};
  font-size: ${p => p.theme.fonts.size.md};
  width: 100%;
  th,
  td {
    &.center {
      text-align: center;
    }
  }
  th {
    text-transform: uppercase;
  }
  tbody {
    tr {
      cursor: pointer;
    }
    tr:hover {
      background-color: ${p => p.theme.colors.grayLighter};
    }
  }
`;

export const Chats: FC = observer(() => {
  const { chatList, status } = chatEventStore;
  const colNames: (keyof ChatRoom)[] = [ 'id','teamId', 'title', 'label', 'updatedAt', 'createdAt', 'users', 'groups', 'messages', 'status'];

  useEffect(() => {
    chatEventStore.getAvailableChats({ skip: 0, take: 1000 });
  }, []);

  const renderData = (colName: keyof ChatRoom, chat: ChatRoom) => {
    const data = chat[colName];
    switch (colName) {
      case 'teamId':
        return  (chat.team && `${chat.team.teamId} (${chat.team.name})`) || data;
      case 'users':
        return (data as ChatRoom['users']).length;
      case 'groups':
        return (data as ChatRoom['groups']).length;
      case 'messages':
        return (data as ChatRoom['messages']).length;
      case 'status':
        if (data === 'archived') {
          return <button onClick={async () => await chatEventStore.restoreChat(chat.id)}>{data}</button>;
        }
        return <button onClick={async () => await chatEventStore.archiveChat(chat.id)}>{(data || undefined as any)}</button>;

      default:
        if ((data as any)?.toISOString) {
          return (data as any).toISOString();
        }
        return data?.toString() || '-';
    }
  };

  return (
    <View routes={adminRoutes}>
      {status === 'BUSY' && <Loader />}
      <Table>
        <thead>
          <tr>{colNames && colNames.map((colName, idx) => <th key={'th_' + idx}>{colName}</th>)}</tr>
        </thead>
        <tbody>
          {chatList.map(c => (
            <tr key={'row_' + c.id}>
              {colNames.map((colName, idx) => (
                <td key={`${c.id}_${colName}`}>{renderData(colName as keyof ChatRoom, c)}</td>
              ))}
              <td key={`${c.id}_actions`}></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </View>
  );
});

import styled from 'styled-components';

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${p => p.theme.breakpoints.mobile} {
    display: block;
  }
`;

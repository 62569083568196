import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 10px;
  text-align: center;
  font-style: italic;
  color: ${p => p.theme.colors.grayDarker};

  button {
    border: none;
    background-color: transparent;
    color: ${p => p.theme.colors.grayDarkest};
  }
`;

interface Props {
  error?: ErrorType;
  tryAgain?: Function;
}

const DataNotFound: FC<Props> = ({ error, tryAgain }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div>{t('dataNotFound') + ''}</div>
      {error && error.key !== 'clientError' && <div>{t(`notification.${error.key}`)}</div>}

      {tryAgain && <button onClick={() => tryAgain()}>{t('tryAgain') + ''}</button>}
    </Container>
  );
};

export default DataNotFound;

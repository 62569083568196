import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { userStore } from '../mobx/userStore';
import { Redirect, Route as RouterRoute, BrowserRouter as Router, Switch } from 'react-router-dom';
import appRoutes, { loginRoutes, path, Route } from './routes';

interface Props {
  children?: React.ReactNode;
  routes?: Route[];
}

const AppRouter: FC<Props> = observer(({ routes = appRoutes, children }) => {
  const isLoggedIn = userStore.token;
  const authorizedRoutes = isLoggedIn ? appRoutes : loginRoutes;
  return (
    <Router>
      <Switch>
        {authorizedRoutes.map((route: Route) => (
          <RouterRoute key={route.path} path={route.path} component={route.component} exact={route.exact} />
        ))}
        {children}
        <Redirect to={`/${path('login')}`} />
      </Switch>
    </Router>
  );
});

export default AppRouter;

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${p => p.theme.breakpoints.mobile} {
    justify-content: center;
    span {
      display: none;
    }
  }
  .is-hidden {
    visibility: hidden;
  }
`;

const Total = styled.div`
  display: flex;
  font-size: ${p => p.theme.fonts.size.sm};
  font-weight: bold;
`;

const PrevNextLink = styled.div`
  color: ${p => p.theme.colors.primary};
  font-size: ${p => p.theme.fonts.size.sm};
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 8px;
  user-select: none;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  @media ${p => p.theme.breakpoints.mobile} {
    > b {
      display: none;
    }
  }
`;

const PageButton = styled.button<{ isActive?: boolean }>`
  border: 1px solid ${p => (p.isActive ? p.theme.colors.primary : p.theme.colors.gray)};
  background-color: ${p => (p.isActive ? p.theme.colors.primary : 'transparent')};
  color: ${p => (p.isActive ? p.theme.colors.white : p.theme.colors.grayDarkest)};
  border-radius: ${p => p.theme.borderRadius[0]};
  padding: ${p => p.theme.spacings.sm} ${p => p.theme.spacings.md};
  margin: 0 3px;
  min-width: 40px;
  font-family: ${p => p.theme.fonts.secondary};
  font-size: ${p => p.theme.fonts.size.md};
  font-weight: bold;
  cursor: ${p => (p.isActive ? 'default' : 'pointer')};
  user-select: none;
  :hover {
    color: ${p => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  }
  @media ${p => p.theme.breakpoints.mobile} {
    padding: ${p => p.theme.spacings.sm} ${p => p.theme.spacings.sm};
    font-size: ${p => p.theme.fonts.size.sm};
    min-width: 20px;
  }
`;

const Ellipsis = styled.span`
  color: ${p => p.theme.colors.grayDarkest};
  margin: 0 3px;
  user-select: none;
`;

interface Props {
  pageCount: number;
  page: number;
  total?: number;
  onPageChange?: (page: number) => void;
}

export const Pagination: FC<Props> = ({ total, pageCount, page, onPageChange }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(page);

  const PAGES = Array.from({ length: pageCount }, (_, i) => i + 1);

  const SHOW_BEFORE = 1; // Page buttons shown before current page
  const SHOW_AFTER = 1; // Page buttons shown after current page

  const isLast = currentPage === pageCount;
  const isFirst = currentPage === 1;

  const beforeThreshold = currentPage - SHOW_BEFORE - (isLast ? 1 : 0);
  const afterThreshold = currentPage + SHOW_AFTER + (isFirst ? 1 : 0);

  const isPrevButtonVisible = currentPage <= 1;
  const isNextButtonVisible = currentPage >= pageCount;

  const showFirstAndLastPageButtons = pageCount > SHOW_BEFORE + SHOW_AFTER + 1;
  const isFirstPageButtonVisible = currentPage > 1 + SHOW_BEFORE + (isLast ? 1 : 0);
  const isLastPageButtonVisible = currentPage < pageCount - SHOW_AFTER - (isFirst ? 1 : 0);

  /**
   * Update internal page if prop-page was changed
   */
  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  /**
   * Use 500 ms delay before doing any actions. This helps when browsing multiple pages
   * -> no need to do actions everytime when e.g. Next-page button is clicked, only after the last click
   */
  useEffect(() => {
    const delayedAction = setTimeout(() => {
      if (currentPage !== page && onPageChange) onPageChange(currentPage);
    }, 500);

    return () => clearTimeout(delayedAction);
  }, [currentPage]);

  const handlePageChange = (page: number) => () => {
    if (page > 0 && page <= pageCount) {
      setCurrentPage(page);
    }
  };

  const renderPageButton = (page: number) => {
    const isOverBeforeThreshold = page < beforeThreshold;
    const isOverAfterThreshold = page > afterThreshold;

    if (isOverBeforeThreshold || isOverAfterThreshold) return null;

    return (
      <PageButton data-testid={`pagination-page-${page}-button`} key={`pagination-page-${page}-button`} onClick={handlePageChange(page)} isActive={page === currentPage}>
        {page}
      </PageButton>
    );
  };

  return (
    <Container>
      {!!total && (
        <Total>
          {t('total')} : {total}
        </Total>
      )}
      <PrevNextLink className={isPrevButtonVisible ? 'is-hidden' : ''} data-testid={`pagination-prev-button`} onClick={handlePageChange(currentPage - 1)}>
        {'<'}
        <b>{`${t('previous')}`}</b>
      </PrevNextLink>
      {showFirstAndLastPageButtons && (
        <div className={!isFirstPageButtonVisible ? 'is-hidden' : ''}>
          <PageButton onClick={handlePageChange(1)}>{1}</PageButton>
          <Ellipsis>{'. . .'}</Ellipsis>
        </div>
      )}
      {PAGES.map(renderPageButton)}
      {showFirstAndLastPageButtons && (
        <div className={!isLastPageButtonVisible ? 'is-hidden' : ''}>
          <Ellipsis>{'. . .'}</Ellipsis>
          <PageButton onClick={handlePageChange(pageCount)}>{pageCount}</PageButton>
        </div>
      )}
      <div className={isNextButtonVisible ? 'is-hidden' : ''}>
        <PrevNextLink data-testid={`pagination-next-button`} onClick={handlePageChange(currentPage + 1)}>
          <b>{`${t('next')}`}</b>
          {'>'}
        </PrevNextLink>
      </div>
    </Container>
  );
};

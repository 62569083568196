import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ noPadding?: boolean }>`
  padding: 0 ${p => (p.noPadding ? '0px' : '20px')};
  @media ${p => p.theme.breakpoints.mobile} {
    padding: 0 10px;
  }
`;

const Wrapper: FC<{ children: ReactNode; noPadding?: boolean }> = ({ children, noPadding = false }) => {
  return <Container noPadding={noPadding}>{children}</Container>;
};

export default Wrapper;

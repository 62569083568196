import { observer } from 'mobx-react';
import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ConfirmModal } from '../../components/ConfirmModal';
import { Button, ButtonGroupRow } from '../../components/inputs';
import { Layout } from '../../components/Layout';
import { SelectRecipients } from '../../components/SelectRecipients';
import { shiftStore } from '../../mobx/shiftStore';
import { path } from '../../routes/routes';
import notify from '../../services/notify';
import EmployeeNotificationSettings from './components/EmployeeNotificationSettings';
import { GeneralSettings } from './components/GeneralSettings';
import NotificationSettings from './components/NotificationSettings';
import TimePeriods from './components/TimePeriods';

const InfoTextContainer = styled.div`
  display: flex;
  margin-left: ${p => p.theme.spacings.md};
  color: ${p => p.theme.colors.primary};
  font-style: italic;

  align-items: center;
`;

const emojiRegex = /<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu;

export const CreateOrUpdateShiftSet: FC = observer(() => {
  const history = useHistory();
  // EMPLOYEES or GROUPS
  const [selectedEmployeesOptions, setSelectedEmployeesOptions] = useState<SelectedOption<number>[] | undefined>([]);
  const [selectedGroupsOptions, setSelectedGroupsOptions] = useState<SelectedOption<number>[] | undefined>([]);
  const [selectedBlacklistedOptions, setSelectedBlacklistedOptions] = useState<SelectedOption<number>[] | undefined>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { t } = useTranslation();
  const { shiftSetId } = useParams<{ shiftSetId: string }>();
  const { unsavedShiftSetData, status } = shiftStore;

  const commonShiftSetData = unsavedShiftSetData?.common;

  const isDeleted = unsavedShiftSetData?.shiftSets && unsavedShiftSetData.shiftSets[0].deleted;
  const isEditing = unsavedShiftSetData?.shiftSets && unsavedShiftSetData.shiftSets[0].id;

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const errorInCustomEmails = commonShiftSetData?.customEmailList?.some(email => !emailRegex.test(email));
  const errorInCustomPhonenumbers = commonShiftSetData?.customNumberList?.some(
    number => number !== null && (number[0] !== '+' || !number.slice(1).match(/^[\d\s]+$/)) && number !== ''
  );

  const notificationsRef = useRef<HTMLDivElement>(null);
  const generalSettingsRef = useRef<HTMLDivElement>(null);

  const allShiftSetsReserved = unsavedShiftSetData?.shiftSets?.every(shiftSet => shiftSet.reservedToId);
  const hasRecipients = commonShiftSetData?.employeeIds?.length || commonShiftSetData?.groupIds?.length || allShiftSetsReserved;

  useEffect(() => {
    if (shiftSetId && status !== 'FETCHED') {
      shiftStore.getShiftSetForEditing(Number(shiftSetId));
    } else if (!shiftSetId && status === 'EMPTY') {
      shiftStore.createNew();
    }
  }, []);

  useEffect(() => {
    if (shiftSetId && status === 'FETCHED') {
      setSelectedEmployeesOptions(commonShiftSetData?.employees?.map(({ id, name }) => ({ label: name as string, value: id as number })));
      setSelectedGroupsOptions(commonShiftSetData?.groups?.map(({ personGroupId, name }) => ({ label: name, value: personGroupId })));
      setSelectedBlacklistedOptions(commonShiftSetData?.blacklistedEmployees?.map(({ name, id }) => ({ label: name as string, value: id as number })));
    }
    if (status === 'SAVED_NEW') {
      const params: any = {
        order: 'DESC',
        sort: 'updatedAt',
        page: 1,
        filter: 'all',
      };
      history.replace(`/${path('shifts')}?${new URLSearchParams(params).toString()}`);
    }
  }, [status]);

  useEffect(() => {
    if (shiftStore.status === 'ERROR') {
      generalSettingsRef.current?.scrollIntoView();
    }
  });

  const handleSend = (event: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (errorInCustomEmails === true || errorInCustomPhonenumbers === true) {
      notificationsRef.current?.scrollIntoView();
      return;
    }

    const notes = shiftStore.unsavedShiftSetData?.common?.notes || '';
    const description = shiftStore.unsavedShiftSetData?.common?.description || '';
    const address = shiftStore.unsavedShiftSetData?.common?.organizationFullAddress || '';

    const notesContainEmoji = emojiRegex.test(notes);
    const descriptionContainEmoji = emojiRegex.test(description);
    const addressContainEmoji = emojiRegex.test(address);
    const emojis = notesContainEmoji || descriptionContainEmoji || addressContainEmoji;
    if (emojis) {
      notify.error('notesContainInvalidCharacters', {
        autoClose: 8000,
      });
      return;
    }

    shiftStore.createOrUpdateShiftSet();
  };

  const handleCancel = () => {
    window.scrollTo(0, 0);
    notify.info('formCancelled');
    history.goBack();
  };

  const setSelectedEmployees = (selected: SelectOption<number>[]) => {
    setSelectedEmployeesOptions(selected);
    shiftStore.change({ common: { ...commonShiftSetData, employeeIds: selected.map(({ value }) => value) } });
  };

  const setSelectedGroups = (selected: SelectOption<number>[]) => {
    setSelectedGroupsOptions(selected);
    shiftStore.change({ common: { ...commonShiftSetData, groupIds: selected.map(({ value }) => value) } });
  };

  const setSelectedBlacklisted = (selected: { label: string; value: number }[]) => {
    setSelectedBlacklistedOptions(selected);
    shiftStore.change({ common: { ...commonShiftSetData, blacklistedEmployeeIds: selected.map(({ value }) => value) } });
  };

  const resendShiftSet = async () => {
    if (unsavedShiftSetData?.shiftSets && unsavedShiftSetData?.shiftSets[0] && unsavedShiftSetData?.shiftSets[0].id) {
      await shiftStore.resendShiftSetToRecipients(unsavedShiftSetData?.shiftSets[0].id);
    }
    history.goBack();
  };

  const handleDeleteShiftSet = async () => {
    setShowDeleteModal(false);
    if (unsavedShiftSetData?.shiftSets && unsavedShiftSetData?.shiftSets[0] && unsavedShiftSetData?.shiftSets[0].id) {
      await shiftStore.deleteShiftSet(unsavedShiftSetData?.shiftSets[0].id);
    }
    history.goBack();
  };

  return (
    <Layout>
      <div ref={generalSettingsRef}>
        <GeneralSettings />
      </div>
      <div ref={notificationsRef}>
        <NotificationSettings />
      </div>
      <TimePeriods />
      <EmployeeNotificationSettings />
      <SelectRecipients
        selectedEmployees={selectedEmployeesOptions}
        selectedBlacklisted={selectedBlacklistedOptions}
        selectedGroups={selectedGroupsOptions}
        setSelectedBlacklisted={setSelectedBlacklisted}
        setSelectedEmployees={setSelectedEmployees}
        setSelectedGroups={setSelectedGroups}
      />
      <ButtonGroupRow>
        {isEditing && (
          <>
            <Button id="open-shift-delete-button" type="submit" text={t('deleteShiftSet')} onClick={() => setShowDeleteModal(true)} color="black" />
            {unsavedShiftSetData.shiftSets && unsavedShiftSetData.shiftSets[0].id && !unsavedShiftSetData.shiftSets[0].deleted && (
              <Button id="resend-shift-button" type="submit" text={t('resendShiftSet')} onClick={resendShiftSet} color="primary" />
            )}
          </>
        )}
        {!isDeleted && (
          <Button
            id="new-shift-send-button"
            type="submit"
            text={isEditing ? t('saveChanges') : t('send')}
            onClick={handleSend}
            color="primary"
            disabled={shiftStore.status === 'BUSY'}
          />
        )}
        {isEditing && showDeleteModal && (
          <ConfirmModal
            bodyText={t('delete-shift-modal__body')}
            confirmText={t('deleteShiftSet')}
            cancelText={t('cancel')}
            onConfirm={handleDeleteShiftSet}
            onHide={() => setShowDeleteModal(false)}
          />
        )}
        <Button id="new-shift-cancel-button" text={t('cancel')} onClick={handleCancel} variant="outlined" />
        <InfoTextContainer>
          <div>{!hasRecipients && t('no recipients for shiftsets')}</div>
        </InfoTextContainer>
      </ButtonGroupRow>
    </Layout>
  );
});

import styled from 'styled-components';

import { ChatUIState } from '../ChatSideUI';

export const Container = styled.div`
  width: 25%;
  background-color: white;
  min-height: 70vh !important;

  button {
    height: ${p => p.theme.spacings.xl} !important;
    border: none !important;
    margin: 0;
    padding: 0 5px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
  svg {
    fill: white;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background: linear-gradient(180deg, #ff1926 0%, #d91722 100%);
    padding: 9px;
    overflow: visible;
  }
`;

export const ListContainer = styled.div<{ chatUIState: ChatUIState }>`
  background-color: white !important;
  padding-left: ${p => p.theme.spacings.md} !important;

  height: ${p =>
    p.chatUIState === 'create chatroom' || p.chatUIState === 'add participants modal'
      ? '45vh'
      : p.chatUIState === 'select chatroom'
      ? '45vh'
      : '80vh'} !important;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;

  td {
    padding: 5px !important;
  }
  tr {
    column-width: 20px !important;
    padding: 0 !important;
    background-color: ${p => p.theme.colors.white} !important;
    border-bottom: 1px solid ${p => p.theme.colors.gray};
  }

  table td:nth-child(1) {
    min-width: 60px !important;
    max-width: 60px !important;
  }

  table td:nth-child(2) {
    min-width: 170px !important;
    max-width: 170px !important;
  }

  table td:nth-child(3) {
    text-align: right;
    min-width: 80px !important;
  }

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  .eFkJLZ {
    margin-top: ${p => p.theme.spacings.xl} !important;
  }

  tr {
    column-width: 20px !important;
    padding: 0 !important;
    cursor: ${p => (p.chatUIState === 'add participants modal' ? 'pointer' : 'default')} !important;
    background-color: ${p => p.theme.colors.white} !important;
    border-bottom: 1px solid ${p => p.theme.colors.gray};
    :hover {
      background-color: ${p => (p.chatUIState === 'add participants modal' ? p.theme.colors.grayLightest : 'none')} !important;
    }
  }
`;

export const ListLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ListItemTextContent = styled.div`
  flex-direction: column;

  overflow: hidden;
  .list-item-secondary-text {
    max-width: 50%;
    color: ${p => p.theme.colors.grayDarker};
    font-size: ${p => p.theme.fonts.size.sm};
  }
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: ${p => p.theme.fonts.size.md};
  font-weight: bold;
  min-height: 5vh !important;
  padding: ${p => p.theme.spacings.md} ${p => p.theme.spacings.lg};
`;

export const ChatRoomListItem = styled.div<{ isActive: boolean }>`
  opacity: ${p => (p.isActive ? 1.0 : 0.5)};
`;

export const FormContainer = styled.div<{ chatUIState: ChatUIState }>`
  padding-top: ${p => p.theme.spacings.sm} !important;
  width: 100% !important;

  min-height: ${p =>
    p.chatUIState === 'create chatroom' || p.chatUIState === 'add participants modal'
      ? '30vh'
      : p.chatUIState === 'select chatroom'
      ? '30vh'
      : undefined} !important;
  background-color: ${p => p.theme.colors.grayLighter} !important;
  overflow-x: hidden;
  input {
    width: 100% !important;
    margin-left: ${p => p.theme.spacings.md} !important;
    margin-right: ${p => p.theme.spacings.md} !important;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 0 !important;
    align-items: center;
    button {
      height: 20px !important;
      margin-right: ${p => p.theme.spacings.md} !important;
      margin-left: ${p => p.theme.spacings.md};
      border: 1px solid black !important;
      border-radius: ${p => p.theme.borderRadius[0]} !important;
    }
  }
`;

export const GreetMessageInputContainer = styled.div`
  width: 100% !important;

  margin: 0 !important;
  background-color: ${p => p.theme.colors.grayLighter};

  .rdw-editor-toolbar {
    background-color: ${p => p.theme.colors.grayLighter};
    border: none;
  }
  .DraftEditor-editorContainer {
    min-height: 10vh !important;
    background-color: ${p => p.theme.colors.white} !important;
    margin-left: ${p => p.theme.spacings.md};
    margin-right: ${p => p.theme.spacings.md};
    margin-bottom: ${p => p.theme.spacings.md};

    border-radius: 2px;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .DraftEditorPlaceholder-root {
    z-index: 100 !important;
    margin-left: ${p => p.theme.spacings.lg};
    margin-top: ${p => p.theme.spacings.lg};
  }

  .public-DraftEditorPlaceholder-root {
    z-index: 100 !important;
    margin-left: ${p => p.theme.spacings.lg};
    margin-top: ${p => p.theme.spacings.lg};
  }

  .public-DraftEditor-content {
    margin-left: ${p => p.theme.spacings.sm};
  }

  .rdw-emoji-modal {
    z-index: 1000;
  }
`;

export const ChatTitle = styled.div`
  font-family: ${p => p.theme.fonts.main};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: ${p => p.theme.spacings.sm};
`;

export const ChatLabel = styled.div`
  font-family: ${p => p.theme.fonts.main};
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #676974;
`;

export const ChatNewEvents = styled.div<{ renderChatEvents: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  width: 23px;
  height: 23px;
  color: white;
  background: ${p => (p.renderChatEvents ? p.theme.colors.primary : 'inherit')};
  border-radius: 45.2419px;
  font-style: normal;
  font-weight: 700;
  font-size: 11.5161px;
  line-height: 20px;
  color: #ffffff;
`;
export const ChatRoomUpdatedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-text: center;
`;

export const ChatUpdateTime = styled.div`
  width: 10px;
`;

export const RemoveParticipantButton = styled.div`
  margin-left: ${p => p.theme.spacings.xl} !important;
  :hover {
    cursor: pointer;
  }
`;

export const SelectionInfo = styled.div`
  margin-left: ${p => p.theme.spacings.md} !important;
  color: ${p => p.theme.colors.primary};
`;

export const TopButtonsContainer = styled.div`
  min-height: 5vh;
  width: 100% !important;
  padding: 0 ${p => p.theme.spacings.lg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${p => p.theme.colors.grayLighter};
`;

export const BottomButtonsContainer = styled.div`
  min-height: 5vh !important;
  width: 100% !important;
  padding: 0 ${p => p.theme.spacings.lg} !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: ${p => p.theme.colors.grayLighter} !important;
`;

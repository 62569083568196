import React, { FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Async from 'react-select/async';
import { userStore } from '../../../mobx/userStore';
import { Container } from './Layout';
import { api } from '../../../services/api';
import config from '../../../services/api/config';
import { Checkbox, Label, SelectContainer } from '../../../components/inputs';
import styled from 'styled-components';
import { likeitDateToString } from '../../../utils/date';

const TaskPriceContainer = styled.div`
  border: solid 2px green;
  padding: 1rem;
  max-width: 600px;
  label {
    color: green;
  }
  p {
    margin: 0;
  }
  .error {
    color: red;
  }
`;

interface Props {
  reservedTo: SelectedOption<number | null | undefined>;
  setReservedTo: Function;
  taskprice?: TaskPriceInfo;
}
const { likeitSellerPersonId } = config;

const searchFiltersInitial = {
  filter: ['employeeTableSearch'],
  likeitSellerPersonId,
  take: 10,
};

export const ReserveTo: FC<Props> = ({ reservedTo, setReservedTo, taskprice }) => {
  const { t } = useTranslation();
  const { token } = userStore;
  const [params, setParams] = useState<GetOrgOptionsParams>(searchFiltersInitial);
  const [searchEmployeeTable, setSearchEmployeeTable] = useState(true);
  const onChange = (option: unknown) => {
    setReservedTo(option);
  };

  const handleSearchChange = (value: SetStateAction<boolean>) => {
    setSearchEmployeeTable(value);

    if (!value) {
      setParams({ ...params, filter: ['employees'] });
      return;
    } else {
      setParams(searchFiltersInitial);
    }
  };

  const loadOptions = async (value: string) => {
    const newParams: GetOrgOptionsParams = { ...params, employeeName: value };
    setParams(newParams);
    if (!token) {
      return;
    }
    const { data } = await api.organizationSearch({ token, ...newParams });
    if (!data) return;
    const optsRes: GetOrgOptionsResponse = data;
    return optsRes.employees.map(({ name, id }) => ({ label: name, value: id }));
  };

  return (
    <Container>
      <SelectContainer>
        <Label>{t('reservedTo')}</Label>
        <Async id="reserveTo-async" loadOptions={loadOptions} value={reservedTo} onChange={onChange} isClearable={true} placeholder={t('select')} />
        <Checkbox id="search-employeetable" checked={searchEmployeeTable} label={t('search-employeetable')} setValue={handleSearchChange} />
      </SelectContainer>
      {taskprice && (
        <TaskPriceContainer>
          <Label>{t('taskPrice')}</Label>
          {taskprice.taskPrice && (
            <>
              <p>{taskprice.taskPrice?.Task?.ToString}</p>
              <p>{taskprice.taskPrice?.Employee?.ToString}</p>
              <p>
                {taskprice.taskPrice.StartTime ? likeitDateToString(taskprice.taskPrice.StartTime) : '.'} -{' '}
                {taskprice.taskPrice.EndTime ? likeitDateToString(taskprice.taskPrice?.EndTime) : '.'}
              </p>
            </>
          )}
          {taskprice.existed === false && <p className="error">{t('taskPrice-is-missing')}</p>}
        </TaskPriceContainer>
      )}
    </Container>
  );
};

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { observer } from 'mobx-react';
import { stateFromHTML } from 'draft-js-import-html';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { FC, useState, useEffect } from 'react';
import { guideStore } from '../../mobx/guideStore';
import { Button, ButtonGroupRow, Input } from '../../components/inputs';
import { isNumberString } from '../../components/inputs/utils';
import { path } from '../../routes/routes';
import { View } from '../../components/View';
import { Loader, Sect, ConfirmModal } from '../../components';

export const GuideEditor: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { status, selected } = guideStore;
  const [title, setTitle] = useState<string>('');
  const [editAsMarkdown, setEditAsMarkdown] = useState<boolean>(false);
  const [markdown, setMarkDown] = useState<string>('');
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<EditorState | undefined>();
  const onEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
    guideStore.changed();
  };

  const uploadImageCallBack = async (file: any) => {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = function (e) {
        resolve({ data: { link: e?.target?.result } });
      };
      fr.readAsDataURL(file);
    });
  };

  const saveChanges = () => {
    if (editAsMarkdown) {
      guideStore.save({
        id: selected?.id,
        title,
        content: markdown,
      });
      return;
    }
    if (editorState) {
      const content = stateToMarkdown(editorState.getCurrentContent(), { gfm: true });
      guideStore.save({
        id: selected?.id,
        title,
        content,
      });
    }
  };
  const deleteGuide = () => {
    if (selected?.id) {
      guideStore.delete(selected?.id);
    }
  };
  useEffect(() => {
    if (isNumberString(id) && status === 'IDLE') {
      guideStore.get(Number(id));
    }
    if (id === path('new')) {
      guideStore.create();
    }
  }, [id]);
  useEffect(() => {
    switch (status) {
      case 'FETCHED':
      case 'NEW':
        if (selected?.content !== undefined && selected?.title !== undefined) {
          if (selected.content.indexOf('<!doctype html>') !== -1) {
            // html content
            const html = selected.content.replace(/figure>/g, 'p>');
            const contentState = stateFromHTML(html);
            setEditorState(EditorState.createWithContent(contentState));
          } else {
            // markdown content
            const markdown = selected.content;
            const contentState = stateFromMarkdown(markdown, { parserOptions: { atomicImages: true } });
            setEditorState(EditorState.createWithContent(contentState));
            setMarkDown(markdown);
          }
          setTitle(selected.title);
        }
        break;
      case 'DELETED':
        history.push(`/${path('guide')}`);
        break;
      case 'SAVED':
        history.push(`/${path('guide')}/${selected?.id}`);
        break;
    }
  }, [status, selected]);

  return (
    <View>
      {selected && (
        <>
          {status === 'BUSY' && <Loader isFullScreen />}
          <Sect className="status">
            <Button
              id="edit-mode"
              onClick={() => {
                setEditAsMarkdown(!editAsMarkdown);
                if (editAsMarkdown) {
                  const contentState = stateFromMarkdown(markdown, { parserOptions: { atomicImages: true } });
                  setEditorState(EditorState.createWithContent(contentState));
                } else {
                  if (editorState) setMarkDown(stateToMarkdown(editorState.getCurrentContent(), { gfm: true }));
                }
              }}
              color="primary"
              variant="square"
              text="Markdown / WYSIWYG"
            />
          </Sect>
          <Sect>
            <Input
              id="guide-title"
              value={title}
              placeholder={t('inputPlaceholder.guideTitle')}
              onChange={e => {
                guideStore.changed();
                setTitle(e.target.value);
              }}
            />
          </Sect>
          <Sect>
            {editorState &&
              (editAsMarkdown ? (
                <textarea
                  value={markdown}
                  onChange={e => {
                    setMarkDown(e.target.value);
                    guideStore.changed();
                  }}
                  cols={80}
                  rows={40}
                />
              ) : (
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: ['blockType', 'inline', 'list', 'link', 'image', 'history'],
                    inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
                    blockType: { inDropdown: true, options: ['Normal', 'H1', 'H2', 'H3', 'H4'] },
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      uploadCallback: uploadImageCallBack,
                      previewImage: true,
                      alt: { present: false, mandatory: false },
                    },
                  }}
                />
              ))}
          </Sect>
          <Sect>
            <ButtonGroupRow>
              <Button id="delete-button" type="submit" text={t('delete')} onClick={() => setShowConfirmDelete(true)} color="primary" disabled={!selected.id} />
              <Button id="save-button" type="submit" text={t('saveChanges')} onClick={saveChanges} color="primary" disabled={status !== 'CHANGED'} />
              <Button id="cancel-button" text={t('cancel')} onClick={() => history.push(`/${path('guide')}`)} variant="outlined" />
            </ButtonGroupRow>
          </Sect>
        </>
      )}
      {showConfirmDelete && <ConfirmModal bodyText={t('delete-guide-confirm-body')} confirmText={t('delete')} cancelText={t('cancel')} onConfirm={deleteGuide} onHide={() => setShowConfirmDelete(false)} />}
    </View>
  );
});

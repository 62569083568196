// my-theme.ts
import { DefaultTheme } from 'styled-components';

const colors = {
  primaryLightest: '#ed5e65',
  primaryLighter: '#e8303a',
  primary: '#db1823',
  primaryDarker: '#b8141d',
  secondaryLightest: '#205879',
  secondaryLighter: '#153b51',
  secondary: '#102C3C',
  secondaryDarker: '#0b1d28',
  grayLightest: '#f9fafb',
  grayLighter: '#e0e6eb',
  gray: '#c2ccd6',
  grayDarker: '#a3b3c2',
  grayDarkest: '#8599ad',
  text: '#2C3034',
  textAccent: '#25A5B5',
  white: '#ffffff',
  black: '#000000',
  success: '#18dc49',
  successBg: '#e0fccb',
  error: '#db1823',
  errorBg: '#FF192631',
};

export const theme: DefaultTheme = {
  borderRadius: ['5px', '10px'],

  spacings: {
    headerHeight: '75px',
    sm: '5px',
    md: '10px',
    lg: '15px',
    xl: '30px',
  },

  breakpoints: {
    mobile: `(max-width: 768px)`,
    tablet: `(max-width: 1024px)`,
    smalldesktop: `(max-width: 1440px)`,
  },

  shadows: ['box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.16);', 'box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);', 'box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);'],

  navBar: {
    bg: colors.secondary,
    link: colors.white,
    icon: colors.secondary,
  },

  fonts: {
    main: 'Mulish',
    secondary: 'Raleway',
    size: {
      sm: '12px',
      md: '14px',
      lg: '18px',
      xl: '24px',
    },
  },

  colors,
};

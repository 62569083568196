interface StorageProps {
  key: 'USER_TOKEN' | string;
  target?: 'local' | 'session';
}

interface WriteStorageProps extends StorageProps {
  value: Object | null;
}

const read = ({ key, target = 'local' }: StorageProps) => {
  switch (target) {
    case 'session':
      if (window.sessionStorage) {
        const value = window.sessionStorage.getItem(key);
        if (value) return JSON.parse(value);
      }
      break;
    case 'local':
    default:
      if (window.localStorage) {
        const value = window.localStorage.getItem(key);
        if (value) return JSON.parse(value);
      }
      break;
  }
  return null;
};

const write = ({ key, value, target = 'local' }: WriteStorageProps) => {
  switch (target) {
    case 'session':
      if (window.sessionStorage) window.sessionStorage.setItem(key, JSON.stringify(value));
      break;
    case 'local':
    default:
      if (window.localStorage) window.localStorage.setItem(key, JSON.stringify(value));
      break;
  }
};

const STORAGE = {
  read,
  write,
};

export default STORAGE;

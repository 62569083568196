import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Layout, Loader, Pagination, SearchListContainer, Toolbar } from '../../components';
import DataNotFound from '../../components/DataNotFound';
import FilterSelectBar from '../../components/FilterSelectBar';
import { Icon } from '../../components/Icon';
import { Checkbox, LinkButton } from '../../components/inputs';
import SearchOrgForm, { SearchOrgFormParams } from '../../components/SearchOrgForm';
import Table, { ColumnType, ItemActionProps } from '../../components/Table';
import { shiftStore } from '../../mobx/shiftStore';
import { path } from '../../routes/routes';
import { dateToString } from '../../utils/date';

const FILTER_OPTIONS: ShiftsFilterType[] = ['filled', 'unfilled', 'past!future', 'future!past'];

const ORDER_OPTIONS = {
  asc: 'ASC',
  desc: 'DESC',
};

const getRowClass = (shift: Shift, i: number) => {
  switch (shift.status) {
    case 'urgent':
      return 'error';
    case 'filled':
      return 'success';
    case 'hidden':
      return 'hidden';
    default:
      return '';
  }
};

const useSearchParams = () => {
  const { search } = useLocation();
  const order = new URLSearchParams(search).get('order') || ORDER_OPTIONS.asc;
  const sort = new URLSearchParams(search).get('sort') || 'startTime';
  const page = Number(new URLSearchParams(search).get('page')) || 1;
  const f = new URLSearchParams(search).get('filter');
  const filter = f === 'all' ? [] : f !== null ? f.split(',') : ['future'];
  const groupId = Number(new URLSearchParams(search).get('groupId')) || undefined;
  const employeeId = Number(new URLSearchParams(search).get('employeeId')) || undefined;
  const deleted = Boolean(new URLSearchParams(search).get('deleted')) || undefined;
  return { order, sort, page, filter, groupId, employeeId, deleted };
};

export const ListShifts: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { status, shifts, total, pages, error } = shiftStore;
  const searchParams = useSearchParams();
  const [searchOptions, setSearchOptions] = useState<any>(searchParams);
  const { page, sort, order, filter } = searchOptions;

  const setQuery = (params: any) => {
    const filtered: any = {};
    Object.keys(params).forEach(key => {
      if (params[key]) filtered[key] = params[key];
    });
    history.replace({ search: new URLSearchParams(filtered).toString() });
  };

  useEffect(() => {
    if (status !== 'BUSY' && status !== 'ERROR' && !shifts) {
      shiftStore.getShifts(searchOptions);
      setQuery(searchOptions);
    }
  });

  // changed options
  useEffect(() => {
    shiftStore.getShifts(searchOptions);
    setQuery(searchOptions);
  }, [searchOptions]);

  const handlePageChange = (page: number) => {
    setSearchOptions({ ...searchOptions, page });
  };

  const handleEditShift = (shift: Shift) => (shift.id ? `${path('editShiftSet')}/${shift.shiftSet.id}/${shift.id}` : 'undefined');

  const renderShiftKeyColumn = ({ shiftKey }: Shift) => <strong>{t(shiftKey).toUpperCase()}</strong>;

  const renderStatusColumn = ({ status }: Shift) => {
    if (status === 'urgent') {
      return <Icon type="Attention" color="error" />;
    }
    if (status === 'filled') {
      return <Icon type="Checked" color="success" />;
    }
    if (status === 'hidden') {
      return <Icon type="Hidden" color="black" />;
    }
    return <></>;
  };

  const renderUpdatedBy = ({ updatedBy }: Shift) => {
    if (updatedBy) {
      return <>{t(updatedBy.role)}</>;
    }
    return <></>;
  };

  const renderTime = ({ startTime, endTime }: Shift) => {
    const date = dateToString(startTime, 'd.M.yyyy');
    const start = dateToString(startTime, 'HH:mm');
    const end = dateToString(endTime, 'HH:mm');
    return (
      <>
        <div style={{ fontWeight: 'bold' }}>{date}</div>
        <div style={{ whiteSpace: 'nowrap' }}>{`${start}–${end}`}</div>
      </>
    );
  };

  const renderCreatedAt = ({ createdAt, shiftSet }: Shift) => {
    let name: string | undefined = shiftSet?.createdBy?.name;
    if (!name) return (
      <>
        <div>{dateToString(createdAt, 'd.M.yyyy HH:mm')}</div>
      </>
    )

    if (name.includes("Päivystys MediPower")) {
      name = "MP Päivystys"
    }

    return (
      <>
        <div>{dateToString(createdAt, 'd.M.yyyy HH:mm')}</div>
        <div>{`(${name})`}</div>
      </>
    );
  }

  const renderReservation = ({ shiftSet: { reservedTo } }: any) => <>{reservedTo ? `${reservedTo.name} (${reservedTo.email})` : '-'}</>;

  const tableColumns: ColumnType<Shift>[] = [
    { key: 'shiftKey', customRender: renderShiftKeyColumn, isSortable: true },
    { key: 'startTime', customRender: renderTime, isSortable: true },
    { key: 'unit', isAccent: true, isSortable: true },
    { key: 'department', isSortable: true },
    { key: 'task', isSortable: true },
    { key: 'createdAt', align: 'center', isSortable: true, customRender: renderCreatedAt },
    { key: 'status', align: 'center', customRender: renderStatusColumn },
    { key: 'shiftSet', label: 'reservation', customRender: renderReservation },
    { key: 'updatedBy', customRender: renderUpdatedBy },
  ];

  const tableRowActions: ItemActionProps<Shift>[] = [{ label: t('edit'), icon: <Icon type="Edit" color="primary" />, makeLink: handleEditShift }];

  const isDataNotFound = ['FETCHED', 'ERROR'].includes(status) && (!shifts || shifts.length === 0);

  const CreateNewShiftButton = (
    <LinkButton href={path('create-new-shifts')} color="primary">
      <Icon type="Add" color="white" />
      {t('create-new-shifts')}
    </LinkButton>
  );

  const changeSortOrder = (params: { sort?: string; order?: string }) => {
    const newParams: any = {};
    if (params.sort) {
      newParams.sort = params.sort;
    }
    if (params.order) {
      newParams.order = params.order === ORDER_OPTIONS.desc ? ORDER_OPTIONS.asc : ORDER_OPTIONS.desc;
    }
    if (newParams.sort || newParams.order) {
      setSearchOptions({ ...searchOptions, ...newParams });
    }
  };

  const handleSearch = (newSearchOptions: SearchOrgFormParams) => {
    const { startTime, endTime, exportToFile, employeeGroups, ...opts } = newSearchOptions;
    const params: any = { startTime, endTime, exportToFile, employeeGroups };
    Object.keys(opts).forEach(k => {
      if (k === 'task') {
        // task is generic search because many task have same name
        params.taskName = opts && opts.task && opts.task.label;
      } else {
        (opts as any)[k] && (params[k + 'Id'] = (opts as any)[k].value);
      }
    });
    setSearchOptions({ page: 1, sort, order, filter, ...params });
  };

  return (
    <Layout>
      <h1>{t('shifts')}</h1>
      <SearchListContainer>
        <div className="secondary-column">
          <SearchOrgForm onSubmit={handleSearch} />
        </div>
        <div className="main-column">
          <div>
            <Checkbox id={'show-deleted'} label={t('show-deleted')} checked={searchOptions.deleted} setValue={() => setSearchOptions({ ...searchOptions, deleted: !searchOptions.deleted })} />
          </div>
          <Toolbar>
            {CreateNewShiftButton}
            <Pagination total={total} pageCount={pages} page={page} onPageChange={handlePageChange} />
            <FilterSelectBar<ShiftsFilterType> selected={filter} setSelected={filter => setSearchOptions({ ...searchOptions, filter })} options={FILTER_OPTIONS} />
          </Toolbar>

          {status === 'BUSY' && <Loader isFullScreen />}
          {Boolean(shifts?.length) && shifts && (
            <>
              <Table<Shift> onChangeSortOrder={changeSortOrder} order={order} sort={sort} data={shifts} columns={tableColumns} itemActions={tableRowActions} getRowClass={getRowClass} linkRow={handleEditShift} />
              <Toolbar>
                {CreateNewShiftButton}
                <Pagination total={total} pageCount={pages} page={page} onPageChange={handlePageChange} />
                <FilterSelectBar<ShiftsFilterType> selected={filter} setSelected={filter => setSearchOptions({ ...searchOptions, filter })} options={FILTER_OPTIONS} />
              </Toolbar>
            </>
          )}
          {isDataNotFound && <DataNotFound error={error} tryAgain={() => shiftStore.getShifts(searchOptions)} />}
        </div>
      </SearchListContainer>
    </Layout>
  );
});

export default ListShifts;

export const photos: { [key: string]: string } = {
  logistics: require('../assets/photos/logistiikka.jpeg'),
  logistics2: require('../assets/photos/logistiikka2.jpeg'),
  services: require('../assets/photos/palvelut.jpeg'),
  services2: require('../assets/photos/palvelut2.jpeg'),
  services3: require('../assets/photos/palvelut3.jpeg'),
  construction: require('../assets/photos/rakennus.jpeg'),
  construction2: require('../assets/photos/rakennus2.jpeg'),
  construction3: require('../assets/photos/rakennus3.jpeg'),
  office: require('../assets/photos/toimisto.jpeg'),
  manufacturing: require('../assets/photos/teollisuus.jpeg'),
  manufacturing2: require('../assets/photos/teollisuus2.jpeg'),
  healthcare: require('../assets/photos/terveydenhuolto.jpeg'),
  healthcare2: require('../assets/photos/terveydenhuolto2.jpeg'),
  healthcare3: require('../assets/photos/terveydenhuolto3.jpeg'),
};
